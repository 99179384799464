section.main-slider{
	background-image: image('~assets/img/header-slider/bg_slider.webp');
	background-size: cover;
	background-position: top right;
	height: 75vh;
	max-height: 720px;
	overflow: hidden;
	margin-bottom: 40px;
	.flickity-enabled, .container{
		position: relative;
		height:100%;
		padding: 0;
	}
	.flickity-viewport{
		opacity:1;
		height:100% !important;
		.carousel-inner{
			height: 100%;
		}
		.btn-primary{
			@include box-shadow(0 5px 5px 0px rgba(0,0,0,0.5));
			@include transition(.6s);
		}
		.item {
			display:block !important;
			width: 100%;
			height:100%;
			max-height: 720px;
			position:absolute;
			top:0;
			left: 0 !important;
			opacity: 0;
			background-repeat: no-repeat;
			background-size: cover;
			-moz-transition: opacity ease-in-out .4s;
			-o-transition: opacity ease-in-out .4s;
			-webkit-transition: opacity ease-in-out .4s;
			transition: opacity ease-in-out .4s;
			.main-slider-content{
				position: relative;
				height: 100%;
				@include transition(.6s);
				&:before{
					content: '';
					width: 100%;
					height: 200px;
					position: absolute;
					left: 0;
					bottom: 0;
					z-index: 10;
					background: linear-gradient(to bottom,rgba(13,13,13,0) 0,#060e1e 100%);
					@include transition(.6s);
				}
				.slide_legend{
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					bottom: 0;
					// left: 0;
					// right: 0;
					padding: 60px 70px;
					text-shadow: 0 2px 4px rgba(0,0,0,.5);
					z-index:110;
					@include transition(.6s);
					h1{
						font-family:$black-font;
						font-size: $bigh1;
						margin: 5px 0 10px;
						@include transition(.3s);
					}
					h2{
						font-size: $bigh2;
						margin: 5px 0;
						@include transition(.3s);
					}
					p{
						font-size: $bigp;
						margin: 5px 0;
						@include transition(.3s);
					}
					button.play{
						height: 40px;
						padding: 0 40px 0 20px;
						font-size: $ctasmall;
						position:relative;
						@include border-radius(20px);
						@include transition(0.2s);
							.iconlace{
								position: absolute;
								top: 12px;
								right: 15px;
								@include transition(.6s);
							}
						&:hover{
							padding-right: 50px;
							@include transition(.6s);
						}
					}
				}
				.slide_img{
					display: flex;
					position: absolute;
					height: 100%;
					width: 60%;
					max-width: 720px;
					top: 0;
					bottom: 0;
					// left: 0;
					// right: 0;
					@include transition(.6s);
					.slide_img-content{
						position: relative;
						height: 100%;
						width: 100%;
						@include transition(.6s);
						img{
							position: absolute;
							height: 100%;
							width: auto;
							max-width: initial;
							bottom: 0;
							@include transition(.6s);
						}
					}
				}
			}

			&.lgr{
				.row:before{
					background: -moz-linear-gradient(left, rgba(167, 0, 255, 0.9) 0%, rgba(6, 14, 30, 0) 100%);
					background: -webkit-linear-gradient(left, rgba(167, 0, 255, 0.9) 0%, rgba(6, 14, 30, 0) 100%);
					background: linear-gradient(to right, rgba(167, 0, 255, 0.9) 0%, rgba(6, 14, 30, 0) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6a700ff', endColorstr='#00060e1e',GradientType=1 );
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 62px;
					@include transition(.6s);
				}
			}
			&.item-vTextAllignToLeft,
			&.item-vTextAllignToRight{
				.slide_legend{
					width: 60%;
					text-align: left;
					@include transition(.6s);
				}
			}
			&.item-vTextAllignToLeft{
				.slide_legend{
					// right: auto;
					left: 1.80%;
					@include transition(.6s);
				}
				.slide_img{
					// left: auto;
					right: 1.80%;
					@include transition(.6s);
					.slide_img-content img{
						right: 0;
					}
				}
			}
			&.item-vTextAllignToRight {
				.slide_legend{
					// left: auto;
					right: -1.80%;
					@include transition(.6s);
				}
				.slide_img{
					// right: auto;
					left: 1.80%;
					@include transition(.6s);
					.slide_img-content img{
						left: 0;
					}
				}
			}
			&.item-vTextAllignToCenter {
				background-position-x: center;
				.slide_legend{
					text-align: center;
					width: 100%;
					@include transition(.6s);
					h1{
						font-size: $bigh4;
						text-transform: none;
						@include transition(.3s);
					}
					h2{
						text-transform: none;
						max-width: 61%;
						margin-left: auto;
						margin-right: auto;
						font-size: $bigh1;
						@include transition(.3s);
					}
				}
				&.text-left{
					.slide_legend{
						text-align: left;
						@include transition(.6s);
						h1{
							font-size: 1.8em;
							text-transform: none;
							margin-bottom: 5px;
							@include transition(.3s);
						}
						h2{
							margin-top: 15px;
							margin-bottom: 15px;
							font-size:2em;
							text-transform: none;
							@include transition(.3s);
						}
						p{
							font-size:1.4em;
							text-transform: none;
							text-shadow: 0 2px 4px $gama-negro;
							@include transition(.3s);
						}
					}
				}
			}
			&.lgr{
				.row:before{
					top:0;
					height:100%;
					background-image: image('~/assets/img/header-slider/maratonica_bg.webp');
					@include transition(.6s);
				}
			}
			&:first-child {
				top:auto;
				position:relative;
				@include transition(.6s);
			}
			&.active {
				opacity: 1;
				-moz-transition: opacity ease-in-out .4s;
				-o-transition: opacity ease-in-out .4s;
				-webkit-transition: opacity ease-in-out .4s;
				transition: opacity ease-in-out .4s;
				z-index: 9;
			}
			@media (max-width: 1199.98px){
				.main-slider-content{
					.slide_legend{
						padding: 60px 30px;
						@include transition(.6s);
					}
				}
				&.item-vTextAllignToLeft,
				&.item-vTextAllignToRight{
					.slide_img{
						width: 100%;
						@include transition(.6s);
					}
				}
				&.item-vTextAllignToLeft{
					.slide_legend{
						left: 0;
						@include transition(.6s);
					}
					.slide_img{
						right: -12%;
						@include transition(.6s);
					}
				}
				&.item-vTextAllignToRight {
					.slide_legend{
						right: 0;
						@include transition(.6s);
					}
					.slide_img{
						left: -12%;
						@include transition(.6s);
					}
				}
			}
			@media (max-width: 991.98px){
				.main-slider-content{
					&:before{
						height: 70%;
						@include transition(.6s);
					}
					.slide_legend{
						height: 70%;
						top: auto;
						justify-content: flex-end;
						@include transition(.6s);
					}
				}
				&.item-vTextAllignToLeft,
				&.item-vTextAllignToRight{
					.slide_legend{
						width: 100%;
						left: 0;
						right: 0;
						@include transition(.6s);
					}
				}
				.main-slider-content{
					.slide_legend{
						padding: 60px 30px;
						@include transition(.6s);
					}
				}
				&.item-vTextAllignToCenter {
					.slide_legend{
						height: 70%;
						top: auto;
						@include transition(.6s);
						h2{
							max-width: 100%;
							@include transition(.3s);
						}
					}
				}
			}
			@media (max-width: 767.98px){
				.main-slider-content{
					.slide_legend{
						@include transition(.6s);
						h1{
							font-size: $bigh2;
							@include transition(.3s);
						}
						h2{
							font-size: $bigh3;
							@include transition(.3s);
						}
						p{
							font-size: $regular;
							@include transition(.3s);
						}
					}
				}
				&.item-vTextAllignToLeft,
				&.item-vTextAllignToRight{
					.slide_legend{
						width: 100%;
						left: 0;
						right: 0;
						@include transition(.6s);
					}
				}
				&.item-vTextAllignToLeft{
					background-position-x: 50%;
					@include transition(.6s);
				}
				&.item-vTextAllignToRight {
					background-position-x: 50%;
					@include transition(.6s);
				}
				&.item-vTextAllignToCenter {
					@include transition(.6s);
					.slide_legend{
						height: 70%;
						top: auto;
						@include transition(.6s);
						h1{
							font-size: $bigh1;
							@include transition(.3s);
						}
						h2{
							font-size: $bigh3;
							@include transition(.3s);
						}
						p{
							font-size: $regular;
							@include transition(.3s);
						}
					}
				}
			}
			@media (max-width: 575.98px){
				.main-slider-content{
					.slide_legend{
						h1{
							font-size: $bigh3;
							@include transition(.3s);
						}
						h2{
							font-size: $regular;
							@include transition(.3s);
						}
						p{
							font-size: $detp;
							@include transition(.3s);
						}
					}
				}
				&.item-vTextAllignToLeft{
					background-position-x: 70%;
					.slide_img{
						right: -50%;
						@include transition(.6s);
					}
				}
				&.item-vTextAllignToRight {
					background-position-x: 30%;
					.slide_img{
						left: -50%;
						@include transition(.6s);
					}
				}
				&.item-vTextAllignToCenter {
					.slide_legend{
						h1{
							font-size: $bigh3;
							@include transition(.3s);
						}
						h2{
							font-size: $medp;
							@include transition(.3s);
						}
						p{
							font-size: $detp;
							@include transition(.3s);
						}
					}
				}
			}
		}
		@media (max-width: 767.98px){
			max-height: 100%;
		}
	}
	@media (max-width: 767.98px){
		height: 100vh;
		margin-bottom: 10px;
	}
}
