//@import "variables";

// Mixins
@mixin cursor-disabled() {
	cursor: not-allowed;
}

@mixin box-sizing($fmt) {
	-webkit-box-sizing: $fmt;
		 -moz-box-sizing: $fmt;
					box-sizing: $fmt;
}

@mixin box-shadow($fmt) {
	-webkit-box-shadow: $fmt;
					box-shadow: $fmt;
}

@function fade($color, $amnt) {
	@if $amnt > 1 {
		$amnt: calc($amnt / 100); // convert to percentage if int

	}
	@return rgba($color, $amnt);
}
	
// Rules
select.bs-select-hidden,
select.selectpicker {
	display: none !important;
}
	
.bootstrap-select {
	width: 220px \0; /*IE9 and below*/

	// The selectpicker button
	> .dropdown-toggle {
		width: 100%;
		padding-right: 25px;
		z-index: 1;

		&.bs-placeholder,
		&.bs-placeholder:hover,
		&.bs-placeholder:focus,
		&.bs-placeholder:active { color: $blanco; box-shadow: none !important; }
	}

	> select {
		position: absolute !important;
		bottom: 0;
		left: 50%;
		display: block !important;
		width: 0.5px !important;
		height: 100% !important;
		padding: 0 !important;
		opacity: 0 !important;
		border: none;

		&.mobile-device {
			top: 0;
			left: 0;
			display: block !important;
			width: 100% !important;
			z-index: 2;
		}
	}

	// Error display
	.has-error & .dropdown-toggle,
	.error & .dropdown-toggle {
		border-color: $color-red-error;
	}

	&.fit-width {
		width: auto !important;
	}

	&:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
		width: $width-default;
	}

}

.bootstrap-select.form-control {
	margin-bottom: 0;
	padding: 0;
	border: none;

	&:not([class*="col-"]) {
		width: 100%;
	}

	&.input-group-btn {
		z-index: auto;

		&:not(:first-child):not(:last-child) {
			> .btn {
				border-radius: 0;
			}
		}
	}
}

// The selectpicker components
.bootstrap-select.btn-group {
	.btn{
		background: transparent;
		color: #aabaea;
		border-radius: 3px !important;
	}
	&:not(.input-group-btn),
	&[class*="col-"] {
		float: none;
		display: inline-block;
		margin-left: 0;
	}

	// Forces the pull to the right, if necessary
	&,
	&[class*="col-"],
	.row &[class*="col-"] {
		&.dropdown-menu-right {
			float: right;
		}
	}

	.form-inline &,
	.form-horizontal &,
	.form-group & {
		margin-bottom: 0;
	}

	.form-group-lg &.form-control,
	.form-group-sm &.form-control {
		padding: 0;

		.dropdown-toggle {
			height: 100%;
			font-size: inherit;
			line-height: inherit;
			border-radius: inherit;
		}
	}

	// Set the width of the live search (and any other form control within an inline form)
	// see https://github.com/silviomoreto/bootstrap-select/issues/685
	.form-inline & .form-control {
		width: 100%;
	}

	&.disabled,
	> .disabled {
		@include cursor-disabled();

		&:focus {
			outline: none !important;
		}
	}

	&.bs-container {
		position: absolute;
		height: 0 !important;
		padding: 0 !important;

		.dropdown-menu {
			z-index: $zindex-select-dropdown;
		}
	}

	// The selectpicker button
	.dropdown-toggle {
		.filter-option {
			display: inline-block;
			overflow: hidden;
			width: 100%;
			text-align: left;
		}

		.caret {
			position: absolute;
			top: 50%;
			right: 12px;
			margin-top: -2px;
			vertical-align: middle;
		}
	}

	&[class*="col-"] .dropdown-toggle {
		width: 100%;
	}

	// The selectpicker dropdown
	.dropdown-menu {
		min-width: 100%;
		max-width: 100% !important;
		background: $gama-medio;
		@include box-sizing(border-box);

		&.inner {
			position: static;
			float: none;
			border: 0;
			padding: 0;
			margin: 0;
			border-radius: 0;
			box-shadow: none;
			background: transparent;
			color: $blanco;
		}

		li {
			position: relative;

			&.active small {
				color: #fff;
			}

			&.disabled a {
				@include cursor-disabled();
			}

			a {
				cursor: pointer;
				user-select: none;
				color: $blanco;

				&.opt {
					position: relative;
					padding-left: 2.25em;
				}

				&:hover,
				&:focus{
					background-color: rgba(0, 0, 0, 0.2);
				}

				span.check-mark {
					display: none;
				}

				span.text {
					display: inline-block;
				}
			}

			small {
				padding-left: 0.5em;
			}
		}

		.notify {
			position: absolute;
			bottom: 5px;
			width: 96%;
			margin: 0 2%;
			min-height: 26px;
			padding: 3px 5px;
			background: rgb(245, 245, 245);
			border: 1px solid rgb(227, 227, 227);
			@include box-shadow(inset 0 1px 1px fade(rgb(0, 0, 0), 5));
			pointer-events: none;
			opacity: 0.9;
			@include box-sizing(border-box);
		}
	}

	.no-results {
		padding: 3px;
		margin: 0 5px;
		white-space: nowrap;
	}

	&.fit-width .dropdown-toggle {
		.filter-option {
			position: static;
		}

		.caret {
			position: static;
			top: auto;
			margin-top: -1px;
		}
	}

	&.show-tick .dropdown-menu li {
		&.selected a span.check-mark {
			position: absolute;
			display: inline-block;
			right: 15px;
			margin-top: 5px;
		}

		a span.text {
			margin-right: 34px;
		}
	}
}

.bootstrap-select.show-menu-arrow {
	&.open > .dropdown-toggle {
		z-index: ($zindex-select-dropdown + 1);
	}

	.dropdown-toggle {
		&:before {
			content: '';
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 7px solid $color-grey-arrow;
			position: absolute;
			bottom: -4px;
			left: 9px;
			display: none;
		}

		&:after {
			content: '';
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-bottom: 6px solid white;
			position: absolute;
			bottom: -4px;
			left: 10px;
			display: none;
		}
	}

	&.dropup .dropdown-toggle {
		&:before {
			bottom: auto;
			top: -3px;
			border-top: 7px solid $color-grey-arrow;
			border-bottom: 0;
		}

		&:after {
			bottom: auto;
			top: -3px;
			border-top: 6px solid white;
			border-bottom: 0;
		}
	}

	&.pull-right .dropdown-toggle {
		&:before {
			right: 12px;
			left: auto;
		}

		&:after {
			right: 13px;
			left: auto;
		}
	}

	&.open > .dropdown-toggle {
		&:before,
		&:after {
			display: block;
		}
	}
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
	padding: 4px 8px;
}

.bs-actionsbox {
	width: 100%;
	@include box-sizing(border-box);

	& .btn-group button {
		width: 50%;
	}
}

.bs-donebutton {
	float: left;
	width: 100%;
	@include box-sizing(border-box);

	& .btn-group button {
		width: 100%;
	}
}

.bs-searchbox {
	& + .bs-actionsbox {
		padding: 0 8px 4px;
	}

	& .form-control {
		margin-bottom: 0;
		width: 100%;
		float: none;
	}
}
.dark-pagination{
	.pagination > li > a, .pagination > li > span{
		border-radius: 3px;
		color: $gama-claro;
		border-color: $gama-negro;
		background: $gama-negro;
		margin-left: 2px;
	}
	.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus{
		color: $gama-blanco;
		border-color: $gama-oscuro;
		background: $gama-oscuro;
	}
	.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
		color: $blanco;
		border-color: $gama-medio;
		background: $gama-medio;
	}
	.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus{
		color: $gama-medio;
		border-color: $gama-negro;
		background: $gama-negro;
		opacity: .5;
	}
}
