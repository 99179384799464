.section-title{
	padding-top: 10vh;
	text-align: center;
	h1,
	.iconlace,
	.isotipo{
		display: inline-block;
		vertical-align: middle;
		margin: 0 5px;
	}
	h1{
		font-family: $black-font;
		text-transform: uppercase;
	}
}
.dark-section{
	.dark-container{
		display: block;
		max-width: 800px;
		margin: 1em auto 2em;
		padding: 2em;
		background-color: rgba($gama-oscuro, .5);
		color: $gama-blanco;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
		.dark-box{
			background: rgba(13,13,13,.3);
			border: 1px solid $gama-medio;
			border-radius: 3px;
			margin: 5px 0;
			padding: 1em;
			color: $gama-claro;
			.iconlace,
			p{
				display: inline-block;
				vertical-align: middle;
				margin: 0 5px;
			}
		}
		h6{
			font-size: $regular;
			text-transform: uppercase;
			font-size: 18px;
			margin-bottom: 1em;
		}
	}
	.btn-primary{
		padding: .6em 4em;
	}
	.dark-division:after{
		content: "";
		display: inline-block;
		position: absolute;
		top: 0;
		right: 0;
		width: 1px;
		height: 100%;
		background: $gama-oscuro;
	}
}
@media (max-width:  991.98px){
	.dark-section{
		.dark-division{
			margin-bottom: 1em;
			padding-bottom: 1em;
		}
		.dark-division:after{
			content: "";
			display: block;
			position: absolute;
			top: auto;
			right: auto;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: $gama-oscuro;
		}
	}
}