/* Base CSS - Gral layout Definitions */

.bg-gama-solid{
	background: rgb(6,14,30); /* Old browsers */
	background: -moz-linear-gradient(left, rgba(6,14,30,1) 0%, rgba(40,50,79,1) 20%, rgba(40,50,79,1) 80%, rgba(6,14,30,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(6,14,30,1) 0%,rgba(40,50,79,1) 20%,rgba(40,50,79,1) 80%,rgba(6,14,30,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(6,14,30,1) 0%,rgba(40,50,79,1) 20%,rgba(40,50,79,1) 80%,rgba(6,14,30,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#060e1e', endColorstr='#060e1e',GradientType=1 ); /* IE6-9 */
}
.bg-gama-solid2{
	background: rgb(6,14,30); /* Old browsers */
	background: -moz-linear-gradient(left, rgba(6,14,30,1) 0%, rgba(40,50,79,1) 7%, rgba(40,50,79,1) 70%, rgba(6,14,30,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(6,14,30,1) 0%,rgba(40,50,79,1) 7%,rgba(40,50,79,1) 70%,rgba(6,14,30,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(6,14,30,1) 0%,rgba(40,50,79,1) 7%,rgba(40,50,79,1) 70%,rgba(6,14,30,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#060e1e', endColorstr='#060e1e',GradientType=1 ); /* IE6-9 */
}
.bg-morado{
	background: rgba(42,0,103,.9);
}
html{
	position: relative;
	min-height: 100%;
}

/* BACKGROUNDS */
body{
	margin: 0 0 55px;
	background-color: #191d2b;
	background-image: url('/assets/img/bg-new-background.webp');
	background-attachment: fixed;
	background-position: top;
	background-size: 100%;
	@media (max-width: 767.98px){
		margin-bottom: 180px;
	}
}

/* ICONS */
.mainwrapper{
	padding-top:$mp10;
}
// SHADOWS PSEUDO BEFORE GRADIENT DRAMA EFFECT
.mainslider .item:before{
	background: -moz-linear-gradient(top, rgba(13,13,13,0) 0%, rgba(13,13,13,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(13,13,13,0) 0%,rgba(13,13,13,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(13,13,13,0) 0%,rgba(13,13,13,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d0d0d', endColorstr='#0d0d0d',GradientType=0 ); /* IE6-9 */
	content: '';
	width: 100%;
	position: absolute;
	left: 0;
	bottom:0;
	z-index: 10;
}
// LISTAS

.inline-list{
	display: block;
	margin-bottom: 10px;
	li{
		display: inline-block !important;
		vertical-align: top !important;
	}
}
.options{
	position: relative;
	margin: 15px 0 0;
}
.option-list{
	.option{
		zoom: 1;
		// margin: 0 20px 15px 0;
		a{
			display: inline-block;
			font-size: 1em;
			vertical-align: top;
		}
		a:hover,
		a:focus{
			text-decoration: none;
			outline: 0;
			span,
			p{
				color: $blanco;
			}
		}
		.option-left,
		.option-body{
			display: table-cell;
			vertical-align: middle;
			height: 30px;
			.iconlace,
			span,
			p{
				display: inline-block;
				vertical-align: middle;
				text-transform: uppercase;
				margin: 0;
				padding: 0;
				padding-bottom: 2px;
			}
			.iconlace{
				color: $gama-blanco;
				font-size: 1.4em;
				margin-right: 5px;
				font-weight: normal !important;
				@media(max-width:  991.98px) {
					font-size: 1.2em;
				}
				@media(max-width:  767.98px) {
					font-size: 1em;
				}
			}
			span{
				font-size: .5em;
				display: block;
				margin: 4px 0 -8px;
			}
			p{
				font-size: .8em;
			}
			.opt-num{
				font-size: .9em;
				font-weight: 600;
			}
		}
		&:hover{
			.option-left,
			.option-body{
				.iconlace,
				span,
				p{
					color: white !important;
				}
			}
		}
	}
	.active{
		.iconlace-heart:before {
			content: "\e930" !important;
		}
		.iconlace-time:before {
			content: "\e933" !important;
		}
    .iconlace-list-off:before {
      content: "\e942";
    }
	}
	.option-big{
		.option-body{
			p{
				font-size: .7em;
			}
		}
	}
}
.options{
	.inline-list{
		li{
			vertical-align: top !important;
			line-height: 26px;
		}
		.option{
			.option-left,
			.option-body{
				line-height: 25px;
			}
		}
	}
}

.container-donation-popup {
  .content-donation-popup {
    max-width: 90%;
    text-align: center;
    align-self: center;
    p {
      font-family: 'Lato';
      font-size: 14px;
      font-weight: regular;
      color: #ffffff;
    }
  }
  .header-donation-popup {
    h2 {
      color: #FFF !important;
      padding: 10px 0;
      font-size: 24px;
      font-weight: bold;
    }
  }
  .image-donation-popup {
    width: auto;
  }
  .btn-donations-popup {
    background-color: #0AAEDE !important;
    margin-bottom: 12px;
    &:hover {
      background-color: #0AAEDE;
    }
  }
}

.tags-list{
	position: relative;
	font-size: 14px;
	padding-left: 10px;
  bottom: 3px;
	&.inline-list {
		display: inline-block;
		@media (max-width: 575.98px) {
			display: inline;
		}
	}
	.iconlace{
		position: absolute;
		left: 0;
		// top: 5px;
		font-size: 1em;
	}
	.tag-option{
		a {
			margin-right: 0.3em;
			text-transform: uppercase;
		}
		a:hover{
			color: #fff;
			text-decoration: underline;
		}
		a:after{
			display: inline-block;
			content: ",";
		}
	}
	.tag-option:last-child{
		margin-right: 0;
		p:after,
		a:after{
			content: ".";
		}
	}
}
.center{
	text-align: center;
}
figure img{
	max-width: 100%;
}

.flex {
	display: flex;
}

.inline-flex {
	display: inline-flex;
}

/* Featured of Bible */
.featured-bible-verse, .verseTextContent {
  .MarkColor1 {
    background-color: $bible-yellow;
  }
  .MarkColor2 {
    background-color: $bible-orange;
  }
  .MarkColor3 {
    background-color: $bible-violet;
  }
  .MarkColor4 {
    background-color: $bible-blue;
  }
  .MarkColor5 {
    background-color: $bible-green;
  }
}
/* End Featured of Bible */

/* generales */

/** Bible Featured Verses **/
.featured-bible-verse {
	.MarkColor1 {
		background-color: $bible-yellow;
		}
		.MarkColor2 {
		background-color: $bible-orange;
		}
		.MarkColor3 {
		background-color: $bible-violet;
		}
		.MarkColor4 {
		background-color: $bible-blue;
		}
		.MarkColor5 {
		background-color: $bible-green;
	}
}
/** End Bible Featured Verses **/

/* ng-select styling */
.ng-option,
.ng-select-container {
  background:white !important;
  border: none !important;;
  color:black !important;;
  top:-0.4em;
}

.ng-dropdown-panel-items {
    border-left: solid 1px #4c5881;
	border-top: solid 1px #4c5881;
	border-right: solid 1px #4c5881;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	padding-top: 5px;
    padding-left: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	margin-right: 5px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
	border-color: #333 transparent transparent;
    border-style: solid;
	border-width: 5px 5px 2.5px;
	margin-right: 8px;
	margin-top: 5px;
}

.ng-option.ng-option-selected .ng-star-inserted {
	font-weight: bold;
}

.ng-option.ng-option-selected .ng-star-inserted:after {
	content: url("/assets/img/icons/check.webp");
	font-weight: bold;
    width: 14px;
	height: 12px;
	float: right;
	margin-right: 10px;
    margin-top: 3px;
}

.ng-dropdown-panel .scroll-host {
	margin-bottom: -3px;
}

.ng-dropdown-panel .scroll-host::-webkit-scrollbar {
	-webkit-appearance: none;
}

.ng-dropdown-panel .scroll-host::-webkit-scrollbar:vertical {
	width: 11px;
}

.ng-dropdown-panel .scroll-host::-webkit-scrollbar:horizontal {
	height: 11px;
}

.ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb {
	background: #666;
}
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-track {
    background: #ddd;
}
/* ng-select styling */

.searchResultItem .opt-share .share-button,
.verseResultItem .opt-share .share-button {
	font-size: 20px;
}
