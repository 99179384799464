.contributions-prayers{
	margin-top: -20px;
	background: rgba(168, 3, 250, 0.6);
	background: -moz-linear-gradient(-45deg, rgba(168,3,250,1) 0%, rgba(36,44,72,1) 40%, rgba(6,14,30,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, rgba(168,3,250,1) 0%,rgba(36,44,72,1) 40%,rgba(6,14,30,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, rgba(168,3,250,1) 0%,rgba(36,44,72,1) 40%,rgba(6,14,30,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a803fa', endColorstr='#060e1e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	.cp-main:before{
		content: "";
		display: inline-block;
		position: absolute;
		top: -150px;
		left: -150px;
		width: 600px;
		height: 600px;
		background-image: url("~/assets/img/isotipo-mundo.webp");
		background-repeat: no-repeat;
		background-size: 100%;
	}
	.cp-main{
		overflow: hidden;
		position: relative;
		.cp-panel{
			text-align: center;
		}
		.lateral{
			position: relative;
			padding-top: 100px;
			text-align: center;
			.iconlace{
				font-size: 10em;
			}
			h1{
				font-family: $black-font;
				text-transform: uppercase;
				letter-spacing: 1pt;
			}
			p{
				display: inline-block;
				text-transform: uppercase;
				font-size: 16px;
				line-height: 36px;
				padding: 1em;
			}
		}
		.cp{
			padding: 100px 40px 0;
			.panel-group .panel{
				background: rgba(6, 14, 30, 0.5);
				margin-top: 20px;
				border: 0;
			}
			.panel-heading{
				padding: 0;
			}
			.panel-title{
				a{
					display: block;
					padding: 10px 15px;
					&:hover{
						text-decoration: none;
						.close{
							opacity: 1;
							@include transition(0.3s);
						}
					}
				}
				.collapsed{
					.close{
						@include transition(0.3s);
						-ms-transform: rotate(45deg); /* IE 9 */
						-webkit-transform: rotate(45deg); /* Safari 3-8 */
						transform: rotate(45deg);
					}
				}
				.iconlace,
				h2{
					display: inline-block;
					vertical-align: middle;
				}
				.iconlace{
					font-size: 2em;
					margin-right: 5px;
					&.iconlace-phone-call,
					&.iconlace-support{
						font-size: 1.5em;
					}
				}
				h2{
					font-size: $medp;
					text-transform: uppercase;
					margin: 10px 0;
				}
				.close{
					font-size: 2em;
					padding: 5px;
					color: $blanco;
					text-shadow: none;
				}
			}
			.panel-body{
				padding: 0 15px 20px;
				p.contribution-phone{
					border-bottom: 1px solid $gama-medio;
					text-align: center;
					width: 100%;
					font-size: 2em;
					font-weight: 400 !important;
					padding: 5px;
				}
				p.info{
					font-weight: 300;
					text-transform: initial;
					color: $gama-gris;
				}
			}
			.panel-group .panel-heading + .panel-collapse > .panel-body{
				border-top: none,
			}
			.btn-lg{
				width: 350px;
				max-width: 90%;
				margin: 1em 0;
			}
			#collapse-online{
				.btn-lg{
					margin: 20px 0;
				}
			}
		}
	}
}
@media (max-width:  1199.98px){
	.contributions-prayers{
		.cp-main{
			.lateral:before{
				width: 500px;
			}
		}
	}
}
@media (max-width:  991.98px){
	.contributions-prayers{
		.cp-main{
			.cp-panel{
				text-align: left;
			}
			.cp{
				padding: 0;
			}
			.lateral{
				padding-top: 70px;
				p{
					max-width: 500px;
					padding: 0;
					font-size: 16px;
				}
			}
			.lateral:before{
				width: 500px;
			}
			.dark-forms .form-group{
				margin-bottom: 0;
				p{
					margin-top: 10px;
					margin-bottom: 0;
				}
			}
		}
	}
}
@media (max-width: 767.98px){
	.contributions-prayers{
		.cp-main{
			.cp-panel{
				text-align: center;
			}
			.lateral{
				p{
					font-size: 14px;
					line-height: 24px;
				}
			}
			.cp{
				.panel-title{
					.iconlace{
						font-size: 1.4em;
						&.iconlace-phone-call,
						&.iconlace-support{
							font-size: 1em;
						}
					}
					h2{
						font-size: 14px;
					}
					.close{
						font-size: 1.4em;
					}
				}
			}
		}
	}
}
@media(max-width: 575.98px){
	.contributions-prayers{
		.cp-main{
			.lateral{
				p{
					font-size: 14px;
					line-height: 24px;
				}
			}
		}
	}
}
