.dark-forms,
.light-forms{
	.form-control{
		border-radius: 3px;
		line-height: 2;
		height: 60px;
		margin: 5px 0;
		padding: 1em;
	}
	textarea.form-control{
		max-width: 100%;
		min-height: 100px;
	}
	.form-group{
		overflow: hidden;
	}
	.form-group.center{
		text-align: center;
	}
	.form-message{
		display: block;
		margin: 1em;
		padding: 1em 2em;
		border-radius: 3px;
		.iconlace,
		p{
			display: inline;
			vertical-align: middle;
			margin: 0;
		}
		.iconlace{
			font-size: 2em;
			margin-right: 5px;
		}
	}
}
.light-forms{
	.form-group p{
		color: #28324F;
		text-transform: uppercase;
		font-weight: 600;
	}
	.form-control{
		background: $blanco;
		border: 1px solid #D8DCE8;
		color: $gama-medio;
	}
	.form-message{
		border: 1px solid $morado;
		.iconlace,
		p{
			color: $gama-medio;
		}
	}
}
.dark-forms{
	.form-control{
		background: rgba(13,13,13,.3);
		border: 1px solid $gama-medio;
		color: $gama-blanco;
	}
	.form-message{
		border: 1px solid $morado;
		.iconlace,
		p{
			color: $gama-blanco;
		}
	}
}
