button {
	background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.btn{
	border-radius: 50px;
	background-color: $gama-medio;
    border: none;
	color: $gama-blanco;
	letter-spacing: 1pt;
	text-shadow: none;
	padding: .4em 2em;
	text-transform: uppercase;
	cursor: pointer;
	transition: background-color 0.5s ease;
	&-sm{
		padding: 2px 20px !important;
	}
	&.mr-10{
		margin-right: 10px;
	}
	&:hover, &:active, &:visited, &:focus{
		background-color:$gama-gris;
		color: $blanco;
		outline:none !important;
	}
	&-default {
		background-color: $gama-medio;
		&:hover, &:active, &:visited, &:focus{
			background-color:$morado;
		}
	}
	&-primary {
		background-color: $morado;
		border: none;
		font-weight: $bold;
		cursor: pointer;
		&:hover, &:focus {
			background-color:$morado-hover;
		}
		&:active, &:visited {
			background-color:$morado;
		}
	}
	&-white{
		background-color: rgba(white,.2);
		&:hover{
			background-color: rgba(white, .5);
		}
	}
	&-success{
		background-color: $green;
		padding: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		span{
			font-size: 22px;
		}
		&:hover, &:active, &:visited, &:focus{
			background-color: $greenHover;
		}
	}
	&-info{
		background-color: $lightBlue !important;
		border-radius: 2px;
		padding: 0 8px;
		font-size: 12px;
		margin-top: 5px;
		&:hover{
			background-color: $lightBlueH !important;
		}
	}
	&-link{
		background-color: transparent;
		margin: 0 5px;
		padding: 0;
		height: 40px;
		width: 40px;
		border-radius: 30px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		span{
			font-size: 20px;
		}
		&:hover, &:active, &:visited, &:focus{
			text-decoration: none;
		}
		&.read{
			color: $lightBlue;
			height: auto;
			width: auto;
			font-size: 12px;
			margin-top: 5px;
			padding: 0 !important;
			margin-left: 0;
			&:hover{
				background-color: transparent;
				color: $lightBlueH;
			}
			&.arrow{
				&:after{
					font-family: 'iconlace' !important;
					content: "\e91f";
					font-size: 10px;
					font-weight: bold;
					margin-left: 5px;
					text-shadow: 0 0 0 $lightBlue;
				}
			}
		}
	}
	&-outline{
		&-light{
			background-color: transparent;
			border: solid 1px white;
			height: 27px;
			width: 27px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			font-size: 10px;
			&:hover{
				background-color: white;
			}
		}
	}
	&-play{
		height: 52px;
		width: 52px;
		border-radius: 60px;
	}
	&-trans{
		background-color: rgba(149, 159, 193, 0.1) !important;
		&:hover, &:focus{
			background-color: rgba(149, 159, 193, 0.4) !important;
		}
		&:active, &:visited{
			background-color: rgba(75,88,129,0.4) !important;
		}
	}
	&-lg{
		padding: 10px 40px;
	}
	&-xs{
		background-color: transparent !important;
		&:hover, &:active, &:visited, &:focus{
			background-color: transparent;
		}
	}
	&-donate{
		position: relative;
		background: $morado;
		padding: .4em 3em;
		&:before{
			display: inline-block;
			font-family: 'iconlace' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 0;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\e906";
			font-size: 1.6em;
			font-weight: 400;
			vertical-align: middle;
			margin-right: 5px;
			margin-top: -.2em;
		}
		&:hover{
			background: $morado-hover;
		}
	}
	&-contact {
		position: relative;
		background: #5B699A;
		padding: .4em 3em;
		&:before{
			display: inline-block;
			font-family: 'iconlace' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 0;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\e97b";
			font-size: 1.6em;
			font-weight: 400;
			vertical-align: middle;
			margin-right: 9px;
			margin-top: -.2em;
		}
		&:hover{
			background: #6673a0;
		}
	}
  &-nextVideo{
		position: relative;
		background: $morado;
		padding: .4em 3em;
		&:before{
			display: inline-block;
			font-family: 'iconlace' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 0;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 1.6em;
			font-weight: 400;
			vertical-align: middle;
			margin-right: 5px;
			margin-top: -.2em;
		}
		&:hover{
			background: $morado-hover;
		}
	}
}
.link-xs{
	font-size: $thumbsub;
	letter-spacing: 2pt;
	color: $gama-gris;
}
.link-xs:hover{
	color: $gama-claro;
}
button:focus,
a:focus{
	outline :none !important;
}
.navmobile {
	.social {
		.social-link {
			@media (max-width:  1199.98px) {
				margin: 0 auto !important;
				margin-top: 15px !important;
			}
		}
	}
}
.social{
	.social-link {
		display: block;
		width: 30px !important;
		height: 30px !important;
		border-radius: 50% !important;
		text-align: center !important;
		color: $blanco !important;
		padding: 0 !important;
		margin: 0 !important;
		.iconlace{
			font-size: 16px !important;
			line-height: 30px !important;
		}

	}
	.social-link:hover,
	.social-link:visited,
	.social-link:focus{
		text-decoration: none;
		color: $blanco !important;
	}
	.btn{
		&-facebook{
			background: #33416C !important;
		}
		&-twitter{
			background: #33416C !important;
		}
		&-google{
			background: $google !important;
		}
		&-instagram{
			background: #33416C !important;
		}
		&-youtube{
			background: #33416C !important;
		}
		&-email{
			background: $email !important;
		}
	}
}
ol.social{
	display: block;
	li{
		display: inline-block !important;
		margin: 1px !important;
		vertical-align: middle !important;
	}
}
.clickAll{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 800;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  cursor: pointer;
}
