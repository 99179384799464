.vod #video_detail .videoplay{
	.amp-default-skin{
		.vjs-control-bar{
			background-color: $gama-oscuro !important;
		}
		.vjs-play-progress{
			background: $morado;
		}
		.vjs-progress-holder{
			background: $gama-medio;
		}
		.vjs-big-play-button{
			border-radius: 50% !important;
		}
	}
}

.videoplay .amp-default-skin .vjs-big-play-button {
	border-radius: 50% !important;
}
.amp-default-skin .vjs-big-play-button {
	border-radius: 50% !important;
}
.amp-default-skin .vjs-big-play-button::before{
	text-shadow: none !important;
	left: 53% !important;
}
.amp-default-skin.amp-big-play-centered:hover .vjs-big-play-button, .amp-default-skin.amp-big-play-centered .vjs-big-play-button:focus {
	-webkit-transform: translate(-50%, -50%) scale(1.15);
	-ms-transform: translate(-50%, -50%) scale(1.15);
	transform: translate(-50%, -50%) scale(1.15);
	border-radius: 50%;
}

.videoplay .amp-default-skin .vjs-caption-settings .vjs-tracksettings-colors .vjs-fg-color legend {
  visibility: hidden !important;
}

.videoplay .amp-default-skin .vjs-caption-settings .vjs-tracksettings-colors .vjs-fg-color legend:after {
  content: 'Texto' !important;
  visibility: visible !important;
  display: block !important;
  border-bottom: 1px solid #e5e5e5 !important;
}

.videoplay .amp-default-skin .vjs-caption-settings .vjs-tracksettings-colors .vjs-bg-color legend {
  visibility: hidden !important;
}

.videoplay .amp-default-skin .vjs-caption-settings .vjs-tracksettings-colors .vjs-bg-color legend:after {
  content: 'Fondo' !important;
  visibility: visible !important;
  display: block !important;
  border-bottom: 1px solid #e5e5e5 !important;
}

.videoplay .amp-default-skin .vjs-caption-settings .vjs-tracksettings-controls{
  display:flex !important;
}

.videoplay .amp-default-skin .vjs-caption-settings .vjs-tracksettings-controls button.vjs-done-button {
  color: #fff !important;
  background: #0a091d !important;
  display: block !important;
  padding: 10px !important;
  margin: 5px !important;
  border-radius: 5px !important;
}

.videoplay .amp-default-skin .vjs-caption-settings .vjs-tracksettings-controls button.vjs-default-button {
  color: #fff !important;
  background: #0a091d !important;
  display: block !important;
  padding: 10px !important;
  margin: 5px !important;
  border-radius: 5px !important;
}
.amp-logo {
  display: none !important;
}