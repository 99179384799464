$mp5: 5px;
$mp10: 10px;
$mp20: 20px;
$mp40: 40px;
$mp60: 60px;

html {
  position: relative;
  min-height: 100%;
}

/* DISTANCIAS */
.mb-20{
	margin-bottom:$mp20;
}
.mt-20{
	margin-top:$mp20;
}
.mb-40{
	margin-bottom:$mp40;
}
.mt-40{
	margin-top:$mp40;
}
.mb-60{
	margin-bottom:$mp60;
}
.mt-60{
	margin-top:$mp60;
}
.pt-20{
	padding-top:$mp20;
}
.pt-40{
	padding-top:$mp40;
}
.mr-20{
	margin-right:$mp20;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
.br10 { @include border-radius(10px); }

@mixin box-shadow($blur) {
  -webkit-box-shadow: $blur;
     -moz-box-shadow: $blur;
      -ms-box-shadow: $blur;
          box-shadow: $blur;
}
.bsh20 { @include box-shadow(0 0 5px 10px); }

@mixin transition($speed) {
	transition:all ease-in-out $speed;
	-moz-transition:all ease-in-out $speed;
	-webkit-transition:all ease-in-out $speed;
	-ms-transition:all ease-in-out $speed;
}
.tr03 { @include transition(0.3s); }

@mixin transition-delay($trdelay) {
	-webkit-transition-delay: $trdelay; /* Safari */
	transition-delay: $trdelay;
}

.trdelay { @include transition-delay(1s); }

@mixin transform-scale($scale) {
	-webkit-transform: scale($scale); /* Safari */
	-moz-transform: scale($scale);
	transform: scale($scale);
}
.trscale { @include transform-scale(1.1); }

ul {
	padding: 0;
	margin: 0;
	li{
		list-style-type:none;
	}
}

:focus{
	outline: 0 !important;
	outline: none !important;
}
@mixin opacity($opacity) {
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity);
	filter: alpha(opacity=$opacity);
	-webkit-opacity: $opacity;
	-khtml-opacity: $opacity;
	-moz-opacity: $opacity;
	-ms-opacity: $opacity;
	opacity: $opacity;
}
/* fix bootstrap settings */

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object{
	height: auto;
	margin: auto;
}

/// Font.
/// @param {number (rem)} $size
/// @param {string} $weight
/// @param {hex} $color
/// @param {integer (unitless)} $line-height
/// @param {boolean} $normalize
/// @param {string} $text-transform
/// If no parameter the property is transparent and no output.
/// @output 'color', 'font-size', 'font-weight', 'line-height' and/or 'text-transform'
/// If normalize is true then also includes the following output with values of 'normal'
@mixin font(
	$font-family: 'Helvetica, Arial, sans-serif',
  $size: null,
  $line-height: null,
  $color: null,
  $weight: null,
  $normalize: false,
  $text-transform: null
) {
  color: $color;
	font-family: $font-family;
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
  text-transform: $text-transform;

  @if $normalize {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
}

.float-right {
	float: right;
}