/* Sass Document */
/* estilos checkbox */
* { box-sizing: border-box; }

%controller {
  position: relative;
  cursor: pointer;
  //padding: 1em;
  
  &::selection {
    background: transparent;
  }
  
  input.check + span {
    background: #ffffff24;
    content: "";
    display: inline-block;
    margin: 0 .5em 0 0;
    padding: 0;
    vertical-align: middle;
    width: 1.5em;
    height: 1.5em;
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    
    &::after {
      content: "";
      display: block;
      transform: scale(0);
      transition: transform .2s;
    }
  }
  
  @media screen and (min-width: 768px) {
    &:hover input.check + span {
      box-shadow: 0 2px 4px rgba(#000, .15);
    }
  }
  
  input.check:active + span {
    box-shadow: 0 4px 8px rgba(#000, .15);
  }
  
  input.check:focus + span {
    box-shadow: 0 0 0 3px lightblue;
  }
  
  input.check:checked + span::after {
    transform: scale(1);
  }
  
  input.check {
    position: absolute;
    cursor: pointer;
    opacity: 0;
  }
}

.checkbox {
  @extend %controller;
  
  input.check + span {
    border-radius: 2px;
    
    &::after {
      background: url(~/assets/img/checked.svg) no-repeat center;
      background-size: contain;
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.radio {
  @extend %controller;
  
  input.check + span {
    border-radius: 100%;
    
    &::after {
      border-radius: 100%;
      margin: .65em;
      width: .75em;
      height: .75em;
    }
  }
  
  input.check:checked + span::after {
    background: black;
  }
}

