/*! Flickity v2.2.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
  }

  .flickity-enabled:focus { outline: none; }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  /* draggable */

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
            tap-highlight-color: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  /* ---- flickity-button ---- */

  .flickity-button {
    position: absolute;
    background: transparent;
    border: none;
    color: white;
    z-index: 500;
  }

  .flickity-button:hover {
    cursor: pointer;
  }

  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19F;
  }

  .flickity-button:active {
    opacity: 0.6;
  }

  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }

  .flickity-button-icon {
    fill: currentColor;
    display: none;
  }

  /* ---- previous/next buttons ---- */

  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: calc(100% + 10px);
    border-radius:0;
    /* vertically center */
    transform: translateY(-50%);
    font-family: 'iconlace' !important;
    font-size: 22px;
  }

  .flickity-prev-next-button.previous {
    left: -40px;
    height: 100%;
    &:before{
      content: "\e91e";
    }
    &:hover{
      background: linear-gradient(to left, rgba(13, 13, 13, 0) 0%, #0d0d0d 100%);
    }
  }
  .flickity-prev-next-button.next {
    right: 0px;
    height: 100%;
    &:before{
      content: "\e91f";
    }
    &:hover{
      background: linear-gradient(to right, rgba(13, 13, 13, 0) 0%, #0d0d0d 100%);
    }
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 0px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 0px;
  }

  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  /* ---- page dots ---- */

  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }

  .flickity-rtl .flickity-page-dots { direction: rtl; }

  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
  }

  .flickity-page-dots .dot.is-selected {
    opacity: 1;
  }
