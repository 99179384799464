/*
	SkyApp Bootstrap Sass Theme
	Author: Brad Traversy
	Version: 0.0.1
*/

/* SIMPLE ROUTING FOR ASSETS*/

/// Base path for assets (fonts, images...),
/// should not include trailing slash
/// @access public
/// @type String
$asset-base-path: './assets' !default;

/// Asset URL builder
/// @access private
/// @param {String} $type - Asset type, matching folder name
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the asset
@function asset($type, $file) {
  @return url($asset-base-path + '/' + $type + '/' + $file);
}

/// Image asset helper
/// @access public
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the image
/// @require {function} asset
@function image($file) {
  @return asset('img', $file);
}

/// Font asset helper
/// @access public
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the font
/// @require {function} asset
@function font($file) {
@return asset('fonts', $file);
}

/* BASE - Base styles, variables, mixins, etc */

@import 'base/_variables.scss';
//@import 'base/_mixins.scss';
@import 'base/_normalize.scss';
@import 'base/_base.scss';
@import 'base/_lightslider.scss';
@import 'base/_iconlace.scss';
@import 'base/checkbox.scss';

/* MODULES - Individual site components */

@import 'modules/_typography.scss';
@import 'modules/_slider_programs.scss';
@import 'modules/_blocks.scss';
@import 'modules/_buttons.scss';
@import 'modules/_sections.scss';
@import 'modules/_components.scss';
@import 'modules/_forms.scss';
@import 'modules/_amp.scss';
@import 'modules/_breadcrumb.scss';
@import 'modules/_lang.scss';
@import 'modules/_load.scss';
@import 'modules/_notifications.scss';
@import 'modules/_ng_select.scss';
@import 'modules/_user_notifications.scss';

/* LAYOUTS - Page layout styles */

@import 'layouts/_nav.scss';
//@import 'layouts/_mmenu.scss';
//@import 'layouts/_header.scss';
@import 'layouts/_footer.scss';
@import 'layouts/_blog.scss';
//@import 'layouts/_mobile.scss';
@import 'layouts/_carousel.scss';
@import 'layouts/_animations.scss';
@import 'base/_mediaqueries.scss';
@import 'layouts/_herovideo.scss';
@import 'layouts/_search.scss';
@import 'layouts/_vod.scss';
@import 'layouts/_epg.scss';
@import 'layouts/_share.scss';
@import 'layouts/_tooltip.scss';
@import 'layouts/_contributions-prayers.scss';
@import 'layouts/_prayers.scss';
@import "layouts/flickity";

@keyframes skeletonPulse {
  0% { background-color: #1F2947; opacity: 1; }
  50% { background-color: #1F2947; opacity: 0.4; }
  100% { background-color: #1F2947; opacity: 1; }
}

@-webkit-keyframes skeletonPulse {
  0% { background-color: #1F2947; opacity: 1; }
  50% { background-color: #1F2947; opacity: 0.4; }
  100% { background-color: #1F2947; opacity: 1; }
}


@keyframes backInDownAnimation {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes backOutUpAnimation {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.custom-toast {
  display: flex;
  padding: 8px 16px;
  width: 250px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #1F2947;
  background-image: url("/assets/img/icons/check-share.svg");
  background-repeat: no-repeat;
  background-size: 16%;
  background-position: 20%, center;
  padding-left: 54px;
}

.custom-title {
  font-family: 'Lato';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.toast-bottom-center {
  display: flex;
  justify-content: center;
  bottom: 12PX;
  right: 0;
  width: 100%;
}

.program-detail-component {

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;

    .close {
      color: #FFFFFF;
    }
  }
}

.stars-content {
  .tooltip-inner {
    border-radius: 10px;
  }
}

.lbl_input {
  &.required::after {
    content: " *";
    font-weight: 700;
    color: #e61610;
  }
}

.form-control {
  &.ng-dirty.ng-invalid {
    border: 1px solid #FF6F05;
  }
}


.detail-container {
  .program-detail-component,
  .video-detail-component {
    margin: 5px auto 50px;
    max-width: 90%;

    @media (min-width: 768px) {
      max-width: 768px;
    }
  }
  .book-detail-component,
  .ebook-detail-component {
    margin: 5px auto 50px;
    max-width: 90%;

    .modal-header {
      display: none;
    }

    .modal-body {
      @media (min-width: 768px) {
        margin: auto !important;
        max-width: 768px;

        .principal-row {
          margin-bottom: 20px;
        }
      }
    }
  }
}
.audiobook-container-component .book-detail-component,
.ebook-container-component .ebook-detail-component {
  margin: 90px auto 50px;
  max-width: 90%;

  .modal-header {
    display: none;
  }

  @media (min-width: 768px) {
    .modal-body {
      margin: auto !important;
      max-width: 768px;

      .principal-row {
        margin-bottom: 20px;
      }
    }
  }
}

.light-black-backdrop {
  background-color: #000000 !important;
  opacity: 0.7 !important;
}