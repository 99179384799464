/* Estilos barra navegacion */
/*NAVBAR*/
$gr1:linear-gradient(top, rgba(23,26,39,0.9) 0%, rgba(22,24,37,0) 100%);
$gr2:linear-gradient(top, rgba(23,26,39,0.9) 0%, rgba(22,24,37,0.4) 100%);
$white:#fff;

body{
	min-width: 300px;
	overflow-y: scroll;
  overflow-x: hidden;
}
body.no-scroll{
	overflow: hidden;
}
.navbar{
	height: 63px;
	border:none;
	padding: 0 50px;
	color:$white;
	transition:all ease-in-out 0.3s;
	-moz-transition:all ease-in-out 0.3s;
	-webkit-transition:all ease-in-out 0.3s;
	-ms-transition:all ease-in-out 0.3s;
	background: -moz-linear-gradient(top, rgba(6,14,30,1) 0%, rgba(6,14,30,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(6,14,30,1) 0%,rgba(6,14,30,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(6,14,30,1) 0%,rgba(6,14,30,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#060e1e', endColorstr='#00060e1e',GradientType=0 ); /* IE6-9 */
	&.navbar-search {
		background: $gama-negro;
		opacity: 0.95;
	}
	.navbar-nav li > a{
		text-transform:uppercase;
		color:$white;
		// @include transition(0.3s);
	}
}
.navbar-header{
	position: relative;
}
.scrolled{
	.navbar{
		background: linear-gradient(to bottom,#060e1e 0,#28324f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}
	.navbar-header{
		position: relative;
	}
}
.scrolled .navbar:before{
	content: "";
	display: block;
	width: 300px;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: -moz-linear-gradient(left, rgba(6,14,30,1) 0%, rgba(6,14,30,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(6,14,30,1) 0%,rgba(6,14,30,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(6,14,30,1) 0%,rgba(6,14,30,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.navmobile{
	display: none !important;
}

.social{
	.btn {
		.iconlace{
			font-size: $regular;
			line-height: inherit;
		}
	}
	.btn-facebook{
		background: $facebook;
	}
	.btn-twitter{
		background: $twitter;
	}
	.btn-google{
		background: $google;
	}
}

#nav-icon-mobile,
#nav-icon-menu {
	width: 25px;
	height: 13px;
	position: relative;
	top: calc(50% - 7px);
	// margin: 5px 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	&:hover{
		:nth-child(1){
			top:1px;
		}
		:nth-child(2), :nth-child(3){
			top:7px;
		}
		:nth-child(4){
			top:13px;
		}
	}
	span{
		display: block;
		position: absolute;
		height: 1px;
		width: 100%;
		background: #fff;
		@include border-radius(9px);
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
	}
}

/* Icon Menu */

#nav-icon-mobile span:nth-child(1),
#nav-icon-menu span:nth-child(1) {
	top: 0px;
}

#nav-icon-mobile span:nth-child(2),
#nav-icon-mobile span:nth-child(3),
#nav-icon-menu span:nth-child(2),
#nav-icon-menu span:nth-child(3) {
	top: 7px;
}

#nav-icon-mobile span:nth-child(4),
#nav-icon-menu span:nth-child(4) {
	top: 14px;
}

#nav-icon-mobile.show span:nth-child(1),
#nav-icon-menu.show span:nth-child(1) {
	top: 18px;
	width: 0%;
	left: 50%;
}

#nav-icon-mobile.show span:nth-child(2),
#nav-icon-menu.show span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

#nav-icon-mobile.show span:nth-child(3),
#nav-icon-menu.show span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#nav-icon-mobile.show span:nth-child(4),
#nav-icon-menu.show span:nth-child(4) {
	top: 18px;
	width: 0%;
	left: 50%;
}
button:not(.collapsed){
	#nav-icon-mobile span:nth-child(1),
	#nav-icon-menu span:nth-child(1) {
		top: 18px;
		width: 0%;
		left: 50%;
	}

	#nav-icon-mobile span:nth-child(2),
	#nav-icon-menu span:nth-child(2) {
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	#nav-icon-mobile span:nth-child(3),
	#nav-icon-menu span:nth-child(3) {
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	#nav-icon-mobile span:nth-child(4),
	#nav-icon-menu span:nth-child(4) {
		top: 18px;
		width: 0%;
		left: 50%;
	}
}

.header-main .navbar-brand {
	padding: 10px 15px;
	height: auto;
	#enlacelogo{
		width: 220px;
		margin-top: -2px;
	}
}
#app-logo #enlacelogo{
	// @include transition(0.1s);
}
// body:not(.scrolled) #app-logo #enlacelogo{
// 	width:160px;
// }
// body.scrolled #app-logo #enlacelogo{
// 	width: 150px;
// 	// @include transition(0.1s);
// }

.show-menu {
	background: $gama-oscuro;
	&:hover {
		background: $gama-oscuro !important;
	}
}
#mainnav_btns{
	// padding-top: $mp10;
	#search-btn {
		background: transparent !important;
		// @include transition(0.2s);
		// &:hover {
		// 	zoom: 1.05;
		// }
	}
	#search-btn,
	#user-btn,
	#langselection,
	#notificationicon {
		&:hover{
			img.iconlace{
				@include transition(0.2s);
				transform: scale(1.2);
			}
		}

		&:hover{
			.ddTitleText img{
				@include transition(0.2s);
				transform: scale(1.1);
			}
		}
	}
	li{
		.mmenu{
			opacity:0;
			@include transition(0.3s);
		}
    &.show,
    &.open{

			img.iconlace{
				transform:none !important;
				-moz-transform:none !important;
			}
			.mmenu{
				opacity:1;
				height:auto;
				margin-top: 0px;
				@include transition(0.3s);
			}
		}
		a#search-btn{
			@include transition(0.3s);
		}

		&.navmega{
      &.show:hover,
      &.open:hover{
				a#search-btn{
					transform: none;
				}
			}
		}
		&.searchli &.navmega{
      &.show:hover,
      &.open:hover{
				a#search-btn{
					transform: none;
				}
			}
			&:hover{
				a#search-btn{
					@include transition(0.3s);
					transform: scale(1.2);
				}
			}
		}
	}
	#mega-mmenu{
		text-align:left;
		padding-bottom: 0;

		#menubase{
			padding: 35px 0;
    		line-height: 40px;

			.col-md-3:before {
				content: '';
				width: 10px;
				height: 140px;
				background: url("/assets/img/border.webp");
				position: absolute;
				background-repeat: no-repeat;
				left: -10px;
			}
		}
		a{
			text-transform: none;
			text-decoration: none;

			&:hover{
    			color: #bec5d2;
			}
		}
	}
	#langselection {
		line-height: 50px;
	}
}
.scrolled{
	#mainnav_btns{
		@include transition(0.3s);
	}
}

// Hover Links
/* Effect 15: scale down, reveal */
li.navlink a {
	position:relative;
	padding-top:17px;
	&:after {
		background-color: #fff;
		content:'';
		position: absolute;
		bottom: 10px;
		width:16px;
		height:2px;
		left:50%;
		margin:auto;
		margin-left:-8px;
		@include transition(0.3s);
		opacity:0;
		@media screen and (min-width: 768px) and (max-width:  1199.98px) {
			height: 0;
		}
	}
	&:hover:after{
		opacity:1;
		margin-left:-15px;
		width:30px;
		@media (max-width:  991.98px) {
			opacity: 0;
		}
	}
}
.navbar-inverse .navbar-nav{
	.open > a,
	.open > a:hover,
	.open > a:focus
	.navmega .open,
	.navmega .open:hover,
	.navmega .open:focus,
  .show > a,
  .show > a:hover,
  .show > a:focus
  .navmega .show
  {
		background: $gama-oscuro !important;
		border-radius: 3px 3px 0 0;
		outline: 0;
	}
}
#nav-icon-menu.show:before{
	content:"";
	display: block;
	position: absolute;
	background: $gama-oscuro;
	width: 50px;
	height: 63px;
	left: -12px;
	top: -18px;
	border-radius: 3px 3px 0 0;
}
/* XS MENU */
.user-xsmenu{
	max-width:400px;
	position:fixed;
	top:0;
	right:0;
	padding-top: 70px;
	display:none;
    z-index: 999;
	.bg-morado{
		position: relative;
		box-shadow: 0 2px 20px rgba(0,0,0,.6);
		border-radius: 3px;
		overflow: hidden;
	}
	.nav-title{
		margin: 0 -15px;
		padding: 1em;
		background: -moz-linear-gradient(top, rgba(40,50,79,1) 0%, rgba(40,50,79,0.94) 20%, rgba(40,50,79,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(40,50,79,1) 0%,rgba(40,50,79,0.94) 20%,rgba(40,50,79,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(40,50,79,1) 0%,rgba(40,50,79,0.94) 20%,rgba(40,50,79,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28324f', endColorstr='#0028324f',GradientType=0 ); /* IE6-9 */
		.logo-enlace{
			width: 120px;
			margin: auto;
			display: block;
		}
	}

	.nav-content{
		padding: 1em 2em;
		.nav-tabs{
			display: table;
			width: 100%;
			text-align: center;
			border-bottom: none;
			li{
				display: table-cell;
				float: none;
				margin-bottom: -1px;
				a{
					display: inline-block;
					text-transform: uppercase;
					border: none;
					border-bottom: 2px solid transparent;
				}
			}
		}
		.nav-tabs > li.active > a,
		.nav-tabs > li.active > a:hover,
		.nav-tabs > li.active > a:focus,
		.nav-tabs > li > a:hover,
		.nav-tabs > li > a:focus{
			color: $blanco;
			border-color: transparent;
			background: transparent;
			border: none;
			border-bottom: 2px solid transparent;
			outline: none !important;
		}
		.nav-tabs > li.active > a,
		.nav-tabs > li.active > a:hover,
		.nav-tabs > li.active > a:focus{
			border-bottom: 2px solid $morado;
		}
		.nav-forms{
			.dark-forms{
				padding: 1em;
				width: auto;
				margin: 0 auto;
				overflow: hidden;
				text-align: left;
				.form-control{
					background: rgba(255, 255, 255, 0.1);
					border: 1px solid rgba(167, 1, 255, 0.4);
					border-radius: 15px;
					line-height: 30px;
					height: 30px;
					margin: 5px 0;
					padding: 1em;
					color: $blanco;
				}
			}
			.btn{
				width: 100%;
				margin: 1em auto;
			}
			.social{
				.btn{
					text-transform: initial;
					margin-bottom: 0;
					padding: 5px 0 3px;
					i{
						margin-right: 1em;
						vertical-align: middle;
					}
				}
			}
			.link-xs{
				display: block;
				text-align: center;
				margin: 1em auto;
			}
			.col-xs-12:before {
				display: block;
				content: "";
				width: 100%;
				height: 2px;
				background: $morado;
				margin: 1em 0;
				opacity: .4;
			}
			.col-xs-12:first-child:before,
			.social:before{
				display: none;
			}
		}
	}
}
/* MEGA MENUES */

/* MEGA MENUES */
.megamenu{
	width:100%;
	position:fixed;
	top: 63px;
	left:0;
	background: $gama-negro;
	// padding-top: 70px;
	display: none;
	z-index: 999;
	box-shadow: 0 10px 20px rgba(0,0,0,.6);

	@media (max-width:  1199.98px) {
		position: fixed;
		left: 25%;
		top: 12.5%;
		width: 50%;
		border-radius: 3px;
		overflow: hidden;
		z-index: 9999999999;
	}
	@media (max-width: 767.98px) {
		width: 90vw;
		height: calc(90vh - 20px);
		top: 60px;
		left: 5vw;
		z-index: 9999999999;
	}
	.nav-title{
		color: $blanco;
		text-transform: uppercase;
		max-width: 97%;
		margin: 1em auto;
		.iconlace,
		h6 {
			display: inline-block;
			vertical-align: middle;
			color: inherit;
		}
		.iconlace{
			font-size: 2em;
		}
	}
	.tab-pane{
		position: relative;
		@media screen and (max-width: 767.98px) {
			height: 100%;
		}
	}
	.thumbnail {
		background:none;
		border:none;
		.caption{
			color:$blanco;
		}
	}
	.nav-content{
		padding: 1em;
	}
	.nav-tabs{
		border-bottom: none;
		margin: 1em 50px 0;
		.nav-item {
			padding: 0;
			border-right: 0;
			&.active {
				padding: 0;
				border-right: 0;
			}
			.nav-link {
				.nav-li-a-icon{
					width: 30px;
					height: 30px;
					margin: -5px 0px;
				}
			}
			.nav-link{
				font-size: 12px;
				padding: 15px 10px;
				padding-bottom: 20px;
				color: $gama-blanco !important;
				border: none !important;
				text-transform: uppercase;
			}
		}
		@media (min-width: 1660px){
			.nav-link{
				font-size: 14px;
				padding: 15px 15px 20px;
			}
		}
		.nav-link:hover,
		.nav-link:focus{
			color: $gama-claro !important;
			background: inherit;
		}
		.active{
			.nav-link{
				color: $blanco;
				background: $gama-negro !important;
			}
		}
		.nav-right{
			float: right;
			margin: .5em;
		}
		.nav-list{
			ul
				h6{
					display: inline-block;
					text-transform: uppercase;
				}
				li{
					display: inline-block;
					margin-left: 5px;
				}
		}
	}
	.tab-content{
		display: block;
		overflow: hidden;
		padding: 1em;
		margin: 0 -15px;
		background: #060e1e; /* Old browsers */
		background: -moz-linear-gradient(top, #060e1e 0%, #28324f 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #060e1e 0%,#28324f 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #060e1e 0%,#28324f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#060e1e', endColorstr='#28324f',GradientType=0 ); /* IE6-9 */
		@media (min-width: 1200px) {
			padding: 1em 50px;
		}
		@media (max-width: 767.98px) {
			padding: 1em;
			margin: 0;
		}
		h1{
			font-family: 'Panton-Light', sans-serif !important;
			font-size: 30px;
			line-height: 30pt;
			text-transform: uppercase;
			margin: 20px 0;
		}
		h1.small{
			font-size: 2.4em;
		}
	}

	.media-list{
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		// grid-template-columns: 33.33% 33.33% 33.33%;
		width: 100%;
		text-align: center;
		// margin: 1em -15px;
		// padding: 20px 0;
		padding-top: 5px;

		@media (max-width: 767.98px) {
			display: block;
			margin: 0;
		}
		@media screen and (min-width: 768px) and (max-width:  1199.98px) {
			display: block;
		}
		.media{
			margin-top: 0 !important;
			// display: grid;
			// grid-template-columns: 30% 70%;
			// vertical-align: top;
			display: flex;
			width: 33.33%;
			align-items: center;
			padding-right: 15px;
			@media (max-width: 767.98px) {
				display: block;
				width: 100%;
				margin: 0;
			}
			@media screen and (min-width: 768px) and (max-width:  1199.98px) {
				width: 100%;
			}
			&:last-child{
				padding-right: 0;
			}
			a{
				color: $gama-blanco;
				text-transform: uppercase;
				text-decoration: none;
			}
			a:hover{
				color: $blanco;
			}
			.media-left{
				// margin: 0 1em 1em 0;
				.iconlace{
					font-size: 50px;
				}
			}
			.media-body{
				text-align: left;
				margin-top: 5px;
				vertical-align: middle;
				width: auto;
				.media-heading {
					margin: 0 0 5px;
					font-weight: 300;
					font-size: 12px;
				}
				a {
					color: #bec5d2;
					text-transform: uppercase;
					font-size: 11px;
					display: block;
					margin-top: 5px;
					&:hover{
						color: #FFFFFF;
						text-decoration: none;
					}
				}
			}
		}
	}
	.bg-gama-solid{
		background: $gama-oscuro;
		@media (max-width: 767.98px) {
			width: 100%;
			position: relative;
			margin: 0 auto;
			padding: 0;
			top: 0;
			height: auto;
			background: transparent;
			& > div {
				height: 100vh;
			}
		}
		@media screen and (min-width: 768px) and (max-width:  1199.98px) {
			& > div {
				height: auto;
				max-height: 50vh;
			}
		}
	}
	.list-megamenu{
		position: relative;
		li{
			padding: 10px 20px 10px 10px;
		}
	}
	.list-megamenu:before {
		content: '';
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		width: 10px;
		height: 100%;
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
}
@media (min-width: 1200px){
	.megamenu #menu-contacto .media-list .media {
		padding-top: 20px;
	}
}

// Mega Menu deprecated

.mmenu{
	width:100%;
	position:fixed;
	top:0;
	left:0;
	background: $gama-negro;
	background: rgba($gama-negro, .6);
	padding-top: 63px;
	padding-bottom: 0;
	border: none;
	display:block;
	height:0;
	overflow: hidden;
	-webkit-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
	-ms-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
	box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
	z-index: -1;
	opacity:0;
	a,
	a:hover,
	a:focus{
		text-decoration: none;
	}

	#menu-maratonica,
	#menu-contacto{
		.media-list{
			display: table;
			width: 100%;
			text-align: center;
			margin-top: 1em;
			.media{
				display: table-cell;
				a{
					color: $gama-blanco;
					text-transform: uppercase;
					text-decoration: none;
				}
				a:hover{
					color: $blanco;
				}
				.media-left{
					float: left;
					margin: 0 1em 1em 0;
					.iconlace{
						font-size: $bigh1;
					}
				}
				.media-body{
					float: left;
					text-align: left;
					margin-top: 5px;
				}
			}
		}
	}
	.bg-gama-solid3{
		background: $gama-oscuro;
	}
	.mmenu-dropdown{
		position: relative;
		min-width: 200px;
		border-radius: 0 0 3px 3px;
		box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
		text-align: left;
		overflow: hidden;
		ul{
			margin: 0;
			li{
				padding: 0 15px;
				&:first-child a{
					padding-top: 20px;
				}
				&:last-child a{
					padding-bottom: 20px;
				}
				&.border-bottom{
					border-bottom: 1px solid #0D1E33;
					padding-bottom: 5px;
				}
				a{
					display: block;
					padding: 10px 0;
					line-height: 1;
				}
			}
		}
	}
}
.user-mmenu.dropdown-menu.show {
    opacity:1!important;
}

.navmega .user-mmenu{
  //height: 250px!important;
  top:0px !important;
  left: 0px !important;
}

.user-mmenu{
	@media screen and (max-width: 767.98px) {
		text-align: left;
	}
	text-align: center;
  	top: 0px !important;
  	left: inherit !important;
	.nav-title{
		opacity:0;
		max-width: 900px;
		vertical-align: middle;
		font-size: $medp;
	}

	.mmenu{
		width: auto;
		left: auto;
		right: 100px;
		background: transparent;
		box-shadow: none;
		overflow: visible;
		// .bg-gama-solid3{
		// 	border-radius: 3px 0 3px 3px;
		// 	-webkit-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
		// 	-moz-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
		// 	-ms-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
		// 	box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
		// }
		ul{
			text-align: left;
			// padding: 5px 20px;
			a{
				display: block;
				// line-height: 42px;
			}
			li.profile-link{
				border-bottom: 1px solid $gama-medio;
			}
			.divider{
				background-color: $gama-medio;
			}
		}
	}

	.nav-forms{
		max-width: 900px;
		margin: 0 auto;
		text-align: left;
		.dark-forms{
			padding: 2em 0;
			width: 400px;
			margin: 0 auto;
			overflow: hidden;
			text-align: center;
			.checkbox{
				text-align: left;
			}
			.link-xs{
				display: block;
				text-align: right;
			}
			.btn-default,
			.btn-primary{
				width: 70%;
				margin-top: 1em;
			}
			.social{
				h6{
					text-align: center;
					text-transform: uppercase;
					font-size: $regular;
					margin-bottom: 1em;
				}
				p{
					margin-bottom: 1em;
				}
			}
			.social:before{
				display: block;
				content: "";
				width: 100%;
				height: 2px;
				background: $gama-medio;
				margin: 2em 0;
			}
		}
	}
}

.notification-mmenu{
  text-align: center;
  top:0px !important;
  left: inherit !important;
  .mmenu {
    width: auto;
    left: auto;
    right: 106px;
    background: transparent;
    box-shadow: none;
    overflow: visible;
    .bg-gama-solid3{
      border-radius: 3px 0 3px 3px;
      -webkit-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
      -ms-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
    }
    ul{
      text-align: left;
      padding: 5px 20px;
      a{
        display: block;
        line-height: 42px;
      }
      li.profile-link{
        border-bottom: 1px solid $gama-medio;
      }
      .divider{
        background-color: $gama-medio;
      }
    }
  }
}

#search-mmenu,
#search-mmenu-mobile{
	.nav-title{
		text-align: left;
		text-transform: uppercase;
		margin: 3em 0 2em -1em;
		.iconlace,
		h3{
			display: inline-block;
			vertical-align: middle;
			font-size: $medp;
		}
		h3{
			margin: 0;
		}
	}
	.nav-content{
		text-align: center;
		position: relative;
		.nav-forms{
			.dark-forms{
				.form-control{
					font-weight: 300;
					margin: auto;
				}
				.btn-lg{
					width: 100%;
					padding: .6em 2em;
				}
				.btn{
					margin-bottom: 1em;
				}
			}
		}
	}
  .cbs.scont {
    padding: 0px;
  }
}
#favorites-mmenu{
	.nav-title{
		text-align: left;
		text-transform: none;
		font-size: 12px;
		.iconlace,h3{
			display: inline-block;
			vertical-align: middle;
			font-size: $medp;
		}
	}
	.container-fluid{
		margin:0;
		padding:0;
		min-height: 100px;
		overflow: hidden;
	}
	.fav-list{
		.item{
			display: inline-block;
			vertical-align: top;
			padding: 4px;
			a.hna-item{
				box-shadow: none !important;
				.thumb-info{
					text-align: left;
				}
				.thumb-info:after{
					left: 0;
				}
				.play-th{
					width: 30px;
					margin-left: -20px;
				}
			}
			a.hna-item:hover{
				.thumb-info{
					opacity: 1;
				}
			}
		}
	}
	.lSSlideOuter{
		margin: 5px 0 1em;
	}
	.lslide .hna-item{
		padding: 0 !important;
		.play-th img{
			width: 30px;
		}
	}
	.slider-no-content{
		position: absolute;
		top: 30px;
		right: 0;
		left: 0;
		z-index: 1;
		font-size: 1.3em;
	}
	.slider-no-content:before{
		font-family: 'iconlace' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		content: "\e90d";
		color: #232c4a;
		position: absolute;
		top: -25px;
		z-index: -1;
		font-size: 5em;
		font-weight: bold;
		margin-left: -30px;
		vertical-align: middle;
	}
}

/* Fav MMENU */
.favlist{
	width: 520px;
	max-width: 100%;
	float: right;
	padding-top: 90px;
	a{
		margin-top:$mp20;
	}
}
#fav-btn{
	&:hover a.hearticon{
		animation-name: pulse;
		-webkit-animation-name: pulse;

		animation-duration: 0.5s;
		-webkit-animation-duration: 0.5s;

		animation-iteration-count: infinite;
		-webkit-animation-iteration-count: infinite;
	}
  &.show:hover a.hearticon,
	&.open:hover a.hearticon{
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
	.iconlace{
		font-size: 1.5em;
	}
}
#favoriteslider li {
    max-width: 210px !important;
    min-width: 0 !important;
}

/* User Menu ICON */

.line-path{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:1.087;}
li#user-btn{
	position: relative;
	text-align: left;
	svg{
		width: 23px;
		height: 27px;
	}
	// a{
	// 	@include transition(0.3s);
	// }
	.user-name{
		text-transform: initial;
		padding-left: 10px;
		@media screen and (min-width: 1200px) {
			display: none;
		}
	}
	&:hover #user-head{
		margin-top:-20px;
		// @include transition(0.3s);
		transform: translateY(-3px);
	}
	a:after{
		display: none;
	}
}

/*MENU MOVIL*/

@media (min-width: 1200px){
	.mmenu {
		.tab-pane{
			margin-left: 50px;
    		margin-right: 50px;
		}
	}
}
@media (max-width:  1199.98px){
	.navbar{
		padding: 0 20px;
		.navbar-brand{
			padding: 10px 0;
		}
		.navbar-toggle {
			display: block !important;
			position: absolute;
			padding: 6px 0;
			margin: 0;
			right: 0;
			top: 20px;
			z-index: 1;
			&:hover,
			&:focus {
				background-color: transparent !important;
			}
			.nav-icon{
				display: block;
				width: 25px;
				height: 25px;
				margin-top: -10px;
				font-size: 20pt;
				color: #FFFFFF;
			}
		}
		li a{
			padding-left: 10px !important;
			padding-right: 10px !important;
		}
	}
	.mmenu {
		.nav-tabs{
			margin-left: 10%;
			max-width: 100%;
		}
		.tab-pane{
			margin-left: 50px;
    	margin-right: 50px;
		}
		.social-foot{
			display: none;
		}
	}
	li#user-btn .mmenu{
		right: 61px;
	}

  .notification-mmenu .mmenu {
    right: 55px;
  }

	.bg-gama-solid2{
		background: #28324f;
		background: -moz-linear-gradient(left, #060e1e 0%, #28324f 15%, #28324f 85%, #060e1e 100%);
		background: -webkit-linear-gradient(left, #060e1e 0%, #28324f 15%, #28324f 85%, #060e1e 100%);
		background: linear-gradient(to right, #060e1e 0%, #28324f 15%, #28324f 85%, #060e1e 100%);
	}
}

.csb{
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	}

	&::-webkit-scrollbar-thumb {
	  background-color: #4B5881;
	  border-radius: 5px;
	  outline: 1px solid #4B5881;
	}

	&.scont{
		// height:90vh;
		// overflow-y:scroll;
	}
}
.navbar-inverse .navbar-toggle{border:none;}

.mainslider .item:before {
    height: 18vh;
}
.mainslider .item:before {
	background: -moz-linear-gradient(top, rgba(13,13,13,0) 0%, $gama-negro 100%);
	background: -webkit-linear-gradient(top, rgba(13,13,13,0) 0%, $gama-negro 100%);
	background: linear-gradient(to bottom, rgba(13,13,13,0) 0%, $gama-negro 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d0d0d', endColorstr='#0d0d0d',GradientType=0 );
}
.mainslider:after {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	background: -moz-linear-gradient(top, rgba(6, 14, 30, 0) 0%, rgba(6, 14, 30, .1) 70%, #060e1e 100%);
	background: -webkit-linear-gradient(top, rgba(6, 14, 30, 0) 0%, rgba(6, 14, 30, .1) 70%, #060e1e 100%);
	background: linear-gradient(to bottom, rgba(6, 14, 30, 0) 0%, rgba(6, 14, 30, .1) 70%, #060e1e 100%);
	z-index: 1;
}
section.main-slider:after {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 200px;
	background: -moz-linear-gradient(top, #060e1e 0%, rgba(6, 14, 30, 0) 100%);
	background: -webkit-linear-gradient(top, #060e1e 0%, rgba(6, 14, 30, 0) 100%);
	background: linear-gradient(to bottom, #060e1e 0%, rgba(6, 14, 30, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6060e1e', endColorstr='#00060e1e',GradientType=0 );
	z-index: -1;
}


#notification-mmenu {
  &.show {
    .dropdown {
      display: block;
      max-height: 80vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0.5em;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: #4B5881;
        border-radius: 5px;
        outline: 1px solid #4B5881;
      }
    }
  }
  .dropdown {
    border: 1px solid #313953;
    padding: 15px 15px 0px 15px;
    width: 320px;
    display: none;
    ul {
	  padding: 0px;
    }
    li {
      overflow: hidden;
      cursor: pointer;
      border-bottom: 1px solid #465177;
      padding: 15px;
      margin: 0px -15px;
    }
    .header {
      overflow: hidden;
    }
    .left {
      display: inline-block;
      float: left;
      font-weight: bold;
    }
    .right {
      display: inline-block;
      float: right;
      font-weight: bold;
      cursor: pointer;
    }
    .noNew {
      padding: 15px 0px;
      display: block;
    }
    .img {
      float: left;
      width: 50px;
      .iconlace {
        font-size: 45px;
      }
    }
    .info {
      float: left;
      padding-left: 15px;
      width: 230px;
      .title {
        font-weight: bold;
      }
      .since {
        font-weight: normal;
        font-size: 10px;
      }
      .message {
        padding-top: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}


/* Small devices (tablets, 768px and up) */
@media (max-width:  991.98px){
	.navbar{
		// .navbar-brand{
		// 	padding: 15px 0;
		// }
		li a{
			padding-left: 10px !important;
			padding-right: 10px !important;
		}
	}
	#favorites-mmenu{
		.fav-list{
			width: 150%;
			.item{
				min-width: 100px;
			}
		}
	}
	#mainnav_btns{
		// font-size: .8em;
		h2 {
			clear: both;
		}
	}
	#search-mmenu .nav-content .nav-forms .dark-forms{
		.col-xs-9{
			width: 66.66666667%;
		}
		.col-xs-3{
			width: 33.33333333%;
		}
		.form-control{
			font-size: 30px;
		}
	}
	#search-mmenu-mobile{
		.nav-title{
			margin-left: 0;
		}
		.nav-forms{
			margin-bottom: 2em;
		}
		.form-control{
			height: 40px;
			font-size: 1.2em;
      line-height: 40px;
		}
		#default-search,
		#btn-moreresults{
			font-size: .9em;
		}
		.result-list{
			margin: 0 -15px;
			.result-item-text{
				padding-left: 0;
			}
		}
	}
}
@media (max-width: 767.98px){
	.navbar-collapse{
		padding-left: 0px;
	}
	.navbar-collapse,
	.collapse.in{
		background: $gama-negro;
		margin: 0 -10px;
	}
	.navbar{
		padding: 0 18px 0 15px;
		height: auto;
		width: 100vw;
		// .navbar-nav li > a{
		// 	padding: 8px .7em;
		// }
		.navbar-toggle{
			top: 8px;
		}
		li.navlink a:after {
			left: 1.7em;
			bottom: 5px;
		}
	}
	#search-btn-mobile{
		float:right;
		padding: 2px;
	}
	#search-mmenu.open{
		#search-mmenu-mobile{
			display: block !important;
		}
	}
	// .nommenu{
	// 	display:none !important;
	// }
	#mega-mmenu,
	.mmenu,
	.xsmenu,
	.navmega{
		display: none !important;
	}
	.navmobile{
		display: block !important;
	}

	.header-main .navbar-brand {
		height: 100%;
		#enlacelogo {
			width: 180px;
			margin-top: -5px;
		}
	}

	.navbar-inverse .navbar-toggle {
		// margin: 10px 0 10px 15px;
		// padding: 5px;
		#nav-icon-mobile{
			margin: 0 !important;
		}
	}
	.navbar-inverse .navbar-toggle:hover,
	.navbar-inverse .navbar-toggle:focus {
		background: none;
	}
	.navbar-fixed-top .navbar-collapse,
	.navbar-fixed-bottom .navbar-collapse {
		max-height: 100%;
		padding: 0 15px 40px;
	}
	#mainnav_btns{
		float: none !important;
		margin: 0;
	}
	.social-foot{
		display: table;
		width: 100%;
		text-align: center;
		li{
			display: table-cell;
		}
	}
	body:not(.scrolled) #app-logo img, body.scrolled #app-logo img {
		width: 140px;
	}
}
@media screen and (min-width: 768px) and (max-width:  1199.98px) {
	#search-mmenu.open{
		#search-mmenu-mobile{
			display: block !important;
		}
	}
	// .nommenu{
	// 	display:none !important;
	// }
	#mega-mmenu,
	.mmenu,
	.xsmenu,
	.navmega{
		display: none !important;
	}
	.navmobile{
		display: block !important;
		width: 100%;
	}

	.navbar-header,
	.scrolled .navbar-header {
		width: 100%;
	}
	.scrolled .navbar {
		transition: unset;
	}
	.navbar-fixed-top .navbar-collapse {
		width: 320px;
		height: 100vh !important;
		max-height: 100vh;
		padding: 10px 20px;
		background: #060E1E;
		position: absolute;
		right: 0;
		display: none !important;
		&.show {
			display: block !important;
		}
	}
	.social-foot{
		display: table;
		width: 100%;
		text-align: center;
		li{
			display: table-cell;
		}
	}
	.label-text {
		display: block;
	}
	.user-mmenu {
		text-align: left;
	}
}
