.modal-dialog {
  margin: 60px auto;
  background: transparent;
}

.configuration-modal {
  .modal-dialog {
    position: relative;
    top: -20%;
    width: 80%;

    @media screen and (min-width: 1200px) {
      width: 60%;
    }

    .modal-content {
      background: #28324f !important;
      width: 100%;
      position: absolute;
      padding-top: 0;
      padding-bottom: 0;

      @media screen and (min-width: 2300px) {
        width: 50%;
        left: 25%;
      }

      @media screen and (min-width: 1660px) {
        width: 60%;
        left: 17.5%;
      }
    }
  }

  @media screen and (min-width: 1660px) {
    .modal-dialog {
      .modal-content {
        left: 12.5%;
        width: 80%;
      }
    }
  }

  @media screen and (min-width: 2300px) {
    .modal-dialog {
      .modal-content {
        left: 15%;
        width: 70%;
      }
    }
  }
}

.dark-modal {
  &.modal-pray-request {
    @media (min-width: 1440px) {
      .modal-dialog {
        width: 896px;
        height: 520px;
        border-width: 0px;
      }

      .modal-content {
        padding: 0px;
        background: transparent !important;
        // background: #1F2947 !important;
        // box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        box-shadow: none !important;
        border: 0px;
      }
    }

    @media (max-width: 1439px) {
      .modal-dialog {
        width: 656px;
        height: 396px;
        border-width: 0px;
      }

      .modal-content {
        padding: 0px;
        background: transparent !important;
        // background: #1F2947 !important;
        // box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        border-radius: 20px;
        box-shadow: none !important;
        border: 0px;
      }
    }

    // @media(max-width: 767px) {
    //   .modal-dialog {
    //     width: 100%;
    //     height: 100%;
    //   }

    //   .modal-content {
    //     padding: 0px;
    //     width: 100%;
    //     height: 100%;
    //     background: #1F2947 !important;
    //     box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
    //     border-radius: 20px;
    //   }
    // }
  }

  &.modal-pray-request2 {
    .modal-dialog {
      width: fit-content;
      animation: slideInFromBottom 1s !important;
      @media screen and (max-width: 767px) {
        margin-top: 25vh !important;
        width: 100% !important;
      }
    }

    &.closing-animation {
      .modal-dialog {
        animation: slideOutToBottom 1.5s !important;
      }
    }

    .modal-content {
      background-color: #1f2947 !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;
      padding: 0;
      border-radius: 20px;

      .modal-header {
        border-bottom: 0px !important;
      }

      @media screen and (max-width: 767px) {
        border-radius: 0px;
      }
    }
  }

  &.step-one {
    .modal-dialog {
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 90%;
      }
    }
  }

  &.modal-plus-suscribe {
    .modal-content {
      border-radius: 14px !important;
      background-color: #fff !important;
    }

    .modal-dialog {
      width: 500px !important;

      @media only screen and (max-width: $break-mobile) {
        width: 90% !important;
      }
    }
  }

  &.modal-checkout-donation {
    .modal-dialog {
      width: 800px !important;

      @media only screen and (max-width: 780px) {
        width: 90% !important;
      }
    }

    .modal-content {
      background-color: rgba(1, 18, 30, 0.8) !important;
      background-image: url(https://enlaceimages.blob.core.windows.net/images/donation_screenview/donation-background.webp) !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-cancel-subscription {
    &.webview {
      overflow-y: auto !important;

      .modal-dialog {
        width: 600px !important;
        position: absolute;
        left: 50%;
        right: 50%;
        top: 200px;
        transform: translate(-50%, -50%);

        @media only screen and (max-width: 780px) {
          width: 90% !important;
        }
      }
    }

    &.notWebview {
      .modal-dialog {
        width: 600px !important;

        @media screen and (max-width: 540px) {
          margin-top: 26vh !important;
          width: 100% !important;
        }

        @media only screen and (min-width: 541px) and (max-width: 780px) {
          width: 90% !important;
        }
      }
    }

    .modal-content {
      background-color: rgba(1, 18, 30, 0.8) !important;
      background-image: url("/assets/img/bg-modal-misionero-1.webp") !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-detail-community {
    overflow-y: auto !important;

    .modal-dialog {
      width: 1200px !important;

      @media only screen and (max-width: 1024px) {
        width: 90% !important;
      }

      @media only screen and (max-width: 780px) {
        margin-top: 37vh;
        width: 90% !important;
      }

      @media only screen and (max-width: 550px) {
        width: 100% !important;
      }

      @media screen and (min-width: 321px) and (max-width: 390px) {
        margin-top: 45vh;
      }
    }

    .modal-content {
      background-color: #28324f !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;
      padding: 0;
      border-radius: 10px;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  @keyframes slideInFromBottom {
    from {
      bottom: -300px;
      opacity: 0;
    }

    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes slideOutToBottom {
    from {
      bottom: 0;
      opacity: 1;
    }

    to {
      bottom: -300px;
      opacity: 0;
    }
  }

  &.modal-detail-content {
    overflow-y: auto !important;

    &.scroll-auto {
      overflow-y: auto !important;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(40, 50, 79, 0.8);
      border-radius: 5px;
      outline: 1px solid #4B5881;
      -webkit-transition: background-color 0.5s ease;
      transition: background-color 0.5s ease;
    }

    .modal-dialog {
      margin-top: 250px !important;
      width: 640px !important;
      animation: slideInFromBottom 1s !important;

      @media screen and (max-width: 650px) {
        width: 95% !important;
      }
    }

    &.program-class {
      .modal-dialog {
        transform-origin: center top;
        transform: translateX(0px) translateY(calc(-200px + 2em)) scaleX(1) scaleY(1) translateZ(0px);
        -webkit-transform: translateX(0px) translateY(calc(-200px + 2em)) scaleX(1) scaleY(1) translateZ(0px);
        -moz-transform: translateX(0px) translateY(calc(-200px + 2em)) scaleX(1) scaleY(1) translateZ(0px);
        -ms-transform: translateX(0px) translateY(calc(-200px + 2em)) scaleX(1) scaleY(1) translateZ(0px);
        -o-transform: translateX(0px) translateY(calc(-200px + 2em)) scaleX(1) scaleY(1) translateZ(0px);
      }
    }

    &.closing-animation {
      .modal-dialog {
        animation: slideOutToBottom 1.5s !important;
      }
    }

    .modal-content {
      background-color: #161d33 !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;
      padding: 0;
      border-radius: 24px;
      margin-bottom: -220px !important;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-detail-video {
    @media screen and (max-width: 650px) {
      overflow-y: auto !important;
    }

    .modal-dialog {
      width: 640px !important;
      animation: slideInFromBottom 1s !important;
      @media screen and (max-width: 650px) {
        width: 95% !important;
      }
    }

    &.closing-animation {
      .modal-dialog {
        animation: slideOutToBottom 1.5s !important;
      }
    }

    &.scroll-auto {
      overflow-y: auto !important;
    }

    .modal-content {
      background-color: #161d33 !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;
      padding: 0;
      border-radius: 24px;
      margin-bottom: -220px !important;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-resources {
    .modal-dialog {
      width: 600px !important;
      animation: slideInFromBottom 1s !important;

      @media screen and (max-width: 768px) {
        width: 90% !important;
        margin-top: 38vh;
      }
    }

    &.closing-animation {
      .modal-dialog {
        animation: slideOutToBottom 1.5s !important;
      }
    }

    .modal-content {
      background-color: #161d33 !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;
      padding: 0;
      border-radius: 8px;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-report {
    overflow-y: auto !important;

    .modal-dialog {
      width: 610px !important;

      @media only screen and (max-width: 780px) {
        width: 90% !important;
      }
    }

    .modal-content {
      background-color: #28324f !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-checkout-subscription {
    .modal-dialog {
      width: 800px !important;

      @media only screen and (max-width: 780px) {
        width: 90% !important;
      }
    }

    .modal-content {
      background-color: #28324f !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-error-image {
    .modal-dialog {
      width: 550px !important;

      @media only screen and (max-width: 780px) {
        width: 90% !important;
      }
    }

    .modal-content {
      background-color: #28324f !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-warning-community-product {
    .modal-dialog {
      width: 550px !important;

      @media only screen and (max-width: 780px) {
        width: 90% !important;
      }
    }

    .modal-content {
      background-color: #202840 !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-sucess-community-product {
    .modal-dialog {
      width: 550px !important;

      @media only screen and (max-width: 780px) {
        width: 90% !important;
        margin-top: 45vh;
      }
    }

    .modal-content {
      background-color: #202840 !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      border: 0px !important;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }


  &.modal-gc-success{
    .modal-dialog {
      width: 560px !important;

      @media only screen and (max-width: 1023px) {
        width: 448px !important;
      }
      @media only screen and (max-width: 767px) {
        width: 377px !important;
      }
    }

    .modal-content {
      overflow: hidden;
      background-color: #1F2947 !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0;
      border: 0px !important;
      border-radius: 16px;
      height: 480px;

      .modal-header {
        border-bottom: 0px !important;
      }

      @media only screen and (max-width: 1023px) {
        height: 441px;
      }
    }
  }

  &.modal-gc-error{
    .modal-dialog {
      width: 560px !important;

      @media only screen and (max-width: 1023px) {
        width: 448px !important;
      }
      @media only screen and (max-width: 767px) {
        width: 377px !important;
      }
    }

    .modal-content {
      overflow: hidden;
      background-color: #1F2947 !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0;
      border: 0px !important;
      border-radius: 16px;
      height: 336px;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-payment {
    .modal-dialog {
      width: 550px !important;

      @media only screen and (max-width: 780px) {
        width: 90% !important;
        margin-top: 45vh;
      }
    }

    .modal-content {
      background-color: #1F2947 !important;
      background-image: none !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      padding: 0;
      border: 0px !important;

      .modal-header {
        border-bottom: 0px !important;
      }
    }
  }

  &.modal-confirmation-plus-suscribe {
    .modal-content {
      border-radius: 14px !important;
      background-color: #28324f !important;
    }

    .modal-dialog {
      width: 500px !important;

      @media only screen and (max-width: $break-mobile) {
        width: 90% !important;
      }
    }
  }

  &.modal-auth-response {
    .modal-content {
      border-radius: 12px;
      background-color: #28324f !important;
    }

    .modal-dialog {
      width: 90% !important;

      @media screen and (min-width: 1024px) {
        width: 500px !important;
      }
    }
  }

  &.modal-gift-code {
    .modal-content {
      width: 560px;
      height: 365px;
      border-radius: 10px;
      background-color: #202840 !important;
      position: fixed;
      top: 116px;
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 1023px) {
        width: 448px !important;
      }
      @media screen and (max-width: 767px) {
        width: 377px !important;
      }
    }

    .modal-dialog {
      width: 50% !important;

    }
  }

  &.modal-response-card {
    .modal-content {
      border-radius: 10px;
      background-color: #28324f !important;
    }

    .modal-dialog {
      width: 90% !important;

      @media screen and (min-width: 1024px) {
        width: 500px !important;
      }
    }
  }

  &.preview-taxes {
    .modal-content {
      border-radius: 10px;
      background-color: #0a1229 !important;
      padding: 3em 0;
    }

    .modal-dialog {
      width: 90% !important;

      @media screen and (min-width: 1024px) {
        width: 680px !important;
      }
    }
  }

  &.modal-config-change-password {
    .modal-dialog {
      width: 90% !important;

      @media screen and (min-width: 1024px) {
        width: 600px !important;
      }
    }
  }

  &.modal-config-confirm,
  &.modal-config-cancel {
    .modal-dialog {
      width: 90% !important;

      @media screen and (min-width: 1024px) {
        width: 500px !important;
      }
    }
  }

  &.modal-response-subscription {
    &.webview {
      overflow-y: auto !important;

      .modal-dialog {
        width: 600px !important;
        position: absolute;
        left: 50%;
        right: 50%;
        top: 250px;
        transform: translate(-50%, -50%);

        @media only screen and (max-width: 780px) {
          width: 90% !important;
        }
      }
    }

    .modal-dialog {
      @media screen and (min-width: 1024px) {
        width: 600px !important;
      }

      @media screen and (max-width: 540px) {
        margin-top: 26vh !important;
        width: 100% !important;
      }

      @media only screen and (min-width: 541px) and (max-width: 780px) {
        width: 90% !important;
      }
    }

    .modal-content {
      border-radius: 10px;
      background-color: #0a1229 !important;
    }
  }

  &.modal-donation-success {
    .modal-dialog {
      width: 90% !important;

      @media screen and (min-width: 1024px) {
        width: 500px !important;
      }
    }

    .modal-content {
      text-align: center;
      background-color: rgb(40, 50, 79) !important;
    }
  }

  .modal-dialog {
    margin-top: 32vh;
  }

  .modal-content {
    background-color: #4b5881 !important;
    color: #f2f3f7;
    padding: 1em 0;

    .modal-header,
    .modal-body {
      border: none;
      padding: 0 2em;
    }

    .modal-body {
      .form-control {
        background-color: transparent;
        width: 100%;
        border: solid 1px rgba(white, 0.2);
        border-radius: 3px;
        color: rgba(white, 0.2);
        font-style: italic;
      }
    }

    .modal-header {
      margin-bottom: 2em;
      border-bottom: 1px solid rgba(white, 0.2);

      h1 {
        display: inline-block;
        vertical-align: middle;
      }

      .center {
        text-align: center;
      }

      .modal-title {
        font-family: $black-font;
      }
    }

    button.close {
      color: $gama-blanco;
      text-shadow: none;
    }

    .g-recaptcha {
      max-width: 100%;
      overflow: hidden;

      iframe {
        max-width: 100%;

        .rc-anchor-error-message {
          display: none !important;
        }
      }
    }

    .dark-forms {
      margin: 5px auto;
      overflow: hidden;
      position: relative;

      .form-group {
        display: inline-block;
        width: 100%;

        p {
          text-transform: uppercase;
          font-weight: 300;
        }
      }

      .checkbox {
        text-align: left;
      }

      .form-control {
        height: 40px;
        line-height: 40px;
        margin: 5px 0;
      }

      .link-xs {
        display: block;
        text-align: right;
      }

      .btn-default,
      .btn-primary {
        margin: 1em 0.5em 0;
      }

      .btn-primary {
        padding: 0.6em 4em;
      }

      .social {
        h6 {
          text-align: center;
          text-transform: uppercase;
          font-size: $regular;
          margin-bottom: 1em;
        }

        p {
          margin-bottom: 1em;
        }

        .btn {
          .iconlace {
            font-size: $regular;
            line-height: inherit;
          }
        }

        .btn-facebook {
          background: $facebook;
        }

        .btn-twitter {
          background: $twitter;
        }

        .btn-google {
          background: $google;
        }
      }
    }

    .dark-box {
      background: rgba(13, 13, 13, 0.3);
      border: 1px solid $gama-medio;
      border-radius: 3px;
      margin: 5px 0;
      padding: 1em;
      color: $gama-claro;

      .iconlace,
      p {
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
      }
    }
  }

  &.filter-modal {
    .modal-content {
      background: #1e2334 !important;
      border-radius: 12px;

      .modal-body {
        overflow-y: scroll;
        color: $blanco;
        height: 50vh;

        & > div {
          cursor: pointer;
          height: 70px;

          img {
            margin-right: 10px;
            width: 36px;
            height: 36px;
            border-radius: 100%;
            background: $gama-claro;
          }
        }
      }
    }
  }

  &.my-configuration-modal {
    .modal-content {
      border-radius: 10px;
      background-color: #28324f !important;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);

      .modal-body {
        color: $blanco;
        margin: 0 0 15px 0 !important;
      }

      .modal-header {
        border-bottom: 0px;
        margin-bottom: 0.5em;
      }
    }

    .modal-dialog {
      max-width: 500px;
      height: 264px;

      @media only screen and (max-width: 600px) {
        width: 80%;
      }
    }
  }

  &.subscription-plan-modal {
    .modal-content {
      background-image: url(~/assets/img/bg-subscription-desktop-modal.webp) !important;
      background-size: cover;
      background-position: top right;
      border-radius: 10px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);

      @media screen and (max-width: 767px) {
        background-image: url(~/assets/img/bg-subscription-mobile-modal.webp) !important;
      }

      .modal-body {
        color: $blanco;
        margin: 0 0 15px 0 !important;
      }

      .modal-header {
        border-bottom: 0px;
        margin-bottom: 0.5em;
      }
    }

    .modal-dialog {
      width: 96% !important;
      margin-top: 55vh !important;

      @media screen and (min-width: 1024px) {
        width: 80% !important;
        margin-top: 32vh !important;
      }
    }
  }

  &.choose-user-cards-modal {
    .modal-content {
      border-radius: 10px;
      background-color: #28324f !important;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);

      .modal-body {
        color: $blanco;
        margin: 0 0 15px 0 !important;
      }

      .modal-header {
        border-bottom: 0px;
        margin-bottom: 0.5em;
      }
    }

    .modal-dialog {
      width: 90% !important;

      @media screen and (min-width: 1024px) {
        width: 640px !important;
      }
    }
  }

  &.answer-modal {
    .modal-content {
      border-radius: 10px;
      background-color: #28324f !important;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
      width: 100%;

      .modal-body {
        color: $blanco;
        margin-bottom: 15px;
      }

      .modal-header {
        border-bottom: 0px;
        margin-bottom: 0.5em;
      }
    }

    .modal-dialog {
      width: 32%;

      @media only screen and (max-width: 600px) {
        width: 80%;
      }
    }
  }
}

#modal-registration.dark-modal {
  .modal-content {
    background-image: url(~/assets/img/header-slider/bg_slider.webp);
    background-size: cover;
    background-position: top right;
  }
}

.modal-outlogin.dark-modal {
  .modal-dialog {
    width: 500px !important;

    @media only screen and (max-width: 540px) {
      width: 90% !important;
    }
  }

  .modal-content {
    background-image: url(~/assets/img/bg-new-login.webp);
    background-size: cover;
    background-position: top right;
    // /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a0b1cb+1,485f88+100 */
    // background: rgb(160,177,203); /* Old browsers */
    // background: -moz-linear-gradient(-45deg, rgba(160,177,203,1) 1%, rgba(72,95,136,1) 100%); /* FF3.6-15 */
    // background: -webkit-linear-gradient(-45deg, rgba(160,177,203,1) 1%,rgba(72,95,136,1) 100%); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(135deg, rgba(160,177,203,1) 1%,rgba(72,95,136,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a0b1cb', endColorstr='#485f88',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    text-align: center;

    .modal-body {
      h2 {
        border-top: none !important;
        padding: 0 0 1em;
      }

      .btn {
        margin: 0 5px 1em;
        border-radius: 50px !important;
      }
    }
  }
}

.modal-login.modal {
  .modal-content {
    background-image: url(~/assets/img/header-slider/bg_slider.webp);
    background-size: cover;
    background-position: top right;

    .modal-content {
      padding: 0 3em 2em;
    }

    .dark-forms {
      max-width: 400px;
      margin: 0 auto 1em;
      text-align: center;
    }

    .link-xs {
      display: block;
      text-align: right;
    }

    .col-xs-12:before {
      display: block;
      content: "";
      width: 100%;
      height: 1px;
      background: $gama-medio;
      margin: 2em 0;
    }

    .col-xs-12:first-child:before {
      display: none;
    }
  }
}

#modal-registration.modal {
  .modal-content {
    .modal-header {
      .isotipo {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}

.modal.show {
  opacity: 1 !important;
}

.dark-modal-form {
  .modal-dialog {
    width: fit-content;
    width: -moz-fit-content;
    margin-top: 25vh;
  }

  .modal-content {
    border-radius: 3px;
    background-color: #141b2b;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 991.98px) {
    .modal-content {
      width: 90vw;
    }
  }

  &.modal-add-card {
    .modal-dialog {
      width: 90% !important;

      @media screen and (min-width: 1024px) {
        width: 600px !important;
      }
    }
  }
}

.custom-fitContent .modal-dialog {
  width: fit-content;
  width: -moz-fit-content;
}

.dark-modal-book {
  .modal-dialog {
    width: -webkit-fit-content;
    width: fit-content;
    width: -moz-fit-content;
    margin-top: 25vh;
  }

  .modal-content {
    width: 642px;
    border-radius: 8px;
    background-color: #01121e;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 991.98px) {
    .modal-content {
      max-height: 90vh;
      overflow: scroll;
      width: 90vw;
    }
  }
}

.custom-fitContent .modal-dialog {
  width: fit-content;
  width: -moz-fit-content;
}
