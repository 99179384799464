
.search-results{
  text-align: left;
  margin-left: calc((100% / 12) * 3);
  h4 {
    margin-top: 0;
  }
  .filter-button {
    // margin-bottom: 20px;
  }
	.result-filters{
		.badge{
			background-color: lighten( $gama-medio, 10% );
		}
		.result-filter-info{
			background-color: lighten( $gama-medio, 10% );
			display: block;
			text-align: center;
			font-size: $thumbsub;
			padding: 5px;
			margin-bottom: 2em;
			margin-right: -15px;
		}
		.result-filter{
			margin-bottom: 1em;
		}
	}
	.result-list{
		position: relative;
		border-left: none;
    padding-left: 0px;
    padding-right: 0px;
		.result-item{
			position: relative;
			// margin-bottom: 1em;
			overflow: hidden;

		}
		.result-item-title{
			font-size: $medp;
			margin-top: 0;
			max-width: 80%;
		}
		.result-item-info{
			font-size: $thumbsub;
      text-transform: capitalize;

    }
		.result-item-description{
			display: -webkit-box;
			font-size: $thumbtit;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 3;
			margin-top: 10px;
		}
		.result-item-text .btn{
			position: absolute;
			top: 0;
			right: 0;
			font-size: .7em;
		}
		.result-item-img{
			text-align: center;
      padding-left: 0px;
      @media (max-width: 767.98px){
        padding-left: 15px;
      }
			.play-icon{
				display: inline-block;
				width: 60px;
				height: 60px;
				margin: auto;
				position: absolute;
				top: 25%;
				left: 0;
				right: 0;
				z-index: 1;
				background-image: url("~/assets/img/icons/play_thumb.svg");
				background-size: 100%;
				background-repeat: no-repeat;
				opacity:0.6;
				@include transition(0.3s);
			}
			a:hover{
				.play-icon{
					opacity:1;
					@include transition(0.2s);
					@include transform-scale(1.2);
				}
			}
		}
		#btn-moreresults{
			display: block;
			margin: 3em auto 0;
		}
	}
}
#search-mmenu{
  .nav-content {
    width: 100%;
    margin: 0;
    padding: 0 50px;
  }
	.nav-title{
		max-width: 100% !important;

		.iconlace, h3{
			font-size: 2em;
		}

		.iconlace{
			font-weight: 900;
			margin-right: 5px;
		}
	}
	.nav-content .nav-forms{
		// max-width: 100% !important;

		&.dark-forms{
			.btn{
				margin: 0;
			}
			.btn-primary:hover{
				background: $morado;
			}
		}
	}

  .nav-content {
    .nav-forms {
      .dark-forms {
        .suggestions {
            border: 1px solid #4B5881;
            background: rgba(13,13,13,0.3);
            border-top: none;
            text-align: left;
            div {
              cursor: pointer;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              padding-right: 1em;
              color: #959FC1;
            }
            span {
              border-radius: 3px;
              line-height: 40px;
              height: 40px;
              margin: 5px 0;
              margin: 1em;
              color: #959FC1;
              font-size: 24px;
              font-weight: 300;
            }
          }
        }
    }
  }
  .csb.scont {
    //before 260px
    // height: 260px;
    height: 100vh;
    margin-top: -63px;
    padding-top: 63px;
    // overflow: hidden;
    @media (max-width:  991.98px){
      height: 100vh;
    }
    &.full {
      overflow-y: scroll;
      height: 100vh;
      // .search-results {
      //   margin: 3em auto;
      // }
    }
  }
  .change-search {
    text-align: left;
    font-size: 16px;
    padding-top: 10px;
    span {
      cursor: pointer;
      border-bottom: 1px solid #FFF;
      padding-bottom: 5px;
      &::after {
        width: 28px;
        height: 20px;
        font-family: iconlace !important;
        position: absolute;
        opacity: 1;
        padding-left: 10px;
        cursor: pointer;
        content: "\E934";
      }
    }
    &.basic {
      span {
        &::after {
          content: "\E943";
        }
      }
    }
    &.advanced {
      @media (max-width:  991.98px) {
        position: absolute;
        top: 120px;
      }
    }
  }
  .margin-xs {
    @media (max-width:  991.98px) {
      margin-top: 40px;
    }
  }
}


#search-mmenu-mobile {
  .nav-content {
    .nav-forms {
      .dark-forms {
        .suggestions {
          border: 1px solid #4B5881;
          background: rgba(13,13,13,0.3);
          border-top: none;
          text-align: left;
        }
        div {
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 1em;
          color: #959FC1;
        }
        span {
          font-size: 1.2em;
          border-radius: 3px;
          line-height: 30px;
          height: 30px;
          margin: 5px 0;
          margin: 1em;
          color: #959FC1;
          font-weight: 300;
        }
      }
    }
  }

  .csb.scont {
    height: 280px;
    &.full {
      // overflow-y: none;
      height: 90vh;
    }
  }
}

#search-mmenu .nav-content .nav-forms .dark-forms .form-control{
	height: 50px;
	font-size: $bigp;
	border-color: lighten( $gama-medio, 10% );
  line-height: 50px;

}
@media (max-width:  991.98px){
	.search-results{
    margin: auto;
		.result-filters{
			margin-bottom: 1em;
			.result-filter-info{
				margin-right: 0;
				display: table;
			}
			.result-filter{
				display: inline-block;
				vertical-align: top;
				margin-right: 15px;
			}
		}
		.result-list{
			border-left: none;
			.result-item-img .play-icon{
				width: 40px;
				height: 40px;
			}
			.result-item-description{
				display: none;
			}
			#btn-moreresults{
				margin: 2em auto;
			}
		}
	}
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  #search-mmenu .nav-content .nav-forms .dark-forms .form-control {
    //before height 30px
    height: 30px;
    line-height: 30px;
    padding: 1em;
  }
}

.advanced-search {
  background: $gama-negro;
  position: fixed;
  top: 0;
  display: none;
  padding: 0 50px;
  padding-top: 63px;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  overflow-y: scroll;
  @media (max-width:  991.98px) {
    padding-top: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.full {
    display: block;
  }
  .dark-forms {
    .filter {
      position: absolute;
      top: 63px;
      left: 0;
      padding: calc(10px + 1.1rem) 20px 0 42px;
      height: 100%;
      width: calc(((100% / 12) * 2.5) + 30px);
      overflow-y: scroll;
      background: #28324F;
      box-shadow: 1px 1px 15px 2px #000000;
      -webkit-box-shadow: 1px 1px 7px 1px #000000;

      &.library {
        background: #1D243F;
        top: 0px;
        box-shadow: 1px 1px 5px 1px #000000;
        -webkit-box-shadow: 1px 1px 5px 1px #000000;

        @media (max-width: 768px) {
          display: block;
        }
      }
      &::-webkit-scrollbar{
        width: 6px;
      }
      &::-webkit-scrollbar-track{
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &::-webkit-scrollbar-thumb{
        background-color: rgba(40, 50, 79, 0);
        -moz-transition: all 1s ease-in;
        -webkit-transition: all 1s ease-in;
        -o-transition: all 1s ease-in;
        transition: all 1s ease-in;
      }
      &:hover::-webkit-scrollbar-thumb{
        background-color: rgba(40, 50, 79, 0.8);
        -moz-transition: all 1s ease-in;
        -webkit-transition: all 1s ease-in;
        -o-transition: all 1s ease-in;
        transition: all 1s ease-in;
      }
    }
    .filter-reset {
      margin-bottom: 20px;
      padding: 0;
      text-align: right;
      p {
        cursor: pointer;
        margin: 0;
        line-height: 1.5;
        color:rgba($gama-medio, .3);
        // -webkit-transition: color 0.2s ease-out;
        // -moz-transition: color 0.2s ease-out;
        // -o-transition: color 0.2s ease-out;
        // transition: color 0.2s ease-out;
        @include transition(0.2s);
      }
    }
    .filter-reset.active p {
      color: $gama-claro;
      font-weight: 400;
      @include transition(0.2s);
      &:hover {
        color: $gama-medio;
      }
    }
    .filter-title {
      margin-bottom: 20px;
      text-align: left;
      padding: 0;
      h3 {
        margin: 0;
        font-size: 20px;
        font-weight: 300;
        @include transition(0.3s);
        &:hover {
          color: $gama-medio;
        }
      }
      i {
        margin-right: 10px;
        font-size: 15px;
        cursor: pointer;
      }
    }
    .filter-body {
      padding: 0;
      margin-top: 0;
      @media (min-width: 768px) and (max-width:  991.98px) {
        margin-top: 2em;
      }
      .filter-selected {
        display: inline-block;
        margin: 0 3px 5px 0;
        float: left;
        height: 26px;
        vertical-align: middle;
        @media (max-width:  991.98px) {
          position: relative;
          z-index: 999;
        }
        span{
          display: block;
          background: $gama-medio;
          color: #fff;
          border-radius: 6px;
          padding: 0px 10px;
          font-size: 12px;
          font-weight: 300;
          letter-spacing: .5pt;
          vertical-align: middle;
          line-height: 25px;
          .iconlace{
            margin-left: 5px;
          }
        }
      }
    }
    .search-body {
      // height: calc(100vh - 71px);
      // overflow-y: scroll;
      margin-top: 10px;
      &.with-filters {
        margin-left: calc(((100% / 12) * 2.5) + 30px);
      }
    }
    .search-item {
      padding: 0px;
      text-align: left;
      margin-bottom: 30px;
      padding-bottom: 10px;
      border-bottom: 1px solid $gama-oscuro;
      h2{
        font-size: 13px;
        letter-spacing: .5pt;
        margin-bottom: 15px;
      }
      .filter-item {
        margin: 10px 0;
        font-size: 12px;
        position: relative;
        @include transition(0.2s);
        &:hover {
          color: $gama-gris;
        }
        img,
        label,
        .filter-small * {
          cursor: pointer;
        }
        img{
          width: 30px;
          height: 30px;
          font-size: 12px;
          position: absolute;
          display: inline;
          vertical-align: middle;
        }
        label.has-icon {
          padding-left: 5px !important;
        }
        label {
          height: 30px;
          overflow: hidden;
          display: block;
          padding-left: 40px;
          vertical-align: middle;
          line-height: 30px;
          text-transform: capitalize;
        }
        .filter-small {
          label {
            line-height: 24px;
            padding-left: 35px;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
      img {
        margin-right: 10px;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        background: $gama-claro;

      }
      label {
        display: inline;
        font-weight: 300;
        line-height: 24px;
      }
      .filter-item-more{
        display: block;
        color: $gama-claro;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        @include transition(0.2s);
        &:hover {
          color: $gama-blanco;
        }
      }
      .input {
        text-align: left;
        position: relative;

        .form-control {
          text-align: left;
          width: 100px;
        }

        &::after {
          display: block;
          width: 28px;
          height: 20px;
          font-family: iconlace !important;
          position: absolute;
          right: 4px;
          top: 9px;
          content: "\E909";
          opacity: 1;
          padding-left: 5px;
        }
      }

      .form-control {
        max-width: 215px;
        background: hsla(0, 0%, 5%, .3);
        border: 1px solid #a0a0a0;
        color: #a0a0a0;
        border-radius: 100px !important;
        -webkit-appearance: none;
        font-size: 12px;
        text-align: center;
        padding: 0 45px 0 12px !important;
        height: 37px !important;
        width: auto;
        font-size: 14px !important;

        option {
          background-color: #262A3E;
        }
      }

      .simple-combo {
        position: relative;
        z-index: 9;

        .btn {
          letter-spacing: 0;
          font-weight: 300;
        }

        // &::after {
        //   display: block;
        //   width: 28px;
        //   height: 20px;
        //   font-family: iconlace !important;
        //   position: absolute;
        //   right: 4px;
        //   top: 9px;
        //   content: "\E943";
        //   opacity: 1;
        //   padding-left: 5px;
        //   z-index: -1;
        // }

        button {
          background: hsla(0, 0%, 5%, .3);
          border: 1px solid #5e6ea1;
          color: #a0a0a0;
          border-radius: 100px !important;
          -webkit-appearance: none;
          font-size: 14px;
          text-align: center;
          padding: 0 45px 0 12px;
          height: 37px;
          width: auto;
          text-transform: none;
        }
      }
    }

    .checkbox {
      margin-top: 10px;
      text-align: left;
      width: 250px;

      label {
        color: #a0a0a0;
        padding-left: 0px;
      }

      input.check:focus + span {
        box-shadow: none;
      }
    }
  }

  .header-search {
    // border-top: 1px solid #a0a0a0;
    // padding: 40px 0px 20px 0px;
    width: 100%;
    .left {
      padding-top: 10px;
    }
    h3 {
      margin-top: 0;
    }
    .pull-right {
      text-align: right;
    }

    .search-history {
      cursor: pointer;
      width: 45px;
      margin-left: 15px;
      padding-right: 10px;
      border-right: 1px solid #a0a0a0;
    }

    .search-alphabet {
      width: 35px;
      cursor: pointer;
    }
  }

  .search-results {
    margin: 1em auto;
    padding-top: 0;
    @media (max-width:  991.98px) {
      padding-top: 1.5em;
    }
  }

  .passages {
    span.v {
      padding-right: 5px;
      font-style: italic;
    }
  }

  .item-list {
    // margin-top: 25px;
    .item-list-combo {
      span:first-of-type {
        cursor: pointer;
        height: 2em;
        float: left;
        border: 0;
        margin-bottom: 0 !important;
      }
    }
    .item-list-type {
      // padding-left: 0.75em;
      display: inline-block;
      margin: 0;
      width: 80%;
    }
    .item-list-more {
      // padding-right: 0.75em;
      float: right;
      width: 6%;
      @media (max-width:  991.98px) {
        width: 18%
      }
    }
    .result-item {
      padding: 0.75em;
      // margin-bottom: 20px;
      &.without-filters {
        width: 50%;
        @media screen and (min-width: 1200px) {
          width: 20%;
        }
      }

      .item-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .item-transcriptions {
          display: block;
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 3;
        }
        .item-clickable {
          display: block;
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
        &:after{
          background: -moz-linear-gradient(top, rgba(13,13,13,0) 0%, rgba(13,13,13,1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(13,13,13,0) 0%,rgba(13,13,13,1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(13,13,13,0) 0%,rgba(13,13,13,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d0d0d', endColorstr='#0d0d0d',GradientType=0 ); /* IE6-9 */
          content: '';
          width: calc(100% - 20px);
          position: absolute;
          left: 10px;
          bottom: 10px;
          height: 90px;
        }
        .item-info {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .play-icon {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin: auto;
          position: absolute;
          top: 25%;
          left: 0;
          right: 0;
          z-index: 2;
          background-image: url("~/assets/img/icons/play_thumb.svg");
          background-size: 100%;
          background-repeat: no-repeat;
          opacity: 0.6;
          @include transition(0.3s);
        }
        &:hover {
          .play-icon {
            opacity: 1;
            @include transition(0.2s);
            @include transform-scale(1.2);
          }
        }

      }
      .result-info {
        padding-top: 15px;
        // min-height: 180px;
        .result-item-title {
          font-size: 1em;
          max-width: initial;
          max-width: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .result-item-description {
          text-transform: none;
          height: 80px;
        }

      }

    }

    .transcript-wrapper {
      min-height: 150px;
    }

    .result-transcripts {
      margin-top: 10px;
      max-height: 150px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0.5em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #4B5881;
        border-radius: 5px;
        outline: 1px solid #4B5881;
      }

      .item-transcript {
        cursor: pointer;
        color: #000;
        padding: 10px 5px;
        background-color: #FFF;
        margin-bottom: 5px;
        font-size: 12px;
        color: #4a4a4a;
        overflow: hidden;

        &:hover {
          background-color: #E2E2E2;
        }

        .transcript-icon {
          color: #000;
          width: 25px;
          float: left;
        }

        .transcript {
          float: left;
          width: 70%;
          padding-top: 4px;

          .highlight {
            font-weight: bolder;
            color: #000;
          }
        }

        .time {
          float: right;
          padding-top: 4px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.ddp-ministry {
  color: #a0a0a0;
  font-size: 14px;
  background-color: #262A3E;
  z-index: 9999999999;
  border: 1px solid #5e6ea1;
  padding: 7px;
  max-height: 200px;
  overflow-y: scroll;
  position:fixed;
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #4B5881;
    border-radius: 5px;
    outline: 1px solid #4B5881;
  }
  .dropdown-item {
    padding: 0px 5px;
    label {
      vertical-align: top;
      color: #a0a0a0;
      font-weight: normal;
    }
  }

}

.csb::-webkit-scrollbar-thumb {
  background-color: rgba(40, 50, 79, .8);
  border-radius: 5px;
  outline: 1px solid #4B5881;
  transition: background-color 0.5s ease;
}
.filter:hover{
  .csb::-webkit-scrollbar-thumb{
    background-color: rgba(40, 50, 79, 1);
    transition: background-color 0.5s ease;
  }
}

