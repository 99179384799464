/* COLORS */
$primary-text-color: #fff;
$morado: #6C00C0;
$morado-hover: #8B00F5;
$morado-actions: #9E1FFF;
$main: $morado;
$blanco: #fff;
$negro: #0D0D0D;
$blue: #0035ff;
$blue-dark: #081D72;
$gama-negro: #060E1E;
$gama-oscuro: #28324F;
$gama-medio: #4B5881;
$bg-tooltip: $gama-medio;
$gama-medio-trans: rgba(75, 88, 129, 0.5);
$gama-gris: #959FC1;
$gama-claro: #B5C4E5;
$gama-blog: #ccd5e7;
$gama-blanco: #F2F3F7;
$facebook: #385185;
$twitter: #00ACED;
$lightBlue: #0aaede;
$lightBlueH: #1b9fc7;
$instagram: #d10869;
$youtube: #FF0017;
$google: #F34A38;
$email: #ffcc00;
$green: #19ab7a;
$greenHover: #0c8f63;
$greenLight: #4effc4;
$color-red-error: rgb(185, 74, 72) !default;
$color-grey-arrow: rgba(204, 204, 204, 0.2) !default;
$width-default: 220px !default; // 3 960px-grid columns
$zindex-select-dropdown: 1060 !default; // must be higher than a modal background (1050)
//** Placeholder text color
$input-color-placeholder: #999 !default;
/** Bible Featured Verses **/
$bible-yellow: #FFF266;
$bible-orange: #FFB680;
$bible-violet: #C8D6FE;
$bible-blue: #99F5FF;
$bible-green: #B2FFE5;
/** End Bible Featured Verses **/
/* Responsive Breaks */
$break-high-desktop: 1600px;
$break-medium-desktop: 1440px;
$break-small-laptop: 1280px;
$break-tablet: 1024px;
$break-small-tablet: 768px;
$break-mobile: 480px;
$break-medium-mobile: 375px;
$break-small-mobile: 320px;
/* End Responsive Breaks */