/* compartir */
.videoplay .vdetails .opt-share{
	padding: 5px 10px !important;
	margin-top: -5px;
}
.social-share{
	display: flex;
	list-style: none;
	padding: 0;
	.social-link {
		display: block;
		width: 30px !important;
		height: 30px !important;
		border-radius: 50% !important;
		text-align: center !important;
		color: #fff !important;
		padding: 0 !important;
		margin: 0 !important;
	}
	.social-link,
	.social-link:hover,
	.social-link:visited,
	.social-link:focus{
		text-decoration: none;
		color: #fff !important;
	}
	.btn-facebook:hover{
		color: #608be6 !important
	}
	.btn-twitter:hover{
		color: #00ACED !important;
	}
	.btn-google:hover{
		color: #F34A38 !important;
	}

}
.option-list .option a {
	display: inline-block;
	font-size: 1em;
	vertical-align: top !important;
	height: 30px;
	display: flex;
	align-items: center;
	@media (max-width: 575.98px) {
		padding-left: 5px;
	}
}
.opt-share{
	position: relative;
	border-radius: 25px;
	padding: 0px 10px;
	transition:all ease-in-out 0.3s;
	.options-share{
		display: inline-block;
		vertical-align: top;
		margin-left: 5px;
		overflow: hidden;
		height: 30px !important;
		width: 0;
		transition:all ease-in-out 0.3s;
		transition-delay: 0.1s;
	}
	.social-share{
		min-width: 90px;
	}
	a{
		display: inline-block !important;
		vertical-align: top;
	}
	.iconlace{
		display: inline-block !important;
		vertical-align: top;
		&-share::before{
			font-size: .9em;
			vertical-align: top;
		}
	}
}
.opt-share:hover{
	background: #28324f;
	.options-share{
		width: 90px;
	}
}
/*@media(max-width:  1199.98px){
	.opt-share{
		display: block !important;
		clear: both !important;
		max-width: 220px;
		margin-top: 5px;
	}
}
@media(max-width:  991.98px){
	.opt-share{
		display: inline-block !important;
		margin-top: -5px;
	}
}*/
@media(max-width: 575.98px){
	.videoplay .vdetails .opt-share{
		padding: 0 5px;
		margin-top: 0;
	}
	.social-share .social-link{
		width: 25px !important;
	}
	/*.opt-share{
		display: block !important;
		max-width: 190px;
		margin-top: 5px;
	}*/
}

.opt-share-like{
  position: relative;
  border-radius: 25px;
  //padding: 0px 10px;
  transition:all ease-in-out 0.3s;
  .options-share{
    display: flex;
    vertical-align: top;
    margin-left: 5px;
    overflow: hidden;
	height: 30px !important;
	width: 0;
    transition:all ease-in-out 0.3s;
    transition-delay: 0.1s;
  }
  .social-share{
    min-width: 120px;
  }
  a{
    display: flex;
    vertical-align: top;
  }
  .iconlace{
    display: flex;
    vertical-align: top;
  }
}
.opt-share-like:hover{
  .options-share{
    width: 90px;
  }
}
