.breadcrumb {
    display: flex;
    align-items: center;
    background: none;
    background: transparent !important;
    padding-left: 30px;
    padding-bottom: 15px;
    margin: 0;
    z-index: 50;
    a,
    a:visited,
    a:hover,
    a:focus{
        @include transition(0.3s);
        text-decoration: none;
    }
    li {
        font-family: $p-bold;
        font-size: 14px;
        color: $gama-blanco;
        vertical-align: middle;
    }
    li:before {
        font-family: 'iconlace' !important;
        content: "\e902" !important;
        color: $gama-medio !important;
        font-size: 12px;
        padding: 0 5px !important;
        opacity: .5;
        @include transition(0.3s);
    }
    li:hover::before{
        opacity: 1;
        @include transition(0.3s);
    }
    li:first-child:before {
        display: none;
    }
    li.active {
        color: $blanco;
    }
    .bread-home {
        display: block;
        margin-left: -31px;
    }
    .isotipo {
        display: block;
        width: 24px;
        height: 24px;
        background-image: url(~/assets/img/icons/isotipo.webp);
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .isotipo-b {
        display: block;
        width: 24px;
        height: 24px;
        background-image: url(~/assets/img/icons/enlace-isotipo-b.webp);
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .separator{
        font-size: 1em;
        margin: 5px;
        .iconlace{
            font-weight: bold;
        }
    }
    .level {
        text-transform: uppercase;
        text-decoration: none;
        line-height: 1.5em;
        vertical-align: text-bottom;
        &.first{
            font-size: 18px;
            line-height: 1em;
        }
        &.last {
            text-transform: none;
            font-size: 18px;
            line-height: 1em;
            cursor: default;
        }
    }
    @media (max-width: 991.98px){
        .level {
            line-height: 1.2em;
            &.first{
                font-size: 16px;
            }
            &.last {
                font-size: 14px;
            }
        }
    }
}
