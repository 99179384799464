.epg-container {
	position: relative;
	width: 100%;
	overflow: hidden;
	display: block;
	margin: 1em auto;
	border-radius: 3px 3px 0 0;
	color: $blanco;
	background: rgb(40,50,79); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(40,50,79,1) 0%, rgba(6,14,30,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(40,50,79,1) 0%,rgba(6,14,30,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(40,50,79,1) 0%,rgba(6,14,30,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28324f', endColorstr='#060e1e',GradientType=0 ); /* IE6-9 */
	
	.left-arrow,
	.right-arrow {
		display: none;
		position: absolute;
		height: 100%;
		top: 0px;
		border: none !important;
		background: rgba($gama-oscuro, .6);
		.iconlace{
			margin: 5px;
			text-shadow: 0 0 4px $negro;
		}
	}
	.left-arrow{
		left: 0px;
	}
	.right-arrow {
		right: 0px;
	}
	.right-arrow:hover{
		background: rgba($gama-oscuro, .9);
	}
	
	ul.listings-grid {
		list-style-type: none;
		padding: 0; 
		margin: 0; 
		white-space:nowrap;
		overflow: hidden;

		.listings-timebar {
			height: 50px;
			background: $gama-medio;
		}

		.listings-channel-row {
			height: auto;
			padding: 0;
			border-bottom: 3px solid $gama-medio;
			overflow: hidden;
			position: relative;
		}

		.listings-timebar-time {
			width: 20%;
			height: 50px;
			vertical-align: middle;
			float: left;
			padding: 15px;
			border-left: 1px solid rgba($gama-negro, .3);
		}
		.listings-timebar-time:first-child{
			border-left: none;
		}

		.listings-channel { 
			text-align: center;
			text-transform: uppercase;
			img {
				max-width:100%;
				max-height:100%;
			}
		}

		.listings-channel,
		.listings-program {
			width: 20%;
			float: left;
			padding: 15px;
			border-left: 1px solid rgba($gama-negro, .3);
			overflow: hidden;
		}

		.listings-program-title{
			max-width: 80%;
			h3{
				display: inline;
			}
		}

		.listings-details {
			p{
				font-size: .8em;
				margin: 15px 0 5px;
			}
		}
		.listings-fav-option{
			float: right;
			.iconlace{
				font-size: 1.5em;
				color: $morado;
			}
		}
	}
}