.notification-number {
  border-radius: 50%;
  background-color: #A700FF;
  overflow: hidden;
  position: absolute;
  height: 20px;
  width: 22px;
  font-size: 12px;
  line-height: 18px;
  left: 28px;
  top: 8px;
  z-index: 99;
  text-align: center;
  @media (max-width:  1199.98px){
    left: 22px;
  }
}

.dark-modal {
  overflow: hidden !important;
  &.notification-modal {
    .modal-content {
      background-color: #111834 !important;
      border-top: 8px solid #9B10AF;
      border-radius: 0px;
      padding: 1em 0;
      .modal-title {
        font-family: 'Lato';
        font-size: 26px;
        font-weight: bold;
      }
    }
    .modal-body {
      max-height: 450px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0.5em;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: #4B5881;
        border-radius: 5px;
        outline: 1px solid #4B5881;
      }
      .block-notifications {
        .title {
          font-weight: bold;
          font-size: 16px;
          border-bottom: 6px solid #262D46;
          padding-bottom: 7px;
          margin-bottom: 10px;
        }
        .notification {
          overflow: hidden;
          cursor: pointer;
          padding: 15px;
          margin: 0px -15px;
          .img {
            float: left;
            width: 50px;
            .iconlace {
              font-size: 45px;
            }
          }
          .info {
            float: left;
            padding-left: 15px;
            .title {
              font-weight: bold;
              font-size: 18px;
              border-bottom: none;
              padding-bottom: 0px;
              margin-bottom: 0px;
            }
            .since {
              font-weight: normal;
              font-size: 12px;
            }
            .message {
              padding-top: 3px;
            }
          }
        }
      }
    }
  }
}
