/* Google Font import */

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
$base-font-family: 'Lato', sans-serif;

@font-face {
  font-family: 'Panton';
  src: url('/assets/fonts/Panton-BlackCaps.otf') format('otf');
  src: url('/assets/fonts/Panton-BlackCaps.woff') format('woff');
  src: url('/assets/fonts/Panton-BlackCaps.woff2') format('woff2');
  font-style: normal;
}
$black-font: 'Panton', sans-serif;


/* General font styles */
body{
  font-family: $base-font-family;
  color: $primary-text-color;
}

$bold:700;
p{
	font-weight: 300;
}

/* General font sizes */
$regular: 18px;
$bigp: 24px;
$medp: 20px;
$bigh1: 50px;
$bigh2: 38px;
$bigh3: 28px;
$bigh4: 70px;
$ctasmall:16px;
$cta:20px;
$thumbtit:16px;
$thumbsub:12px;
$detp:16px;

$footer:16px;
$copy:10px;
$blancofoot:#D8DCE8;

/* LINKS */
a{
	color: $gama-blanco;
	text-decoration: none;
  cursor: pointer;
}
a:hover{
	color: $blanco;
}

/* new panton font */
@font-face {
  font-family: 'Panton-Light', sans-serif;
  src: url('/assets/fonts/Panton-Light.otf') format('otf');
  src: url('/assets/fonts/Panton-Light.woff') format('woff');
  src: url('/assets/fonts/Panton-Light.woff2') format('woff2');
  font-style: normal;
}
$p-light: 'Panton-Light', sans-serif;

@font-face {
  font-family: 'Panton-Regular', sans-serif;
  src: url('/assets/fonts/Panton-Regular.otf') format('otf');
  font-style: normal;
}
$p-normal: 'Panton-Regular', sans-serif;
@font-face {
  font-family: 'Panton-Italic', sans-serif;
  src: url('/assets/fonts/Panton-Italic.otf') format('otf');
  font-style: italic;
}
$p-italic: 'Panton-Italic', sans-serif;

@font-face {
  font-family: 'Panton-SemiBold', sans-serif;
  src: url('/assets/fonts/Panton-SemiBold.otf') format('otf');
  font-style: normal;
}
$p-semib: 'Panton-SemiBold', sans-serif;
@font-face {
  font-family: 'Panton-SemiBoldItalic', sans-serif;
  src: url('/assets/fonts/Panton-SemiBoldItalic.otf') format('otf');
  font-style: italic;
}
$p-semibitalic: 'Panton-SemiBoldItalic', sans-serif;

@font-face {
  font-family: 'Panton-Bold', sans-serif;
  src: url('/assets/fonts/Panton-Bold.otf') format('otf');
  font-style: normal;
}
$p-bold: 'Panton-Bold', sans-serif;

@font-face {
  font-family: 'Panton-Black', sans-serif;
  src: url('/assets/fonts/Panton-Black.otf') format('otf');
  font-style: normal;
}
$p-black: 'Panton-Black', sans-serif;
/* new panton font */

