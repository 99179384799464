footer {
  background: #161D33; 
  width: 100%;
  position: absolute;
  height: 65px;
  .simplefoot{
    display: flex;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 65px;
    font-size: 14px;
    height: 100%;
    a{
      color:$blancofoot;
      text-decoration: none;
    }
    &-social{
      -webkit-flex: 20%;
      flex: 20%;
    }
    &-legacy{
      -webkit-flex: 60%;
      flex: 60%;
    }
    &-logo{
      -webkit-flex: 20%;
      flex: 20%;
    }
    .social-foot{
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      margin: 0;
      padding: 0;
    }
    .legacy-foot{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      p,a{
        color: $gama-claro;
      }
      .margin {
        margin-right: 16px !important;
      }
      .privacyterms-foot,
      .copy-foot{
        .separator{
          display: inline-block;
          vertical-align: middle;
          width: 10px;
        }
        p,a{
          display: inline-block;
          font-weight: 400;
          color: #A8AABC;
          margin: 0;
          line-height: 16px;
        }
        a:hover{
          color: #FFFFFF;
        }
      }
    }
    .enlacelogo-foot{
      display: flex;
      align-items: center;
      flex-direction: row;
      .iso-foot{
        position: relative;
        width: 36px;
        margin-right: 1em;
        img{
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 1220.98px){
    .simplefoot{
      .legacy-foot{
        flex-direction: column;
      }
    }
  }
  @media (max-width: 1024px){
    .simplefoot{
      padding: 0 30px;
      font-size: 12px;
      .social-foot {
        gap: 8px;
      }
      .legacy-foot {
        gap: 8px;
      }
    }
  }
  @media (max-width: 991.98px){
    margin-top: 1em;
    .simplefoot{
      padding: 0 25px;
    }
  }
  @media (max-width: 767.98px){
    height: 180px;
    .simplefoot{
      padding: 10px 15px;
      flex-direction: column;
      &-social,
      &-legacy,
      &-logo{
        -webkit-flex: 33%;
        flex: 33%;
        padding: 5px 0;
      }
      .social-foot{
        gap: 16px;
      }
      .legacy-foot .privacyterms-foot a{
        margin-bottom: 5px;
      }
    }

  }
}
