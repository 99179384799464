#loading{
	position: fixed;
	text-align: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, .75) !important;
	z-index: 10050;
	overflow: hidden;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	outline: 0;
	animation-duration: 1.5s;
	-webkit-animation-duration: 1.5s;
	animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: ease-in-out;
	margin-top: 1px;
	p{
		font-family: 'Panton-Regular','Panton', sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: #fff;
	}
	.loading .sk-cube-grid{
		margin-top: 30vh;
		margin-bottom: 30px;
	}
}
#loading.in{
	display: block;
}
