/*section.vod{
	position: relative;
}
section.vod:after{
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 200px;
	background: -moz-linear-gradient(top, #060e1e 0%, rgba(6, 14, 30, 0) 100%);
	background: -webkit-linear-gradient(top, #060e1e 0%, rgba(6, 14, 30, 0) 100%);
	background: linear-gradient(to bottom, #060e1e 0%, rgba(6, 14, 30, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6060e1e', endColorstr='#00060e1e',GradientType=0 );
	z-index: -1;
}*/
.vod{
	.videoplay {
		.vdetails {
			overflow: hidden;
			height: 0;
			@include transition(0.3s);
		}
	}
	.videoplay.open{
		.vdetails {
			height: auto;
			@include transition(0.3s);
		}
	}
}
#video_detail{
	min-height: 70vh;
    overflow: hidden;
    position: relative;
}
.videoplay {
	display: inline-block;
	height: 100%;
	padding-top: 60px;
	margin-bottom: 20px;
	width: 100%;
	&.notfound {
		text-align: center;
	}
	.embed-responsive .embed-responsive-item,
	.embed-responsive iframe,
	.embed-responsive embed,
	.embed-responsive object{
		height: 100%;
	}
	.vdetails {
		padding: 30px 0 0 50px;
		.program-options {
				.iconlace {
					font-size: 0.8em;
				}
				li {
					padding: 0 !important;
					margin: 0;
					margin-right: 10px;
					font-size: 1em !important;
					& .like {
						margin-right: 10px;
					}
				}
				.option-follow {
					.option-left,
					.option-body {
						display: inline;
					}
					button {
						background: transparent;
						padding: 0;
						width: auto;
						height: auto;
						p {
							line-height: 2.5em;
						}
					}
					.simple-combo-follow::after {
						display: none;
					}
				}
				.opt-share {
					.options-share {
						display: block;
						margin: 0 auto;
						.social-share li {
							margin: 0;
						}
					}
					&:hover {
						background: transparent;
						.options-share {
							display: block;
							margin: 0 auto;
						}
					}
				}
			}
	}
	.voddate {
		text-transform: capitalize;
		font-weight: 100;
		color: gray;
		.options{
			font-size: 1.5em;
		}
	}
	.vplayer{
		padding-left:55px;
	}

}
.vdetails,
.pvdetails{
	.tag{
		display: block;
		text-transform: uppercase;
		font-weight: 300;
		color: $gama-claro;
	}
	.program-title{
		font-size: 1.75em;
		text-transform: capitalize;
		font-weight: 900;
	}
	.chapter-title{
		font-size: 1.3em;
		text-transform: uppercase;
		font-weight: 300;
	}
	.description{
		display: block;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 20px 0 10px 0;
		-webkit-line-clamp: 3;
		-moz-line-clamp: 3;
		-ms-line-clamp: 3;
		line-clamp: 3;
		-webkit-box-orient: vertical;
		-moz-box-orient: vertical;
		-ms-box-orient: vertical;
		box-orient: vertical;
		height: 73px;
		height: 1.1em 1.5em 3;
		line-height: 1.5em;
		font-size: 1.1em;
	}
}
.pvdetails{
	.description{
		height: 92px;
	}
}

.pausedetailblur{
	position: absolute;
	width: 100%;
	height: 100%;
	min-height: 70vh;
	top: 0;
	left: 0;
	background: $gama-negro;
	padding-top: 100px;
	z-index: 10;
	.blurbg-img{
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		img{
			min-height: 100%;
			float: right;
		}
	}
	.pvdetails{
		position: relative;
		padding: 10px 0 0 50px;
		font-size: $regular;
		font-weight: 400;
		text-shadow: 0 1px 10px $gama-negro;
		z-index: 12;
		.program-title{
			font-size: $bigh4;
		}
	}
	.playbtn {
		display: flex;
		justify-content: center;
		flex-flow: column;
		position: relative;
		min-height: 40vh;
		text-align: center;
		z-index: 12;
		a{
			display: inline-block;
			position: relative;
			width: 200px;
			height: 200px;
			margin: auto;
			img{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				opacity: 0.4;
				@include transition(0.3s);
			}
		}
		a:hover{
			img{
				opacity: 1;
				@include transition(0.2s);
				@include transform-scale(1.2);
			}
		}
	}
	&:after{
		content: '';
		width: 100%;
		height: 160px;
		position: absolute;
		bottom: 0;
		left: 0;
		background: -moz-linear-gradient(top, rgba(8,15,33,0) 0%, rgba(8,15,33,1) 100%);
		background: -webkit-linear-gradient(top, rgba(8,15,33,0) 0%,rgba(8,15,33,1) 100%);
		background: linear-gradient(to bottom, rgba(8,15,33,0) 0%,rgba(8,15,33,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00080f21', endColorstr='#a6080f21',GradientType=0 );
		z-index: 11;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 10;
		background: rgba(6,14,30,.4);
		background: -moz-linear-gradient(left, rgba(6,14,30,1) 0%, rgba(40,50,79,1) 30%, rgba(40,50,79,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(6,14,30,1) 0%, rgba(40,50,79,1) 30%, rgba(40,50,79,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(6,14,30,1) 0%, rgba(40,50,79,1) 30%, rgba(40,50,79,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#060e1e', endColorstr='#0028324f',GradientType=1 ); /* IE6-9 */
	}
}

.hnav-title{
	overflow: hidden;
	clear: both;
	.datepicker{
		padding: 20px 40px 0 0;
	}
}
.datepicker{
	display: inline-block;
	float: right;
	span,
	p,
	i{
		display: inline-block;
		vertical-align: middle;
		color: $gama-claro;
		margin: 0;
	}
}
@media (max-width:  1199.98px){
	.videoplay {
		.vplayer{
			padding-left:10px;
		}
	}
	.pausedetailblur{
		.pvdetails{
			.program-title{
				font-size: 2em;
			}
		}
	}
}
@media (max-width:  991.98px){
	.videoplay {
		.vdetails {
			.options{
				font-size: 1.2em;
			}
		}
	}
	.pausedetailblur{
		.playbtn{
			a{
				width: 120px;
				height: 120px;
			}
		}
	}
}
@media (min-width: 992px) {
// @media (max-width:1389px){
	.videoplay {
		.vdetails {
			height: 330px;
			display: grid;
			grid-template-rows: repeat(2, 1fr 2fr) 1fr;
			grid-template-columns: 100%;
			grid-row-gap: 5px;
			padding: 0;
			padding-right: 3em;
			.program-program,
			.program-title,
			.program-host,
			.program-description,
			.see-more,
			.program-options,
			.program-tags,
			.see-more-tags,
			.voddate {
				margin: 0;
			}
			.program-host {
				font-size: 1.125em;
				font-weight: 500;
			}
			.program-description {
				position: relative;
				padding-right: 3em;
				.see-container.show {
					display: inline !important;
					.show {
						display: inline !important;
					}
				}
				p,
				span {
					margin: 0;
				}
				p {
					font-size: 1em;
					&.full-text {
						display: inline;
						margin-right: 5px;
					}
				}
				span {
					font-size: 1em;
					display: inline;
				}
			}

			.program-tags {
				padding-right: 3em;
				ul {
					margin: 0;
				}
				li {
					line-height: 0.75em;
				}
				p {
					display: inline;
					font-size: 0.75em;
				}
			}
			.options {
				font-size: 1em;
			}
			.voddate {
				margin-top: 10px;
			}
		}
	}
}
@media (min-width: 768px){
	.videoplay {
		.vdetails {
			padding: 10px 0 0 15px;
			.options{
				font-size: 1.2em;
			}
		}
	}
	.pausedetailblur{
		.pvdetails{
			padding-left: 10px;
		}
		.playbtn{
			min-height: auto;
			a{
				width: 80px;
				height: 80px;
				margin: 0 10px;
			}
		}
	}
	.options br{
		display: none;
	}
}
@media (max-width: 767.98px){
	.options br{
		display: block;
	}
}
@media(max-width: 575.98px){
	.videoplay{
		padding-right: 0;
		.vplayer {
    		padding-left: 0px;
			padding-right: 0;
		}
		.vdetails {
			padding: 20px;
			.chapter-title  {
			    font-size: 1.6em;
			}
			.program-title{
				font-size: 1.4em;
				margin-bottom: 10px;
			}
			.options{
				font-size: 1em;
			}
		}
	}
	.pausedetailblur{
		.pvdetails{
			padding: 0;
			.program-title{
				font-size: 2em;
			}
		}
	}
	.hnav-title .datepicker{
		padding-right: 20px;
	}
}


.loadingVideo {
  padding-top: 100px;
}

.loadingVideo .sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.loadingVideo .sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.loadingVideo .sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.loadingVideo .sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.loadingVideo .sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.loadingVideo .sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.loadingVideo .sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.loadingVideo .sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.loadingVideo .sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    } 90%, 100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
      }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    } 90%, 100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
      }
}

.sk-cube-grid {
  width: 100px;
  height: 100px;
  margin: 100px auto;
  .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #fff;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }
  .sk-cube1 {
    background-color: transparent !important;
  }
  .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    border-radius: 50% 50% 0 0;
    background-color: #808080;
  }
  .sk-cube3 {
    background-color: transparent !important;
  }
  .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    border-radius: 50% 0 0 50%;
  }
  .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    background-color: #ccc;
  }
  .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    border-radius: 0 50% 50% 0;
    background-color: #808080;
  }
  .sk-cube7 {
    background-color: transparent !important;
  }
  .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    border-radius: 0 0 50% 50%;
  }
  .sk-cube9 {
    background-color: transparent !important;
  }
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    opacity: 0;
  }

  35% {
    opacity: 1;
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    opacity: 0;
  }

  35% {
    opacity: 1;
  }
}



.vdetails {
  .description.full-text,
  .tags-list.full-text {
    overflow: auto;
    height: 150px;
		display: block;
		margin-bottom: 0;
  }
  .see-more {
    color: #b933ff !important;
    cursor: pointer;
    font-size: 1.2em;
    margin-top: -20px;
    &:hover {
      text-decoration: none !important;
    }
  }

  .see-more-tags {
    color: #b933ff !important;
    cursor: pointer;
    font-size: 1.2em;
    margin-top: -5px;
    // padding-left: 5px;
  }

  .see-more.hide,
  .see-more-tags.hide{
    display: none;
  }

  .tags-list {
    height: 1em;
		overflow: hidden;
  }

  .tags-list.full-tags {
    overflow: auto;
    height: auto;
  }

  .see-more:hover {
    text-decoration: underline;
  }
}


.azuremediaplayer-dimensions {
  padding-bottom: 56.25% !important;
  display: block;
  height: 0;
  overflow: hidden;
}

.vjs-loading-spinner {
	//display: none !important;
	margin-left: -75px !important;
	margin-top: -75px !important;
}
