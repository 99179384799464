
.col-xs-none{display:inline-block !important;}
.col-xs-display{display:none !important;}

@media (max-width: 575.98px){
	// .desktop{display:none;}
	// .mobile{display:block !important;}	
}
@media (min-width: 1660px){
	#search-mmenu .container {
		width: 1370px;
	}
}
@media (min-width: 2200px){
	#search-mmenu .container {
		width: 1780px;
	}
} 
@media (max-width: 767.98px){
	// .mobile{display:none !important;}
	.col-xs-none{display:none !important;}
	.col-xs-display{display:inline-block !important;}
}

.container{
	width: 1600px;
	@media (max-width: 2299.98px){
		width: 74vw;
	}
	@media (max-width: 1599.98px){
		width: 84vw;
	}
	@media (max-width: 1199.98px){
		width: 94vw;
	}
}