.contributions-prayers {
  margin-top: -20px;
  background: rgba(168, 3, 250, 0.6);
  background: -moz-linear-gradient(-45deg, rgba(168,3,250,1) 0%, rgba(36,44,72,1) 40%, rgba(6,14,30,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(168,3,250,1) 0%,rgba(36,44,72,1) 40%,rgba(6,14,30,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(168,3,250,1) 0%,rgba(36,44,72,1) 40%,rgba(6,14,30,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a803fa', endColorstr='#060e1e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  .cp-main:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -150px;
    left: -150px;
    width: 600px;
    height: 600px;
    background-image: url("~/assets/img/isotipo-mundo.webp");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .cp-main {
    overflow: hidden;
    position: relative;

    .cp-panel {
      text-align: center;
    }

    .lateral {
      position: relative;
      padding-top: 100px;
      text-align: center;

      .iconlace {
        font-size: 10em;
      }

      h1 {
        font-family: $black-font;
        text-transform: uppercase;
        letter-spacing: 1pt;
      }

      p {
        display: inline-block;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 36px;
        padding: 1em;
      }
    }

    .cp {
      padding: 100px 40px 0;

      .panel-group .panel {
        background: rgba(6, 14, 30, 0.5);
        margin-top: 20px;
        border: 0;
      }

      .panel-heading {
        padding: 0;
      }

      .panel-title {
        a {
          display: block;
          padding: 10px 15px;

          &:hover {
            text-decoration: none;

            .close {
              opacity: 1;
              @include transition(0.3s);
            }
          }
        }

        .collapsed {
          .close {
            @include transition(0.3s);
            -ms-transform: rotate(45deg); /* IE 9 */
            -webkit-transform: rotate(45deg); /* Safari 3-8 */
            transform: rotate(45deg);
          }
        }

        .iconlace,
        h2 {
          display: inline-block;
          vertical-align: middle;
        }

        .iconlace {
          font-size: 2em;
          margin-right: 5px;

          &.iconlace-phone-call,
          &.iconlace-support {
            font-size: 1.5em;
          }
        }

        h2 {
          font-size: $medp;
          text-transform: uppercase;
          margin: 10px 0;
        }

        .close {
          font-size: 2em;
          padding: 5px;
          color: $blanco;
          text-shadow: none;
        }
      }

      .panel-body {
        padding: 0 15px 20px;

        p.contribution-phone {
          border-bottom: 1px solid $gama-medio;
          text-align: center;
          width: 100%;
          font-size: 2em;
          font-weight: 400 !important;
          padding: 5px;
        }

        p.info {
          font-weight: 300;
          text-transform: initial;
          color: $gama-gris;
        }
      }

      .panel-group .panel-heading + .panel-collapse > .panel-body {
        border-top: none,
      }

      .btn-lg {
        width: 350px;
        max-width: 90%;
        margin: 1em 0;
      }

      #collapse-online {
        .btn-lg {
          margin: 20px 0;
        }
      }
    }
  }
}

@media (max-width:  1199.98px) {
  .contributions-prayers {
    .cp-main {
      .lateral:before {
        width: 500px;
      }
    }
  }
}

@media (max-width:  991.98px) {
  .contributions-prayers {
    .cp-main {
      .cp-panel {
        text-align: left;
      }

      .cp {
        padding: 0;
      }

      .lateral {
        padding-top: 70px;

        p {
          max-width: 500px;
          padding: 0;
          font-size: 16px;
        }
      }

      .lateral:before {
        width: 500px;
      }

      .dark-forms .form-group {
        margin-bottom: 0;

        p {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .contributions-prayers {
    .cp-main {
      .cp-panel {
        text-align: center;
      }

      .lateral {
        p {
          font-size: 14px;
          line-height: 24px;
        }
      }

      .cp {
        .panel-title {
          .iconlace {
            font-size: 1.4em;

            &.iconlace-phone-call,
            &.iconlace-support {
              font-size: 1em;
            }
          }

          h2 {
            font-size: 14px;
          }

          .close {
            font-size: 1.4em;
          }
        }
      }
    }
  }
}

@media(max-width: 575.98px) {
  .contributions-prayers {
    .cp-main {
      .lateral {
        p {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}








.thumbnail-prayer {
  background: rgba(6, 14, 30, 0.8);
  border-color: rgba(75, 88, 129, 0.4);
  color: $blanco;
  height: 280px;
  overflow: hidden;
  @include transition(0.3s);

  .thumb-date {
    float: right;
    width: 60px;
    text-align: center;
    padding: 5px;
    margin: -5px -5px 5px 5px;
    border-radius: 0 3px;
    background: #4b5881;
    font-style: 14px;

    p {
      font-family: $black-font;
      margin: 0;
    }

    .day {
      font-size: 1.4em;
      margin-bottom: -5px;
    }
  }

  .caption {
    color: $blanco;

    p {
      font-size: 14px;
      line-height: 24px;
    }

    .category,
    .name {
      max-width: 85%;
    }

    .category {
      text-transform: uppercase;
    }

    p.prayer-box {
      display: -webkit-box;
      height: 130px;
      height: 16px 1.5em 3;
      line-height: 1.5em;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 6;
      -moz-line-clamp: 6;
      -ms-line-clamp: 6;
      line-clamp: 6;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -ms-box-orient: vertical;
      box-orient: vertical;
    }

    .name {
      font-weight: 500;
    }

    .cp-link {
      font-weight: 800;
      color: #7e8bb3;
      text-transform: uppercase;
      opacity: .7;

      &:hover {
        text-decoration: none;
        opacity: 1;
      }
    }
  }
}
.btn-programacion{
	text-align: right;
	margin: 20px 50px;
}
.thumbnail-prayer:hover{
	background: rgba(6, 14, 30, 0.4);
	border-color: rgba(75, 88, 129, 0.8);
	@include transition(0.3s);
}
.prayers{
	overflow: hidden;
	margin-top: 20px;
	.breadcrumb{
		li:nth-child(2){
			text-transform: uppercase;
		}
	}
	.prayers-head{
		margin: 20px 0;
		h2{
			font-size: $medp;
			text-transform: uppercase;
			margin: 10px 0;
		}
		.prayers-filter{
			float: right !important;
			margin-top: -5px;
		}
	}
	.prayers-list{
		margin: 0 -15px;
	}
}
.dark-forms {
  .email_list {
    padding-top: 5px;
    padding-bottom: 5px;
    column-count: 1;
    font-size: 11px;
    line-height: 13px;

    button {
      background: none;
      padding: 4px;
    }
  }

  .form-control {
    button {
      padding: 5px;
    }
  }

  .add_email {
    width: 100%;
    display: block;

    i {
      color: #fff;
      opacity: 0.8;
      transform: rotate(45deg);

      &:hover {
        opacity: 1;
        color: #fff;
      }
    }

    button {
      padding: 10px;
      border-radius: 5px;
      background: none;
      position: absolute;
      right: 5px;
      /* margin-left: -46px;*/
      margin-top: 9px;
      color: blue;
      float: right;
    }

    input {
      width: 80%;
      background: none;
      border: none;
      margin-top: 0 !important;
      line-height: 103%;
      outline: none !important;
    }

    .email-input:focus {
      //box-shadow: inset 0 1px 0 rgba(0,0,0,.075), 0 0 0 rgba(102, 175, 233, .6);
      /*-webkit-box-shadow: 0 8px 0 0 rgba(102, 175, 233, .6);
      box-shadow: 0 8px 0 0 rgba(102, 175, 233, .6);*/
    }
  }

  .email_list {
    padding-top: 5px;
    padding-bottom: 5px;
    column-count: 1;
    font-size: 11px;
    line-height: 13px;
    /*-webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    display: table-cell;*/
    i {
      color: #fff;
    }

    li {
      float: left;
      border: 1px solid #ffffff2b;
      border-radius: 60px;
      padding-left: 13px;
      margin: 2px 2px;
    }

    button {
      background: none;
      padding: 4px;
    }
  }
}
.modal-prayer {
  .modal-dialog {
    /*margin-top: 12vh;*/
    min-width: 60%;

    .modal-content {
      padding: 0 !important;
      background: $gama-oscuro !important;

      .modal-header {
        position: absolute;
        right: 20px;
        top: 14px;
        padding: 0;
        z-index: 1;
      }

      .modal-body {
        padding: 0;
        height: 100%;

        .thumbnail-prayer {
          margin: 0;
          width: 100%;
          height: auto;
          max-height: 80vh;
          background: transparent;
          border: 0;
          padding: 0;

          .thumb-date {
            float: left;
            border-radius: 3px 0;
            margin: 0;
          }

          p.prayer-box {
            display: -webkit-box;
            height: 130px;
            line-height: 1.5em;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 6;
            -moz-line-clamp: 6;
            -ms-line-clamp: 6;
            line-clamp: 6;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -ms-box-orient: vertical;
            box-orient: vertical;
          }

          .caption {
            padding: 30px 20px 30px 80px;

            p {
              margin-bottom: 20px;
            }

            .category {
              font-size: 22px;
              font-weight: bold;
            }

            .txt-group {
              overflow-y: auto;
              max-height: 48vh;
              margin-bottom: 30px;
              padding-right: 40px;

              .txt {
                line-height: 28px;
              }
            }

            p.prayer-box {
              display: -webkit-box;
              height: 130px;
              line-height: 1.5em;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 6;
              -moz-line-clamp: 6;
              -ms-line-clamp: 6;
              line-clamp: 6;
              -webkit-box-orient: vertical;
              -moz-box-orient: vertical;
              -ms-box-orient: vertical;
              box-orient: vertical;
            }

            .txt-group::-webkit-scrollbar {
              width: 10px;
            }

            .txt-group::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
              -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
              border-radius: 20px;
            }

            .txt-group::-webkit-scrollbar-thumb {
              background-color: $gama-medio;
              outline: none;
              border-radius: 20px;
            }
            /*mozilla scrolbalken*/
            scrollbar {
              -moz-appearance: none !important;
              background: rgb(0,255,0) !important;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px){
	.email_list{
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
	}
}
@media (min-width: 1200px){
	.prayers .breadcrumb{
		margin-left: 0;
	}
}

.italic {
  font-style: italic;
}
