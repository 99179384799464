
#langselection{
	position: relative;
	a.dropdown-toggle{
		display: block;
		position: relative;
		height: 53px;
		text-decoration: none;
		background: transparent;
	}
	a.dropdown-toggle:hover,
	a.dropdown-toggle:focus {
		background: transparent;
		text-decoration: none;
	}
}
select#countries{
	background:none;
	border:none;
	-webkit-appearance:none;
	width:60px;
}
#countries_msdd{
	outline:none;
	text-align: center;
	padding: 0;
	@media(max-width:  991.98px) {
		width: 50px !important;
	}
	// width: 100% !important;
	// height: 100%;

	img{
		width:20px;
		height:20px;
		border-radius: 50%;
		margin-right: 10px;
		border: 1px solid #fff;
	}
	.ddlabel{
		vertical-align: middle;
	}
	
	div#countries_child {
		background: $gama-oscuro;
		width: 280px;
		text-align: left;
		margin-left: -220px;
		padding: 20px;
		padding-top: 0;	
		line-height: 45px;
		overflow-y: scroll;
		border-radius: 3px;	
		margin-top: 0px;
		@include box-shadow(0 5px 5px 0px rgba(0,0,0,0.5));
		li:first-child{
			border-bottom: 1px solid $gama-medio; 
		}
	}
	.ddTitle{
		border-radius: 3px 3px 0 0;
		margin-top: -1px;
		@include transition(0.3s);
	}
	
	.ddTitleText {
		img{
			margin-right:0;	
		}
		
		span.ddlabel {
			font-size: 0px;
		}
	}
}
#countries_msdd.borderRadiusTp .ddTitle{
	@include transition(0.3s);
	background:$gama-oscuro;
}
#countries_msddHolder{
	@include opacity(0);
}
#countries_child::-webkit-scrollbar {
    width: 8px;
}
 
#countries_child::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
#countries_child::-webkit-scrollbar-thumb {
  background-color: $gama-medio;
  border-radius: 5px;
  outline: 1px solid $gama-medio;
}
@media screen and (max-width: 767.98px) {
	#langselection {
		a {
			height: auto;
			text-decoration: none;
			background: transparent;
		}
	}
}