#notification {
	overflow: hidden;
	.error{
		max-width: 400px;
		margin: 25vh auto ;
		padding: 0;
		img{
			max-width: 140px;
		}
		h2{
			text-transform: uppercase;
		}
		p{
			margin: 20px;
		}
	}
}
@media (max-width: 767.98px){
	#notification{
		.error{
			margin: 15vh auto;
			padding: 0;
			img{
				max-width: 80px;
			}
			h2{
				font-size: 20px;
			}
		}
	}
}