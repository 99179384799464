/* Definition for tira de porgramas deslizante */
$progressbar:#a700ff;
$progressbarbg:#2d354e;

// Sizes for thumnails
$hnamob:44vw;
$hnalmob:33.3333333vw;
$hnatab:25vw;
$hnadesk:20vw;
$hnahd:16.6666666vw;

@mixin ellipse($clamp, $orient) {
	-webkit-line-clamp: $clamp;
	   -moz-line-clamp: $clamp;
			-ms-line-clamp: $clamp;
	-webkit-box-orient: $orient;
		 -moz-box-orient: $orient;
			-ms-box-orient: $orient;
}

// posible bg thumbs background: rgba(255, 255, 255, 0.21);
// MAINTAIN ASPECT RATIO 16:9
@mixin aspect-ratio($width, $height) {
		position: relative;
		&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: calc(($height / $width) * 100%);
	}
	> .thumb_content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background:red;
	}
}
@-webkit-keyframes animateOpacity {
	0% {
		opacity:0;
	}
	60% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}
.sixteen-nine {
	max-width: 363px;
	@include aspect-ratio(16, 9);
}
@mixin transition($speed) {
	transition:all ease-in-out $speed;
	-moz-transition:all ease-in-out $speed;
	-webkit-transition:all ease-in-out $speed;
	-ms-transition:all ease-in-out $speed;
}

// THUMBS
.hnav {
	position:relative;
	clear: both;
	.lSAction {
		height: 100%;
		left: 0;
		position: initial;
	}
	.lSAction > a {
		background:none;
	}
	.lSNext:before, .lSPrev:before {
		content:'';
		position:absolute;
		z-index: 9;
		display: block;
		top: 50%;
		width: 29px;
		height: 27px;
		cursor: pointer;
		z-index: 99;
		margin-top: -16px;
		opacity: 0.7;
		-webkit-transition: opacity 0.35s linear 0s;
		transition: opacity 0.35s linear 0s;
	}
	.lSNext:before {
		background-image: url("/assets/img/icons/controls.svg");
		background-position: 30px;
		margin-right: 10px;
	}
	.lSPrev:before {
		background-image: url("/assets/img/icons/controls.svg");
		margin-left: 10px;
	}
	.lSAction a {
		background-color: transparent;
		background-repeat:no-repeat;
		height: 100%;
		width: 40px;
		margin: 0;
		position: absolute;
		top: 0;
		opacity:0;
		@include transition-delay(0.2s);
		right:0;
	}
	.lSAction > .lSNext {
		right: 0px;
		background-position:-26px 50%;
	}
	.lSAction > .lSPrev {
		left: 0px;
		background-position:12px 50%;
	}

	.lSPrev:after, .lSNext:after{
		content: "";
		display: block;
		width: 60px;
		height: 100%;
		min-height: 200px;
		opacity: 1;
	}
	.lSPrev:after{
		background: -moz-linear-gradient(left, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
	}
	.lSNext:after{
		background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
		background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
		background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 );
	}
	.lSNext:hover:before	,
	.lSPrev:hover:before{
		@include transition(0.2s);
		opacity: 1;
	}

	&:hover{
		.lSAction a {
			opacity: 1;
		}
	}
}
a.hna-item {
	position: relative;
	display: block;
	cursor: pointer;
	height: 0;
	padding-bottom: 56.25%;
	overflow: hidden;
	@include transition(0.3s);
	@include box-shadow(0 2px 10px #000);
	img{
		width:100%;
	}
	.thumb-info, .play-th, .thumbstext, .readmore, .progress-thumb{z-index:99;}
	.thumb-info{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		opacity:0;
		transition-delay: 1s;
		transition: all ease-in-out 0.3s;
		width:100%;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 5-7 */ filter: alpha(opacity=0);
		&:after{
			background: -moz-linear-gradient(top, rgba(13,13,13,0) 0%, rgba(13,13,13,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(13,13,13,0) 0%,rgba(13,13,13,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(13,13,13,0) 0%,rgba(13,13,13,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d0d0d', endColorstr='#0d0d0d',GradientType=0 ); /* IE6-9 */
			content: '';
			width: 100%;
			position: absolute;
			bottom:0;
			height: 90px;
		}
	 }
	.play-th {
		position: absolute;
		text-align: center;
		top: 20%;
		left: 0;
		right: 0;
		margin: auto;
		@include transition(0.3s);
		opacity: 0.7;
		width: 60px;
		img{
			opacity:1 !important;
		}
		&:hover{
			opacity: 1;
			@include transition(0.3s);
			@include transform-scale(1.1);
		}
	}
	.thumbstext {
		position: absolute;
		width: 100%;
		display: block;
    	bottom: 0;
		padding: 0 50px 14px 10px;
		color:#dadada;
		text-transform: initial;
		z-index: 99;

		.thumb-title {
			font-size:$thumbtit;
			font-family: $base-font-family;
			font-weight: bold;
			overflow: hidden;
			height: 20px;
			width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.thumb-date {
			font-size:$thumbsub;
			text-transform: capitalize;
		}
	}
	.progress-thumb {
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 6px;
		background:$progressbarbg;
	}
	.progress-bar{
		background:$progressbar;
	}
}

#favorites-mmenu a.hna-item {
  height: initial;
  padding-bottom: 0px;
}

.homevertical a.hna-item{
	padding-bottom: 198.74%;
	.play-th {
		top: 35%;
	}
}
.programs-slide {
	li{
		margin-right: 10px;
	}
}
.programs-slide {
  @include transition(0.3s);
  height: auto !important;
  padding-top: 20px;
  padding-bottom: 30px !important;
  min-width: 100%;

  &.flex {
    display: flex;
  }

  &.homevertical {

    li {
      a.hna-item {
        .play-th {
          width: 80px;

          img {
            width: 80px;
          }
        }
      }

      &:hover {
        /* IE 8 */ -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; /* IE 5-7 */
        filter: alpha(opacity=100);

        a.hna-item {
          .play-th {
            img {
              width: 80px;
            }
          }
        }
      }
    }
  }

  &.flexie {
    display: flex;
    @include transition(0.3s);
  }

  &:hover {
    //		display:flex;
    @include transition(0.3s);
  }

  li {
    position: relative;
    @include transition(0.3s);


    &.open-thumb {
      @include transition(0.3s);
      width: 25vw;
    }

    &:hover {
      @include transition-delay(0.6s);

      .thumb-info {
        opacity: 1;
        @include transition(0.3s);
        @include transition-delay(0.6s);
      }

      .hna-item {
        @include transition(0.3s);
        @include transition-delay(0.6s);
        margin-top: 0px;
        z-index: 999;

        img {
          opacity: 1.0;
          @include transition(0.3s);
          @include transition-delay(0.6s);
        }
      }

      .readmore {
        opacity: 1;
        @include transition-delay(0.6s);
        cursor: pointer;
      }
    }

    .readmore {
      opacity: 0;
      position: absolute;
      bottom: 10px;
      right: 10px;
      @include transition(0.3s);
      z-index: 9999;

      &:hover {
        @include transition(0.3s);
        @include transform-scale(1.1);
      }
    }

    .readmore .iconlace-open-arrow,
    .readmore .iconlace-close-arrow {
      font-size: 16px;
    }
  }
}

$programsTitle:20px;

.hnav h3.b-title {
	font-size:$programsTitle;
	text-transform: uppercase;
	margin: 1em 1em 0;
	z-index: -1;
	margin-left: 20px;
	@media screen and (min-width: 1200px) {
		margin-left: 70px;
	}
	.isotipo{
		display: none;
	}
}
.lSSlideWrapper {
    padding-top: 0px;
}

/* DETAIL PROGRAMA HOME */

.prev-detail {
    border-bottom: 3px solid rgba(167, 0, 255, 0.76);
    margin-bottom: 40px;
	// position:absolute;
	position: relative;
	z-index: 1;
	width:100%;
	height:100%;
	overflow: hidden;
	@media screen and (min-width: 1200px) {
		position: absolute;
	}
	.chapter-title{
		font-size: $bigh3;
		// text-transform: capitalize;
	}
	.description{
		display: block;
		display: -webkit-box;
		height: auto;
		padding-top: 10px;
		line-height: 1.5em;
		font-size: $detp;
		overflow: hidden;
		text-overflow: ellipsis;
		@include ellipse(4, vertical);
	}

	.detactions {
		font-size: 9px;
		font-weight: 300;
		color: #bd44ff;

		i{
			margin: 0 5px;
		}
	}
	.text-detail {
		padding-left: 70px;
		padding-top: 10vh;
		text-shadow: 0 1px 10px $gama-negro;
    .voddate {
	  text-transform: capitalize;
	  font-weight: 100;
	  color: gray;
    }
	}

	.playbtn_mobile{
		padding: 0;
		padding-top: 0.5em;
		.btn{
			width: 100%;
			max-width: 189.55px;
		}
	}

	.btnside{
		position: relative;
		height: 100%;
		// Check overlapping layout
		@media (max-width:  991.98px) {
			display: none;
		}

		.playbtn {
			display: block;
			width: 100%;
			height: 100%;
			margin: 0;
			position: relative;
			float: right;
			a{
				display: block;
				img{
					position: absolute;
					right: 30%;
					top: 30%;
					width: 140px;
					margin-top: -10px;
					margin-left: -30px;
					opacity:0.4;
					@include transition(0.3s);

					&:hover{
						opacity:1;
						@include transition(0.2s);
						@include transform-scale(1.2);
					}
				}
			}
		}
	}
	.close_detail {
    	position: absolute;
    	right: 20px;
    	top: 20px;
		z-index: 9;
		@include transition(0.2s);
		cursor:pointer;

		img{width:45px;}

		&:hover{
			@include transition(0.2s);
			@include transform-scale(1.2);
		}
	}
}
.prev-detail-wrap{
	&.hidedetail{
		max-height: 0 !important;
    	opacity: 0 !important;
		margin: 0px;
		padding: 0;
		overflow: hidden;
		@include transition(0.5s);
		.prev-detail {margin-bottom:0 !important;}
		.detailbg{
			background-position: right top;
			@include transition(0.2s);
		}
	}

	@include box-shadow(0 4px 40px rgba(0,0,0,.5));
	@include transition(0.4s);
	opacity:1;
	z-index: 0;
	margin-top: 0;
	background: $gama-negro;
    margin-bottom: 20px;
    max-height: 65vh;
    height: 500px;
    position: relative;

	.detailbg{
		background-repeat: no-repeat;
		background-size: cover;
		background-position: right top;
		width: 100%;
		height: 100%;
		position: relative;
		@media screen and (min-width: 992px) {
			position: absolute;
		}
		&#testb{
		}
	}

	.gradient{
		content:'';
		width:100%;
		height:100%;
		background: -moz-linear-gradient(left, $gama-negro 0%, rgba(13,13,13,0) 53%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, $gama-negro 0%,rgba(13,13,13,0) 53%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, $gama-negro 0%,rgba(13,13,13,0) 53%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gama-negro', endColorstr='#000d0d0d',GradientType=1 ); /* IE6-9 */
		position: absolute;
		left: 0;
		top: 0;
	}
	.lSAction{
		width: 100%;

	}
}

@media (min-width: 1660px){
	.lSAction {
		top: 10px;
	}
	.programs-slide li{
		@include transition-delay(0.2s);
		@include transition(0.3s);

		&:hover{
			@include transition-delay(0.6s);
		}

		&.active{

		}
		a.hna-item .play-th img{width:50px;}
		.readmore{bottom: 30px;}
	}

	.homevertical {
		@include transition(0.3s);
		a{
			&.hna-item{
				@include transition(0.3s);

				.play-th{
					width:90px;

					img{
						width:100%;
					}
				}
			}
		}

		li{
			.play-th{
				img{
					width:100% !important;
				}
			}

			&:hover{
			@include transition-delay(0.6s);
			-ms-transform-origin: 50% 50%; /* IE 9 */
			-webkit-transform-origin: 50% 50%; /* Chrome, Safari, Opera */
			margin-top: 0px;

			.hna-item{
				margin-top: 0px;
			}

			.play-th {
					img{
						width:100% !important;
					}
				}
			}
		}
	}

	a.hna-item .play-th {
		top:30%;
	}

	.homevertical a.hna-item .play-th {
		top: 40%;
	}

}

/*@media (min-width:1550px){
	a.hna-item .play-th {
		top:40px;
	}
	.programs-slide li:hover .play-th {
		top:45px;
	}
	.homevertical a.hna-item .play-th {
		top: 270px;
	}
	.programs-slide.homevertical li:hover .play-th {
		top:275px;
	}
}*/

@media (max-width:  1499.98px){

	.prev-detail-wrap{
		margin-top: 0px;
		.detailbg{
			background-size: auto 100%;
		}
	}
	.programs-slide li{

		&:hover{
			z-index: 10;
			.hna-item{
				img{opacity: 0.9;}
			}
		}
		a.hna-item .play-th{
			width: 40px;
		}
	}
	/*.programs-slide.homevertical{
		li{
			&:hover{
				.hna-item{
					margin-top:0px;
				}
			}
		}
	}*/
}

@media (min-width: 1200px){
	.hnav{
		h3.b-title{
			margin-left: 70px;
		}
		.lSSlideWrapper {
			// overflow-x: hidden;
			margin-left: 70px;
		}
		.lSAction > .lSPrev{
			left: -70px;
		}
	}
}
@media (max-width:  991.98px){
	.site-wrapper-inner{
    	padding-top: 5px;
	}
	.programs-slide{
		li{

			@include transition-delay(0.6s);

			&:hover{
				@include transition-delay(0.6s);
				.hna-item{
					@include transition-delay(0.6s);
					@include transition(0.3s);
					-ms-transform-origin: 50% 50%; /* IE 9 */
					-webkit-transform-origin: 50% 50%; /* Chrome, Safari, Opera */
					transform-origin: 50% 50%;
				}
			}
		}
	}
	.prev-detail-wrap{
		#testb, .detailbg {
			background-size: cover;
			background-position: left;
		}
	}
	.prev-detail {
		.text-detail {
			padding-left: 50px;
			padding-top: 3vh;
			.chapter-title{
				width: 90%;
			}
		}
	}
}
@media (max-width:  991.98px){
	.prev-detail {
		.text-detail {
			padding-left: 30px;
		}
		.btnside {
			.playbtn a {
				img {
					left: 30px;
					top: 1em;
					width: 90px;
				}
			}
		}
	}
}
@media (max-width: 767.98px){
	.hnav {
		min-height: 0;
		h3.b-title{
			left: 20px;
		}
	}
	.prev-detail{
		.text-detail{
			padding-left: 20px;
			padding-top: 70px;
		}
	}
	.programs-slide li{

		&:hover{
			@include transition-delay(0.6s);
			@include transition(0.3s);
		}
	}
}
@media (max-width: 767.98px){
	.prev-detail-wrap{
		max-height: 100%;
		height: 100%;
		// position: fixed;
		position: relative;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 999;

		&:not(.hidedetail){

			.detailbg{

				opacity:1;
				@include transition(0.3s);
				@include transition-delay(1s);
				}
		}
		&.hidedetail{
			opacity:0;
			@include transition(0.3s);
			@include transition-delay(0.3s);
		}
	}
	.prev-detail{
		.text-detail{
			padding-left: 20px;
			padding-top: 0;
			@media screen and (min-width: 1200px) {
				padding-top: 70px;
			}
		}
		.close_detail{
			right: 12px;
			top: 60px;
		}
	}
}
@media (max-width: 575.98px){
	body{
		background-size: 450%;
	}
	.hnav h3.b-title {
		font-size: 1.1em;
		z-index: 10;
		// margin-left: 20px;
	}
	.programs-slide li{
		&:hover{
			.thumb-info, .readmore{opacity:0;}
			.hna-item{margin-top:0;}
		}
		.readmore{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
	.prev-detail-wrap .detailbg {
		background-size: cover;
		background-position: center;
		width: 100vw;
		height: 100vh;
		@media screen and (max-width:  991.98px) {
			background-size: contain;
			height: 300px;
			margin-top: 50px;
		}
	}
	.playbtn_mobile{
		text-align: center;
	}
	.lSAction{
		display: none !important;
	}
	.option-list .option{
		margin-right: 10px;
	}
}

@media (min-width: 1660px){
	.hnav .programs-slide li{
		//min-width:$hnahd !important;
		//max-width:$hnahd !important;
	}
}
@media (max-width:  1499.98px){
	.hnav .programs-slide li{
    //min-width:$hnadesk !important;
    //max-width:$hnadesk !important;
	}
}
@media (max-width:  991.98px){
	.hnav .programs-slide li{
		//min-width:$hnatab !important;
		//max-width:$hnatab !important;
	}
}

@media (max-width: 767.98px){
	.hnav .programs-slide li{
		//min-width:$hnalmob !important;
		//max-width:$hnalmob !important;
	}
}

@media (max-width: 575.98px){
	.hnav .programs-slide li{
		//min-width:$hnamob !important;
		//max-width:$hnamob !important;
	}
}




