@font-face {
  font-family: 'iconlace';
  src: font('~/assets/fonts/iconlace.eot');
  src: font('~/assets/fonts/iconlace.eot') format('embedded-opentype'),
    font('~/assets/fonts/iconlace.ttf') format('truetype'),
    font('~/assets/fonts/iconlace.woff') format('woff'),
    font('~/assets/fonts/iconlace.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.iconlace {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconlace' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.color-icon{
    color: #FFFFFF !important;
  }
}

%icon-article-before:before {
  content: "\e9a7";
}

.iconlace-podcast:before {
  content: "\e9a8";
}

.iconlace-video:before {
  content: "\e927";
}

.iconlace-program:before {
  content: "\e967";
}

.iconlace-article:before {
  content: "\e9a7";
}

.iconlace-transactions:before {
  content: "\e97f";
}

.iconlace-info-circle-solid:before {
  content: "\e9aa";
}

.iconlace-info-circle:before {
  content: "\e9ab";
}

.iconlace-podcast-solid:before {
  content: "\e9ac";
}

.iconlace-pause-status:before {
  content: "\e9a6";
}

.iconlace-less-mini:before {
  content: "\e97c";
}

.iconlace-check:before {
  content: "\e987";
}

.iconlace-cross-status:before {
  content: "\e9a4";
}

.iconlace-circle-status:before {
  content: "\e9a0";
}

.iconlace-return-status:before {
  content: "\e9a1";
}

.iconlace-stop-status:before {
  content: "\e9a2";
}

.iconlace-font-size:before {
  content: "\e9a3";
}

.iconlace-pause-circle-solid:before {
  content: "\e99e";
}

.iconlace-pause-circle:before {
  content: "\e99f";
}

.iconlace-callworld:before {
  content: "\e989";
}

.iconlace-card:before {
  content: "\e988";
}

.iconlace-users:before {
  content: "\e925";
}

.iconlace-user:before {
  content: "\e93d";
}

.iconlace-check-status:before {
  content: "\e9a5";
}

.iconlace-filter-mixer:before {
  content: "\e986";
}

.iconlace-trespuntos:before {
  content: "\e983";
}

.iconlace-amen:before {
  content: "\e984";
}

.iconlace-creo:before {
  content: "\e985";
}

.iconlace-gloria:before {
  content: "\e900";
}

.iconlace-testimonio:before {
  content: "\e901";
}

.iconlace-transcription:before {
  content: "\e970";
}

.iconlace-share:before {
  content: "\e96d";
}

.iconlace-plus-mini:before {
  content: "\e96a";
}

.iconlace-back:before {
  content: "\e968";
}

.iconlace-share-solid:before {
  content: "\e912";
}

.iconlace-bubble-cc:before {
  content: "\e921";
}

.iconlace-list-solid:before {
  content: "\e90c";
}

.iconlace-camera:before {
  content: "\e945";
}

.iconlace-exit:before {
  content: "\e924";
}

.iconlace-map-marker:before {
  content: "\e938";
}

.iconlace-pray-right:before {
  content: "\e973";
}

.iconlace-pray:before {
  content: "\e971";
}

.iconlace-pray-left:before {
  content: "\e972";
}

.iconlace-play-circle:before {
  content: "\e902";
}

.iconlace-notification-solid:before {
  content: "\e903";
}

.iconlace-settings-solid:before {
  content: "\e904";
}

.iconlace-cross-circle-solid:before {
  content: "\e905";
}

.iconlace-donate:before {
  content: "\e906";
}

.iconlace-heart-solid:before {
  content: "\e90d";
}

.iconlace-map-marker-solid:before {
  content: "\e910";
}

.iconlace-phone-solid:before {
  content: "\e911";
}

.iconlace-play:before {
  content: "\e913";
}

.iconlace-play-circle-solid:before {
  content: "\e914";
}

.iconlace-plus-circle-solid:before {
  content: "\e915";
}

.iconlace-tag-solid:before {
  content: "\e917";
}

.iconlace-warning-solid:before {
  content: "\e919";
}

.iconlace-notification:before {
  content: "\e91a";
}

.iconlace-calendar:before {
  content: "\e91b";
}

.iconlace-down-circle:before {
  content: "\e91c";
}

.iconlace-chevron-down:before {
  content: "\e91d";
}

.iconlace-chevron-previous:before {
  content: "\e91e";
}

.iconlace-chevron-next:before {
  content: "\e91f";
}

.iconlace-chevron-up-circle:before {
  content: "\e920";
}

.iconlace-chevron-up:before {
  content: "\e922";
}

.iconlace-settings2:before {
  content: "\e923";
}

.iconlace-cross-circle:before {
  content: "\e926";
}

.iconlace-cross:before {
  content: "\e928";
}

.iconlace-coment:before {
  content: "\e929";
}

.iconlace-enter:before {
  content: "\e92a";
}

.iconlace-mail:before {
  content: "\e92b";
}

.iconlace-cc:before {
  content: "\e92d";
}

.iconlace-less:before {
  content: "\e92e";
}

.iconlace-plus:before {
  content: "\e931";
}

.iconlace-search:before {
  content: "\e933";
}

.iconlace-menu:before {
  content: "\e935";
}

.iconlace-music:before {
  content: "\e937";
}

.iconlace-heart:before {
  content: "\e939";
}

.iconlace-list:before {
  content: "\e93a";
}

.iconlace-phone:before {
  content: "\e93b";
}

.iconlace-tag:before {
  content: "\e93c";
}

.iconlace-undo:before {
  content: "\e93f";
}

.iconlace-warning2:before {
  content: "\e940";
}

.iconlace-arrow-down-circle:before {
  content: "\e942";
}

.iconlace-arrow-down:before {
  content: "\e943";
}

.iconlace-arrow-left-circle:before {
  content: "\e944";
}

.iconlace-arrow-left:before {
  content: "\e946";
}

.iconlace-arrow-right-circle:before {
  content: "\e947";
}

.iconlace-arrow-right:before {
  content: "\e948";
}

.iconlace-arrow-up-circle:before {
  content: "\e949";
}

.iconlace-arrow-up:before {
  content: "\e94a";
}

.iconlace-book:before {
  content: "\e94b";
}

.iconlace-bookmark:before {
  content: "\e94c";
}

.iconlace-bubble:before {
  content: "\e94d";
}

.iconlace-bullhorn:before {
  content: "\e94e";
}

.iconlace-checkmark-circle:before {
  content: "\e950";
}

.iconlace-chevron-left-circle:before {
  content: "\e951";
}

.iconlace-chevron-right-circle:before {
  content: "\e952";
}

.iconlace-minus-circle:before {
  content: "\e953";
}

.iconlace-clock:before {
  content: "\e954";
}

.iconlace-download:before {
  content: "\e955";
}

.iconlace-eye:before {
  content: "\e958";
}

.iconlace-layers:before {
  content: "\e95a";
}

.iconlace-link:before {
  content: "\e95b";
}

.iconlace-paperclip:before {
  content: "\e95d";
}

.iconlace-pencil:before {
  content: "\e95e";
}

.iconlace-picture:before {
  content: "\e95f";
}

.iconlace-plus-circle:before {
  content: "\e960";
}

.iconlace-redo:before {
  content: "\e963";
}

.iconlace-sort-alpha-asc:before {
  content: "\e965";
}

.iconlace-sync:before {
  content: "\e96b";
}

.iconlace-trash:before {
  content: "\e974";
}

.iconlace-alert-radius:before {
  content: "\e975";
}

.iconlace-upload:before {
  content: "\e976";
}

.iconlace-sort-alpha-asc-revert-2:before {
  content: "\e978";
}

.iconlace-apartment:before {
  content: "\e97d";
}

.iconlace-history:before {
  content: "\e97e";
}

.iconlace-sort-alpha-asc-revert:before {
  content: "\e980";
}

.iconlace-history-revert:before {
  content: "\e981";
}

.iconlace-transcription1:before {
  content: "\e982";
}

.iconlace-google-plus:before {
  content: "\ea8b";
}

.iconlace-facebook:before {
  content: "\ea90";
}

.iconlace-instagram:before {
  content: "\ea92";
}

.iconlace-twitter:before {
  content: "\ea96";
}

.iconlace-youtube:before {
  content: "\ea9d";
}

.iconlace-send-solid:before {
  content: "\e90f";
}

.iconlace-send:before {
  content: "\e916";
}

.iconlace-narrated-bible:before {
  content: "\e997";
}

.iconlace-player-next-15:before {
  content: "\e998";
}

.iconlace-player-back-15:before {
  content: "\e99b";
}

.iconlace-ministerio:before {
  content: "\e98a";
}

.iconlace-ranking-3:before {
  content: "\e98b";
}

.iconlace-ranking-2:before {
  content: "\e98c";
}

.iconlace-player-pause:before {
  content: "\e98d";
}

.iconlace-player-play:before {
  content: "\e98e";
}

.iconlace-player-back:before {
  content: "\e98f";
}

.iconlace-player-forward:before {
  content: "\e990";
}

.iconlace-player-next:before {
  content: "\e991";
}

.iconlace-player-repeat:before {
  content: "\e992";
}

.iconlace-player-rewind:before {
  content: "\e993";
}

.iconlace-player-shuffle:before {
  content: "\e994";
}

.iconlace-sound-muted:before {
  content: "\e995";
}

.iconlace-sound-low:before {
  content: "\e996";
}

.iconlace-sound-full:before {
  content: "\e999";
}

.iconlace-ranking-1:before {
  content: "\e99a";
}

.iconlace-minister:before {
  content: "\e9bd";
}

.iconlace-catalog:before {
  content: "\e9bc";
}

.iconlace-config:before {
  content: "\e9bb";
}

.iconlace-succes:before {
  content: "\e9ba";
}

.iconlace-live:before {
  content: "\e9b9";
}

.iconlace-sort-by:before {
  content: "\e9b7";
}

.iconlace-alert:before {
  content: "\e9b6";
}

.iconlace-actions-search:before {
  content: "\e9b5";
}

.iconlace-actions-remove:before {
  content: "\e9b4";
}

.iconlace-actions-images:before {
  content: "\e9b3";
}

.iconlace-actions-edit:before {
  content: "\e9b2";
}

.iconlace-actions-delete:before {
  content: "\e9b1";
}

.iconlace-actions-add:before {
  content: "\e9b0";
}

.iconlace-action-drag:before {
  content: "\e9af";
}

.iconlace-ebook:before {
  content: "\e9ae";
}

.iconlace-audiobook:before {
  content: "\e9ad";
}

.iconlace-home:before {
  content: "\e907";
}

.iconlace-hero:before {
  content: "\e9be";
}

.iconlace-person:before {
  content: "\e9bf";
}

.iconlace-pending:before {
  content: "\e9c0";
}

.iconlace-sort-by-asc:before {
  content: "\e9c1";
}

.iconlace-sort-by-des:before {
  content: "\e9c2";
}

.iconlace-eye-solid:before {
  content: "\e908";
}

.iconlace-ministries:before {
  content: "\e90e";
}

.iconlace-bullhorn-solid:before {
  content: "\e90a";
}

.iconlace-live-play:before {
  content: "\e90b";
}

.iconlace-bookshop:before {
  content: "\e909";
}

.iconlace-people:before {
  content: "\e9c3";
}

.iconlace-marker-solid:before {
  content: "\e9c4";
}

.iconlace-marker:before {
  content: "\e9c5";
}

.iconlace-topic:before {
  content: "\e918";
}

.iconlace-outside:before {
  content: "\e92c";
}

.iconlace-plans:before {
  content: "\e92f";
}

.iconlace-first-solid:before {
  content: "\e934";
}

.iconlace-last-solid:before {
  content: "\e936";
}

.iconlace-next-solid:before {
  content: "\e93e";
}

.iconlace-prev-solid:before {
  content: "\e941";
}

.iconlace-prayer-group:before {
  content: "\e94f";
}

.iconlace-lock-solid:before {
  content: "\e932";
}

.iconlace-circle:before {
  content: "\e930";
}

.iconlace-logout:before {
  content: "\e956";
}

.iconlace-bible:before {
  content: "\e957";
}

.iconlace-worldwide:before {
  content: "\e959";
}

.iconlace-equel:before {
  content: "\e961";
}

.iconlace-unequal:before {
  content: "\e95c";
}

.iconlace-map-markers-solid:before {
  content: "\e962";
}

.iconlace-shopping-car:before {
  content: "\e964";
}

.iconlace-target:before {
  content: "\e966";
}

.iconlace-error:before {
  content: "\e969";
}

.iconlace-paloma:before {
  content: "\e96c";
}

.iconlace-Mi-contenido:before {
  content: "\e96e";
}

.iconlace-Icono-Enlace:before {
  content: "\e96f";
}

.iconlace-Icono-alerta:before {
  content: "\e975";
  color: #ff6f05;
}

.iconlace-borrar-filtros:before {
  content: "\e977";
}

.iconlace-eye-on:before {
  content: "\e979";
}

.iconlace-eye-off:before {
  content: "\e97a";
}

.iconlace-Contacto:before {
  content: "\e97b";
}

.iconlace-status-approved:before {
  content: "\e99c";
}

.iconlace-status-rejected:before {
  content: "\e99d";
}

.iconlace-status-inactivated:before {
  content: "\e9a9";
}

.iconlace-status-unique-returned:before {
  content: "\e9c7";
}

.iconlace-status-reclaimed:before {
  content: "\e9c8";
}

.iconlace-status-rejected-returned:before {
  content: "\e9c9";
}

.iconlace-status-recurrent-canceled:before {
  content: "\e9b8";
}

.iconlace-status-request-cancellation:before {
  content: "\e9c6";
}

.iconlace-Sad:before {
  content: "\e9ca";
}

.iconlace-Digital-missionary:before {
  content: "\e9cb";
}

.iconlace-sleep-mode:before {
  content: "\e9d0";
}

.iconlace-resources:before {
  content: "\e958";
}

.iconlace-comunity:before {
  content: "\e9ce";
}

.iconlace-whatsapp:before {
  content: "\e9cf";
}

.iconlace-watch-lineal:before {
  content: "\e972";
}

.iconlace-listen-lineal:before {
  content: "\e973";
}

.iconlace-read-lineal:before {
  content: "\e9d1";
}

.iconlace-pdf-lineal:before {
  content: "\e9d2";
}

.iconlace-check-circle-solid:before {
  content: "\e9d3";
}

.iconlace-new-player-back:before {
  content: "\e9d8";
}

.iconlace-new-player-next:before {
  content: "\e9d9";
}

.iconlace-connectdevice:before {
  content: "\e9df";
}

.iconlace-pip:before {
  content: "\e9de";
}

.iconlace-fullscreen:before {
  content: "\e9da";
}

.iconlace-quality:before {
  content: "\e9dd";
}

.iconlace-subtitles:before {
  content: "\e9dc";
}

.iconlace-shrink:before {
  content: "\e9db";
}

.iconlace-chapters:before {
  content: "\e9ea";
}

.iconlace-chapters-solid:before {
  content: "\e9eb";
}

.iconlace-volume-bullhorn:before {
  content: "\e9ec";
}

.iconlace-volume-muted:before {
  content: "\e9f2";
}

.iconlace-volume-low:before {
  content: "\e9ef";
}

.iconlace-volume-medium:before {
  content: "\e9f0";
}

.iconlace-volume-full:before {
  content: "\e9f1";
}

.iconlace-double-page:before {
  content: "\e9e9";
}

.iconlace-simple-page:before {
  content: "\e9e8";
}

.iconlace-full-view:before {
  content: "\e9e6";
}

.iconlace-minimize-view:before {
  content: "\e9d7";
}

.iconlace-note:before {
  content: "\e9e0";
}

