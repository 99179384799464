/* CSS3 ANIMATION  STYLESHEET */

/*
==============================================
SlideDown
==============================================
*/


.slideDown{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 0.2s;	
	-webkit-animation-duration: 0.2s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;	

	visibility: visible !important;						
}

@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}	
	100% {
		transform: translateY(0%);
	}
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	100% {
		-webkit-transform: translateY(0%);
	}	
}

/*
==============================================
slideUp
*/


.slideUp{
	animation-name: slideUp;
	-webkit-animation-name: slideUp;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;

	visibility: visible !important;			
}

@keyframes slideUp {
	0% {
		transform: translateY(100%);
	}
	50%{
		transform: translateY(-8%);
	}
	65%{
		transform: translateY(4%);
	}
	80%{
		transform: translateY(-4%);
	}
	95%{
		transform: translateY(2%);
	}			
	100% {
		transform: translateY(0%);
	}	
}

@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(100%);
	}
	50%{
		-webkit-transform: translateY(-8%);
	}
	65%{
		-webkit-transform: translateY(4%);
	}
	80%{
		-webkit-transform: translateY(-4%);
	}
	95%{
		-webkit-transform: translateY(2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
}

/*
==============================================
slideLeft
==============================================
*/


.slideLeft{
	animation-name: slideLeft;
	-webkit-animation-name: slideLeft;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideLeft {
	0% {
		transform: translateX(-2%);
		opacity:0;
	}
	
	100% {
		transform: translateX(0%);
		opacity:1;		
	}
}

@-webkit-keyframes slideLeft {
	0% {
		transform: translateX(-10%);
		opacity:0;
	}
	
	100% {
		transform: translateX(0%);
		opacity:1;		
	}
}

/*
==============================================
slideRight
==============================================
*/


.slideRight{
	animation-name: slideRight;
	-webkit-animation-name: slideRight;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideRight {
	0% {
		transform: translateX(-150%);
	}
	50%{
		transform: translateX(8%);
	}
	65%{
		transform: translateX(-4%);
	}
	80%{
		transform: translateX(4%);
	}
	95%{
		transform: translateX(-2%);
	}			
	100% {
		transform: translateX(0%);
	}	
}

@-webkit-keyframes slideRight {
	0% {
		-webkit-transform: translateX(-150%);
	}
	50%{
		-webkit-transform: translateX(8%);
	}
	65%{
		-webkit-transform: translateX(-4%);
	}
	80%{
		-webkit-transform: translateX(4%);
	}
	95%{
		-webkit-transform: translateX(-2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
	}
}


/*
==============================================
expandUp
==============================================
*/


.expandUp{
	animation-name: expandUp;
	-webkit-animation-name: expandUp;	

	animation-duration: 0.7s;	
	-webkit-animation-duration: 0.7s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;		

	visibility: visible !important;	
}

@keyframes expandUp {
	0% {
		transform: translateY(100%) scale(0.6) scaleY(0.5);
	}
	60%{
		transform: translateY(-7%) scaleY(1.12);
	}
	75%{
		transform: translateY(3%);
	}	
	100% {
		transform: translateY(0%) scale(1) scaleY(1);
	}	
}

@-webkit-keyframes expandUp {
	0% {
		-webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
	}
	60%{
		-webkit-transform: translateY(-7%) scaleY(1.12);
	}
	75%{
		-webkit-transform: translateY(3%);
	}	
	100% {
		-webkit-transform: translateY(0%) scale(1) scaleY(1);
	}	
}

/*
==============================================
fadeIn
==============================================
*/

.fadeIn{
	animation-name: fadeIn;
	-webkit-animation-name: fadeIn;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes fadeIn {
	0% {
		transform: scale(0);
		opacity: 0.0;		
	}
	60% {
		transform: scale(1.1);	
	}
	80% {
		transform: scale(0.9);
		opacity: 1;	
	}	
	100% {
		transform: scale(1);
		opacity: 1;	
	}		
}

@-webkit-keyframes fadeIn {
	0% {
		-webkit-transform: scale(0);
		opacity: 0.0;		
	}
	60% {
		-webkit-transform: scale(1.1);
	}
	80% {
		-webkit-transform: scale(0.9);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}		
}





/*
==============================================
bounce
==============================================
*/


.bounce{
	animation-name: bounce;
	-webkit-animation-name: bounce;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	
	
	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 	
}

@keyframes bounce {
	0% {
		transform: translateY(0%) scaleY(0.6);
	}
	60%{
		transform: translateY(-100%) scaleY(1.1);
	}
	70%{
		transform: translateY(0%) scaleY(0.95) scaleX(1.05);
	}
	80%{
		transform: translateY(0%) scaleY(1.05) scaleX(1);
	}	
	90%{
		transform: translateY(0%) scaleY(0.95) scaleX(1);
	}				
	100%{
		transform: translateY(0%) scaleY(1) scaleX(1);
	}	
}

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(0%) scaleY(0.6);
	}
	60%{
		-webkit-transform: translateY(-100%) scaleY(1.1);
	}
	70%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
	}
	80%{
		-webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
	}	
	90%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
	}				
	100%{
		-webkit-transform: translateY(0%) scaleY(1) scaleX(1);
	}		
}


/*
==============================================
pulse
==============================================
*/

.pulse{
	animation-name: pulse;
	-webkit-animation-name: pulse;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.9);		
	}
	50% {
		transform: scale(1);
	}	
	100% {
		transform: scale(0.9);
	}			
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;		
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;	
	}			
}

