#blog-news{
	color: $gama-blog;
	margin-bottom: 0;
	.site-wrapper{
		overflow: hidden;
		.col-line{
			position: absolute;
			display: inline-block;
			width: 1px;
			height: 100%;
			top: 50px;
			background: $gama-oscuro;
		}
		.col-line-2{
			display: none;
		}
	}
	.sidebar-wrapper{
		&::before{
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 65%;
			/*background: rgba( $gama-oscuro, .1);
			background: -moz-linear-gradient(45deg, rgba(6,14,30,0) 0%, rgba( $gama-oscuro, .5) 100%);
			background: -webkit-linear-gradient(45deg, rgba(6,14,30,0) 0%,rgba( $gama-oscuro, .5) 100%);
			background: linear-gradient(45deg, rgba(6,14,30,0) 0%, rgba( $gama-oscuro, .5) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00060e1e', endColorstr='#060e1e',GradientType=1 );*/
			background: -moz-linear-gradient(left, rgba( $gama-negro, 0) 0% , rgba( $gama-medio, .4) 20%, rgba( $gama-medio, .5) 100%);
			background: -webkit-linear-gradient(left, rgba( $gama-negro, 0) 0%,rgba( $gama-medio, .4) 20%,rgba( $gama-medio, .5) 100%);
			background: linear-gradient(to right, rgba( $gama-negro, 0) 0%,rgba( $gama-medio, .4) 20%,rgba( $gama-medio, .5) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00060e1e', endColorstr='#060e1e',GradientType=1 );
		}
		#sidebar .widget-post-list li .post-rank{
			display: none !important;
		}
	}
	.site-wrapper-inner{
		margin: 0 auto;
		padding: 0;
		max-width: 1160px;
		overflow: hidden;
		position: relative;
	}

	.publicity{
		margin: 20px 0;
		img{
			max-width: 100%;
		}
    }
    a,
    a:visited,
    a:hover,
    a:focus{
		@include transition(0.3s);
		color: $blanco;
    }
    .news-content-title,
	.widget-title{
		font-family: $p-bold;
        color: $gama-blog;
        margin-bottom: 30px;
        a,
        a:visited{
            text-decoration: none;
            color: $gama-blog;
            .iconlace{
                font-size: 17px;
                margin-left: 0;
                font-weight: 800;
                @include transition(0.3s)
            }
        }
        a:hover,
        a:focus{
            text-decoration: none;
			color: lighten($gama-blog, 90%);
            .iconlace{
                margin-left:10px;
                @include transition(0.3s)
            }
        }
	}
	.post{
		display: block;
		position: relative;
		overflow: hidden;
		.post-title{
            font-family: $p-semib;
			font-size: 16px;
			line-height: 1.4em;
			margin: 10px 0 5px;
			a,
			a:visited{
				text-decoration: none;
				color: $gama-blog;
				@include transition(0.3s)
			}
			a:hover,
			a:focus{
				text-decoration: none;
				color: $blanco;
				@include transition(0.3s)
			}
		}
		p{
			font-size: 14px;
			line-height: 1.4em;
		}
		.post-category{
            font-family: $p-normal;
			text-transform: uppercase;
		}
		.post-caption{
			display: block;
			display: -webkit-box;
			height: 60px;
			overflow: hidden;
			margin: 10px 0 20px;
			text-align: justify;
			line-height: 1.5em;
			font-size: 14px;
			color: lighten( $gama-blog, 10% );
			text-overflow: ellipsis;
			-webkit-line-clamp: 3;
			-moz-line-clamp: 3;
			-ms-line-clamp: 3;
			line-clamp: 3;
			-webkit-box-orient: vertical;
			-moz-box-orient: vertical;
			-ms-box-orient: vertical;
			box-orient: vertical;
		}
		.post-date,
		.post-origin{
            font-family: $p-light;
			display: inline-block;
			vertical-align: middle;
            font-size: 13px;
            color: $gama-gris;
			a{
				color: inherit;
			}
		}
		.post-origin:before{
			content: "·";
			margin: 0 5px;
		}
		.post-text{
			blockquote{
				border-left-color: $gama-medio;
			}		}
		.col{
			padding: 0;
		}
	}
	.news-list{
        margin: 50px 0;
        overflow: hidden;
		.post{
            margin-bottom: 30px;
            #post-thumb img{
                @include transition(0.6s)
            }
            &:hover{
                #post-thumb img{
                    @include transform-scale(1.03);
                    @include transition(0.3s)
                }
            }
        }
        .news-list-item{
            .news-list-title{
                padding: 20px;
                background-color: rgba($gama-oscuro, .6);
                overflow: hidden;
                border-radius: 3px;
                text-align: center;
                margin-bottom: 15px;
                .news-content-title{
                    margin: 0;
                }
            }
            .news-list-post{
                .post{
                    margin:0;
                    .post-title{
                        a{
                            display: block;
                            position: relative;
                            overflow: hidden;
                            padding-left: 30px;
                            .iconlace{
                                @include transition(0.3s);
                                position: absolute;
                                left: 0;
                                bottom: 30%;
                            }
                        }
                    }
                    &:hover{
                        .post-title a .iconlace{
                            @include transition(0.3s);
                            left: 5px;
                        }
                    }
                }
            }
        }
	}
	.news-list-pagination{
		border-top:  1px solid $gama-oscuro;
		overflow: hidden;
		.pagination-info{
			margin: 20px 0;
		}
		.pagination-nav{
			text-align: right;
		}
	}
	.news-list-default,
	.news-list-default-caption{
		.post{
			.post-content{
				.post-title{
					display: block;
					display: -webkit-box;
					max-height: 70px;
					line-height: 1.5em;
					font-size: 16px;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 3;
					-moz-line-clamp: 3;
					-ms-line-clamp: 3;
					line-clamp: 3;
					-webkit-box-orient: vertical;
					-moz-box-orient: vertical;
					-ms-box-orient: vertical;
					box-orient: vertical;
				}
			}
		}
		.publicity{
			margin: 0;
		}
	}
	.news-list-default{
		.post{
			.post-content{
				height: 90px;
			}
		}
	}
	.news-list-default-caption{
		.post{
			.post-content{
				height: 180px;
			}
		}
	}
	.news-list-home{
		.post{
			.post-content{
				margin-left: 20px;
				.post-title{
					margin-top: 0;
				}
			}
		}
    }
	.mega-news {
		margin-top: 72px;
		height: 65vh;
		max-height: 100%;
		min-height: auto !important;
		border: 5px solid $gama-oscuro;
		.mega-news-left,
		.mega-news-right{
			height: 64vh;
		}
		.mega-news-left{
			padding-right: 0;
		}
		.mega-news-right{
			padding-left: 0;
		}
		.post{
			border: 5px solid $gama-oscuro;
			width: 100%;
            height: 50%;
			.post-image,
			.post-content{
				position: absolute;
				left:0;
				right: 0
			}
			.post-image{
				top: 0;
				bottom: 0;
                background-size: cover;
                background-position: center;
                @include transition(0.6s)
            }
            &:hover{
                .post-image{
                    @include transform-scale(1.03);
                    @include transition(0.3s)
                }
            }
			.post-content{
				bottom: 0;
				padding: 20px 30px 30px;
				background: -moz-linear-gradient(top, rgba(6,14,30,0) 0%, rgba(6,14,30,0.6) 40%, rgba(6,14,30,1) 100%);
				background: -webkit-linear-gradient(top, rgba(6,14,30,0) 0%, rgba(6,14,30,0.6) 40%, rgba(6,14,30,1) 100%);
				background: linear-gradient(to bottom, rgba(6,14,30,0) 0%, rgba(6,14,30,0.6) 40%, rgba(6,14,30,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00060e1e', endColorstr='#060e1e',GradientType=0 );
                @include transition(0.3s);
				.post-title{
                    font-size: 20px;
                    line-height: initial;
					a,
					a:visited{
						color: $gama-blog;
					}
					a:hover,
					a:focus{
						color: lighten( $gama-blog, 90% );
					}
				}
            }
		}
		.post-big{
			height: 100%;
			.post-content{
				.post-title{
					font-size: 50px;
				}
			}
		}
	}
	.main-slider.mega-news:after{
		display: none;
	}
	.news-post{
		margin-top: 70px;
	}
	#post-container{
		margin-right: 15px;
		border-bottom: 1px solid rgba( $gama-oscuro, .5);
		.post{
			.post-head{
				margin-bottom: 20px;
				.post-title{
					font-size: 50px;
					margin-bottom: 20px;
				}
				.post-date,
				.post-origin{
					font-size: 15px;
				}
			}
			.post-options{
				margin: 20px -15px 0;
				overflow: hidden;
				.post-date,
				.post-origin{
					margin-top: 5px;
				}
				.option-list .option {
					margin: 0 0px 0px 20px;
					float: right;
					.option-left,
					.option-body{
						line-height: 25px;
					}
				}

			}
			.post-content{
				padding: 20px 40px 0;
				.post-text-caption{
					font-size: 18px;
					text-align: justify;
					margin: 10px 0;
				}
			}
			p{
				font-size: 16px;
				line-height: 1.8em;
			}
			.options{
				padding: 20px 0;
			}
		}
	}

}
#sidebar{
	margin-left: 15px;
	min-height: 100vh;
	.widget{
		position: relative;
		margin-bottom: 50px;
	}
	.post{
		.post-image{
			width: 140px;
			margin-right: 15px;
			float: left;
		}
		.post-content{
			.post-title{
				font-size: 16px;
			}
		}
	}
	.widget-post-list{
		li{
			position: relative;
			margin: 30px 0 30px 10px;
			.post{
				.post-image{
					width: 140px;
					margin-right: 15px;
					float: left;
				}
				.post-content{
					height: 100%;
					position: relative;
					.post-title{
						margin:0;
						margin-bottom: 7px;
						height: 52px;
						overflow: hidden;
					}
				}
			}
			.post-rank{
				position: absolute;
				top: -10px;
				left: -20px;
				width: 40px;
				height: 40px;
				line-height: 40px;
				border-radius: 50%;
				text-align: center;
				background: $gama-negro;
				color: $morado;
				//font-family: $black-font;
				font-size: 20px;
			}
		}
	}
}
#news-wrapper-example-1{
	overflow: hidden;
	background: -moz-linear-gradient(-45deg, rgba(40,50,79,0.8) 0%, rgba(40,50,79,0.8) 1%, rgba(47,33,66,0) 100%);
	background: -webkit-linear-gradient(-45deg, rgba(40,50,79,0.8) 0%,rgba(40,50,79,0.8) 1%,rgba(47,33,66,0) 100%);
	background: linear-gradient(135deg, rgba(40,50,79,0.8) 0%,rgba(40,50,79,0.8) 1%,rgba(47,33,66,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc28324f', endColorstr='#002f2142',GradientType=1 );
}
#news-social-widgets{
	padding: 50px 0;
	background: rgba($morado, .1);
	.news-widget{
		text-align: center;
	}
}
#news-maratonica{
    .news-list-title{
        background-image: url('~/assets/img/header-slider/maratonica_bg.webp');
        background-size: cover;
        background-position: center;
        .news-content-title a{
            color: goldenrod;
        }
    }
}
#news-blog{
    .news-list-title{
        background-image: url('~/assets/img/header-slider/bg_slider.webp');
        background-size: cover;
        background-position: center;
    }
}
#news-declaraciones{
    .news-list-title{
        background-image: url('~/assets/img/header-slider/stars.webp');
        background-size: cover;
        background-position: center;
        .news-content-title a{
            color: $morado;
        }
    }
}
#news-subscription{
	background-image: url('~/assets/img/bg-newsletter.webp');
	background-repeat: no-repeat;
	background-size: 400px;
	background-position: right top;
	padding: 40px 0 90px;
	.newsletter-form{
		.btn{
			width: 100%;
			margin-top: 20px;
		}
	}
	.newsletter-title{
		font-family: $p-bold;
		font-size: 50px;
		margin: 0;
	}
}
#news-breadcrumb .breadcrumb{
	margin-left: 0;
}


@media (max-width:  1199.98px){
	#blog-news {
		.mega-news{
			.post{
				.post-content{
					.post-title{
						font-size: 24px;
					}
				}
			}
			.post-big{
				.post-content{
					.post-title{
						font-size: 36px;;
					}
				}
			}
		}
		#sidebar{
			.post{
				.post-image{
					width: 100%;
					margin-right: 0;
					float: none;
					margin-bottom: 10px;
				}
			}
		}
	}
}
@media (max-width:  991.98px){
	#blog-news {
		.sidebar-wrapper::before{
			display: none;
		}
		.mega-news{
			height: auto;
			.mega-news-left{
				padding-right: 15px;
				height: 24vh;
			}
			.mega-news-right{
				padding-left: 15px;
				height: 48vh;
			}
			.post{
				.post-content{
					padding: 5px 10px 10px;
				}
			}
			.post-big{
				height: 100%;
				.post-content{
					.post-title{
						font-size: 24px;
					}
				}
			}
		}
		.news-list{
			margin: 30px 0;
		}
	}
	#news-social-widgets{
		margin-bottom: 20px;
		.news-widget{
			margin-bottom: 30px;
		}
	}
	#news-subscription{
		padding-left: 20px;
		padding-right: 20px;
		background-position: right -20px;
		.newsletter-title{
			font-size: 30px;
			text-align: center;
		}
	}
}
@media (max-width: 767.98px){
	#blog-news {
		.site-wrapper-inner{
			padding: 0;
		}
		.publicity{
			margin: 20px;
		}
		.news-list-home{
			.post{
				.post-content{
					margin-left: 0;
					margin-top: 15px;
				}
			}
		}
		.news-list{
			margin: 20px 0;
			.news-list-item{
				margin: 15px 0;
			}
		}
		.news-list-default,
		.news-list-default-caption{
			.post{
				.post-content{
					height: auto;
				}

			}
		}
		.mega-news{
			height: auto;
			.mega-news-left{
				padding-right: 15px;
				height: 34vh;
			}
			.mega-news-right{
				padding-left: 15px;
				height: 68vh;
			}
			.post{
				.post-content{
					padding: 5px 10px 10px;
					.post-title{
						font-size: 18px;
					}
				}
			}
		}
		.news-list-pagination{
			margin: 0 15px 10px;
			text-align: center;
			.pagination-info{
				margin-bottom: 0;
			}
			.pagination-nav{
				text-align: center;
			}
		}
	}
	#news-subscription{
		margin: -20px 0 20px;
		background-position: center;
		.newsletter-title{
			font-size: 20px;
			text-align: center;
		}
	}
}
