$gama-medio-trans: rgba(47, 51, 63, 0.9);
.tooltip{
  padding: 0;
  margin: 0;
  border-radius: 0;
  background-color: transparent;
  text-transform: none;
  opacity: 1;
  @media (max-width: 768px) {
    opacity: 1;
  }
  .arrow {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px;
    &:before {
      position: absolute;
      border-color: transparent;
      border-style: solid;
    }
  }
}

.tooltip-inner {
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: $gama-medio-trans;
  border-radius: 25px;
  padding: 12px 15px;
}

.tooltip.bs-tooltip-bottom,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-bottom .arrow,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.tooltip.bs-tooltip-top,
.tooltip.bs-tooltip-auto[x-placement^="top"] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-bottom .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: $gama-medio-trans;
}

.tooltip.bs-tooltip-top .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
}

.tooltip.bs-tooltip-top .arrow,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.tooltip-profile {
  margin-bottom: 10px;
  border-radius: 10px;
  .tooltip-inner {
    background: #28324F;
    border-radius: 10px;
  }
  .arrow {
    &:before {
      border-top-color: #28324F;
    }
  }
}

.tooltip-ebook {
  left: 25px !important;
  margin-bottom: 5px;
  .tooltip-inner {
    background: #33416C;
  }
  .arrow::before {
    border-top-color: #33416C;
    right: 20px;
  }
}

.tooltip-two-page {
  left: -25px !important;
  margin-bottom: 5px;
  .tooltip-inner {
    background: #33416C;
  }
  .arrow::before {
    border-top-color: #33416C;
    right: -27px;
  }
}

.tooltip-more-info {
  margin-bottom: 5px;
  .tooltip-inner {
    background: #0a1229;
    max-width: 275px;
    @media (max-width: 767px) {
      max-width: 200px;
    }
  }
  .arrow::before {
    border-top-color: #0a1229;
  }
}

.tooltip-score {
  margin-bottom: 5px;
  .tooltip-inner {
    background: #0a1229;
  }
  .arrow::before {
    border-top-color: #0a1229;
  }
}

.tooltip-search-tags {
  margin-bottom: 5px;
  @media (max-width: 1024px) {
    left: 50px !important;
  }
  .tooltip-inner {
    background: #33416C;
  }
  .arrow::before {
    border-top-color: #33416C;
    @media (max-width: 1024px) {
      left: -50px;
    }
  }
}

.tooltip-taxes {
  margin-bottom: 5px;
  .tooltip-inner {
    background: #33416C;
  }
  .arrow::before {
    border-top-color: #33416C;
  }
}

.tooltip-transactions {
  margin-bottom: 7px;
  .tooltip-inner {
    width: 143px;
    background-color: #28324F;
    border-radius: 6px;
  }
  .arrow::before {
    border-top-color: #28324F;
  }
}