.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.marked {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.selected {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: #fff;
  background: #4B5881;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  color: #fff;
  background: #4B5881;
}

.ng-select.ng-single .ng-control {
  background: rgba(13, 13, 13, 0.3);
  border: 1px solid #4B5881;
  color: #F2F3F7;
  height: 60px !important;
}

.ng-dropdown-panel {
  border: none;
}

.ng-dropdown-panel {
  position: relative !important;
}

